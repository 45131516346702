import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { resetBackgroundMatch } from "store/matchMaking/matchMakingSlice";
import { getGameChallenge } from "store/gameHub/action";
import { resetCurrentGame, resetGameHubSlice } from "store/gameHub/gameHubSlice";
import ButtonDc from "components/Button";
import PaySuccess from "assets/payment_success.svg";
import PayUnsuccess from "assets/payment_unsuccess.svg";
import Summary from "assets/summary.png";
import CloseIcon from "assets/close-button.svg";
import { ReactComponent as Arrow } from "assets/arrow-right-gaming.svg";
import ImageHolder from "components/ImagePlaceholder";
interface WinnerDisplayProps {
  team1JSON: any[];
  team2JSON: any[];
  winner: string;
}

const WinnerDisplay: React.FC<WinnerDisplayProps> = ({
  team1JSON,
  team2JSON,
  winner,
}) => {
  const renderTeam = (team: any[]) => {
    return team.map((player, index) => (
      <ImageHolder
        key={index}
        uri={player?.profilePicture}
        className="w-12 h-12 rounded-md flex"
      />
    ));
  };

  const renderWinner = (winner: string) => {
    const winningTeam = team1JSON.some((player: any) => player._id === winner) ? team1JSON : team2JSON;
    return renderTeam(winningTeam);
  };

  return (
    <div className="flex flex-row gap-5 justify-evenly">
      <div className="flex flex-row items-center gap-3">
        <div className="flex flex-row items-center gap-2 border border-white rounded-lg p-2">
          <div className="flex flex-row gap-1">{renderTeam(team1JSON)}</div>
          <span className="text-white text-l">VS</span>
          <div className="flex flex-row gap-1">{renderTeam(team2JSON)}</div>
        </div>
        <div>
          <Arrow width={25} height={20} />
        </div>
        <div className="flex flex-row items-center gap-2 border border-white rounded-lg p-2">
          <div className="flex flex-row gap-1">{renderWinner(winner)}</div>
        </div>
      </div>
    </div>
  );
};

const GamePaymentStatusScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const { challengeId, paid } = useParams();
  const { currentGame, loader } = useAppSelector((state: RootState) => state.gameHub)

  const paidScreen = paid === "paid"
  const winner = currentGame?.winner;
  const cubes = currentGame?.cubeWager;
  const name = currentGame?.gameId.name;
  const players = currentGame?.players;

  const hostPlayerId = currentGame?.host;
  const opponentPlayerId = players.filter((player: any) => player._id !== hostPlayerId) ?? null;
  const team1 = players?.filter((player: any) => player._id === hostPlayerId) || [];
  const team2 = players?.filter((player: any) => opponentPlayerId.some((opponent: any) => opponent._id === player._id)) || [];


  const handleTryAgain = () => {
    //TODO navigate to payment screen
  };

  const onCancel = () => {
    dispatch(resetCurrentGame());
    dispatch(resetGameHubSlice());
    setTimeout(() => {
      navigate("/");
    }, 300);
  };

  useEffect(() => {
    dispatch(getGameChallenge({ matchId: challengeId }));
  }, [dispatch, challengeId]);

  useEffect(() => {
    const isSuccess = window.location.search?.split("?payment=")?.[1];
    setIsPaymentSuccessful(isSuccess === "success");
    dispatch(resetBackgroundMatch());
  }, []);


  return (
    <div className=" max-md:p-3  max-md:items-center  max-md:justify-center mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex min-h-screen h-full">
      <div className="container flex flex-col gap-y-[20px] md:my-16 items-center max-md:p-5">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-5 flex flex-col justify-center items-center
        w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] gap-3 max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <button
              className=" w-[25px] h-[25px] absolute right-10 top-10"
              onClick={onCancel}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-center gap-5 md:mt-5">
            {paidScreen ? (<>
              <div className="w-36 h-36  rounded-full flex items-center justify-center">
                <img
                  src={isPaymentSuccessful ? PaySuccess : PayUnsuccess}
                  alt="scale"
                />
              </div>
              <span className="text-2xl text-white text-center items-center font-normal">
                {isPaymentSuccessful
                  ? "Payment Successful"
                  : "Payment Unsuccessful"}
              </span>
            </>) : (<>
              <div className="w-36 h-36  rounded-full flex items-center justify-center">
                <img
                  src={Summary}
                  alt="scale"
                />
              </div>
              <span className="text-2xl mb-10 text-white text-center items-center font-normal">
                Match summary
              </span>
            </>)}
          </div>

          <div className="flex flex-col justify-center items-center gap-y-10 mb-6">
            {paidScreen && <div className="justify-center items-center  text-center">
              <span className=" max-md:text-l text-xl text-subText items-center font-normal text-center">
                {isPaymentSuccessful ? (
                  <>
                    Thank you for your payment! <br />
                    Your transaction has been successfully processed!
                  </>
                ) : (
                  <>
                    Sorry, your payment could not be processed. <br />
                    Please try again.
                  </>
                )}
              </span>
            </div>}
            {!isPaymentSuccessful && (
              <ButtonDc
                text="Try again"
                action={handleTryAgain}
                short={true}
                type="primary"
              />
            )}
            <div className="flex flex-row gap-5 justify-evenly">

              {cubes === 0 ? (<div className="flex flex-col bg-borderColor py-1 px-4  rounded-[12px]">
                <span className=" max-md:text-sm text-xl text-primary font-normal ">
                  Free Play
                </span>
              </div>) : (<div className="flex flex-col text-center bg-borderColor py-2 px-4  rounded-[12px]">
                <span className="text-white text-center font-aldo text-4xl mb-5">{name}</span>
                <div>
                  <span className=" max-md:text-sm text-xl text-center text-white font-normal ">
                    Cube Wager:
                  </span>
                  <span className=" max-md:text-sm text-xl text-primary font-normal ">
                    {' '}${cubes}
                  </span>
                </div>
              </div>
              )}
            </div>
            <WinnerDisplay team1JSON={team1} team2JSON={team2} winner={winner} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamePaymentStatusScreen;
