import React, { useEffect } from 'react';
import ImageHolder from 'components/ImagePlaceholder';
import { useAppSelector } from 'store/hooks';
import { AppDispatch, RootState } from 'store/index';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Ranking } from "assets/games/ranking.svg";
import { ReactComponent as Trophy } from "assets/games/trophy.svg";
import { useDispatch } from 'react-redux';
import { getGameChallenge } from 'store/gameHub/action';
import Refresh from "assets/refresh_icon.svg";
import Back from "assets/arrow-right.svg";
import LoadingAnim from 'components/Loader';
interface GameStartingScreenProps {
  id: string;
  title: string;
  route: string;
  loader: boolean;
  isLandscape: boolean;
  backgroundImage: string;
  image?: string;
  gameLogo?: string;
  playButtonImage?: string;
  textColor?: string;
  textColor2?: string;
}

const GameStartingScreen: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { challengeId } = useParams();
  const isSuspended = useAppSelector(
    (state: RootState) => state.user
  ).user?.isSuspended;
  const { currentGame, loader } = useAppSelector((state: RootState) => state.gameHub);
  const user = useAppSelector((state: RootState) => state.user).user;

  const userToken = useAppSelector((state) => state.auth).userToken;
  const fcmData = useAppSelector((state) => state.fcm);
  const userFinished = currentGame?.finishedUsers?.includes(user?._id) || false;


  const data = location.state?.gameData as GameStartingScreenProps;
  const lastScore = 650; //TODO
  const personalBest = 650; //TODO

  useEffect(() => {
    if (fcmData?.data?.type === "FINISHED_CHALLENGE" || userFinished) {
      dispatch(getGameChallenge({ matchId: challengeId }));
      navigate(`/gaming/game-waiting/${challengeId}`)
    }
  }, [fcmData, userFinished]);

  const onRefresh = () => {
    dispatch(getGameChallenge({ matchId: challengeId }));
  };

  return (
    <div className="relative w-full h-screen">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${data?.backgroundImage})` }}
      />
      {challengeId ? (<button
        className=" w-[35px] h-[35px] absolute top-10 right-10 z-30"
        onClick={onRefresh}
      >
        <img className=' bg-2048-super rounded-full p-2' src={Refresh} alt="Close" />
      </button>) : (<button
        className=" w-[35px] h-[35px] rotate-180 absolute top-10 left-10 z-30"
        onClick={() => navigate(`/gaming/games`)}
      >
        <img className=' bg-2048-super rounded-full p-2' src={Back} alt="Back" />

      </button>)}
      {/* TODO */}
      {/* <div className='fixed bg-opacity-20 bg-cardBackground p-4 w-full mt-[7vh] flex flex-row items-center justify-between md:px-20'>
        <div
          className={`md:w-[320px] w-[200px] p-2 rounded-xl flex gap-2 items-center align-middle ${data?.textColor2 ? `border-2  border-${data?.textColor2}` : ""}`}
          style={{
            backgroundColor: data?.textColor,
            borderColor: data?.textColor2,
          }}
        >
          <ImageHolder className={`rounded-xl max-md:w-14 max-md:h-14  ${data?.textColor2 ? `border-2  border-${data?.textColor2}` : ""}`} uri={user?.profilePicture} width={70} height={70} />
          <div className='flex flex-col text-white max-md:text-xs' >
            <span style={{
              fontFamily: "'Lemon', cursive",
            }}>{user?.username}</span>
            <span>Last Score: {lastScore}</span>
            <span>Personal Best: {personalBest}</span>
          </div>
          <div className='h-auto '>

            <Trophy className='fixed top-[9vh] max-md:left-[170px] max-md:w-12 max-md:h-12' />
          </div>
        </div>
        <button className={`z-10 p-2 rounded-xl flex gap-2 items-center align-middle transform transition-transform hover:scale-105 hover:shadow-lg  ${data?.textColor2 ? `border-2  border-${data?.textColor2}` : ""}`}
          style={{
            backgroundColor: data?.textColor,
            borderColor: data?.textColor2,
          }}>
          <Ranking className='max-md:w-14 max-md:h-14' />
        </button>
      </div> */}
      <div className="relative flex flex-col items-center justify-center h-full gap-4">
        <img src={data?.gameLogo} alt={"logo"} />
        <button className='absolute bottom-32 transform transition-transform hover:scale-105 hover:shadow-lg animate-pulse'
          onClick={() => {
            !isSuspended ? (window.open(
              `${process.env.REACT_APP_GAMING_SITE_URL}${data?.route}?authorize=${userToken}?challengeId=${challengeId}?gameId=${data?.id}`
            )) : (toast.warning('Account Suspended'))
          }}>
          <span
            className="text-6xl max-md:text-3xl font-bold "
            style={{
              fontFamily: "'Lemon', cursive",
              color: data?.textColor2 ? data?.textColor2 : "#FFFFFF",
              textShadow: `8px 8px 8px ${data?.textColor}, -8px -8px 8px ${data?.textColor}, 8px -8px 8px ${data?.textColor}, -8px 8px 8px ${data?.textColor}`,
            }}
          >
            Play now
          </span>
        </button>
        <LoadingAnim loading={loader} />
      </div>
    </div >
  );
};

export default GameStartingScreen;
