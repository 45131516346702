import { createAsyncThunk } from "@reduxjs/toolkit";
import { capturePaymentApi, digitalPaymentApi } from "api/common.api";
import { toast } from "react-toastify";

interface WithCallback {
  payload: any;
  callback?: (data: any) => void;
}

export const capturePayment = createAsyncThunk<any, any>(
  "pay/capturePayment",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const data: any = await capturePaymentApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const digitalPayment = createAsyncThunk<any, WithCallback>(
  "pay/digitalPayment",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await digitalPaymentApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error?.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
