import React, { useEffect } from "react";
import ButtonDc from "components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { getCurrentMatch } from "store/currentMatch/currentMatchActions";
import Scale from "assets/dispute_scale.svg";

const DisputeScreen = () => {
  const navigate = useNavigate();
  const { matchId } = useParams();
  const currentMatch = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;
  const fcmData = useAppSelector((state) => state.fcm);

  const dispatch = useAppDispatch();


  const handleDispute = () => {
    navigate(`/disputes`);
  };

  useEffect(() => {
    dispatch(getCurrentMatch({ matchId }));
  }, [matchId]);

  useEffect(() => {
    if (
      fcmData?.data?.type === "VOTE_POPUP" ||
      fcmData?.data?.type === "MATCH_WINNER"
    )
      dispatch(getCurrentMatch({ matchId }));
  }, [fcmData]);

  return (
    <div className=" max-md:p-3  max-md:items-center  max-md:justify-center mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex min-h-screen h-full">
      <div className="container flex flex-col gap-y-10 md:my-16 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 flex flex-col justify-center items-center
        w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] gap-3 max-md:h-[95vh] max-md:p-3"
        >
          <div className="flex flex-col items-center justify-center gap-5 md:mt-16">
            <div className="w-36 h-36  rounded-full flex items-center justify-center">
              <img src={Scale} alt="scale" />
            </div>
            <span className="text-5xl text-white text-center items-center font-normal">
              Match Disputed
            </span>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-10 mb-10">
            <div className="justify-center items-center  text-center">
              <span className=" max-md:text-3xl text-4xl text-subText items-center font-normal text-center">
                You can either change your entry, or tap
                <br /> 'Done' to have us review the match <br />
                <br />
                Losing multiple disputes will result in a <br />
                permanent ban and forfeiture of your
                <br /> current balance.
              </span>
            </div>
          </div>
          <ButtonDc
            text="Go to dispute"
            action={handleDispute}
            type="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default DisputeScreen;
