import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";

import ButtonDc from "components/Button";
import CloseIcon from "assets/close-button.svg";
import Sidebar from "components/SideBar";
import Menu from "components/Menu";
import NotificationModal from "components/Notifications";
import { useState } from "react";

export default function StreamingMethodScreen({ }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [visibleOptionsMenu, setVisibleOptionsMenu] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(false);

  const handleClose = () => {
    navigate(`/stream/live-setup`);
  };

  const handleCancel = () => {
    navigate(`/stream/hub`);
  };

  const handleStreamDC = () => {
    navigate(`/stream/streaming-more-places`);
  };
  const handleStreamExt = () => {
    navigate(`/stream/preview-stream/${"external"}`);
    // TODO
  };

  return (
    <div className="mx-auto w-full h-screen  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar
        onOptions={() => setVisibleOptionsMenu(true)}
        onNotifications={() => setVisibleNotifications(true)}
      />
      <div className=" w-full md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20 ">
        <div className="md:mt-[20px] flex flex-row justify-between items-center w-[80vw] max-md:w-[95vw]">
          <span className="text-white text-3xl text-center max-md:text-2xl">
            Choose Streaming Method
          </span>
          <button
            className=" w-[25px] h-[25px] right-10 max-md:w-[15px]"
            onClick={handleClose}
          >
            <img src={CloseIcon} alt="Close" />
          </button>
        </div>
        <div className="flex flex-col md:p-4 gap-[2em] max-md:gap-8 items-center md:gap-[5em] rounded-2xl  md:bg-componentBackground bg-opacity-80 w-[80vw] h-[80vh] max-md:h-[58vh] max-md:w-[95vw]">
          <span className=" text-placeholder text-xl font-normal">
            Please choose how you would like to broadcast your stream
          </span>
          <div className="flex flex-col gap-8 items-center">
            <div className=" bg-borderColor rounded-xl p-4 w-full flex desktop:flex-row flex-col gap-8 max-md:w-4/5 justify-between ">
              <div className="flex flex-col desktop:w-3/4 gap-2">
                <span className="text-white font-bold max-md:text-center">
                  Use Built-in Streaming Feature
                </span>
                <span className="text-white max-md:text-justify">
                  Stream directly from the app with our built-in tools and
                  features.
                </span>
              </div>
              <ButtonDc
                text={"Go Live"}
                action={handleStreamDC}
                type="primary"
                short
              />
            </div>
            <div className=" bg-borderColor rounded-xl p-4 flex desktop:flex-row flex-col gap-8 max-md:w-4/5 justify-between">
              <div className="flex flex-col desktop:w-3/4 gap-2">
                <span className="text-white font-bold max-md:text-center">
                  Use External Streaming Software
                </span>
                <span className="text-white max-md:text-justify">
                  Stream using your preferred external software such as OBS or
                  Streamlabs.
                </span>
              </div>
              <ButtonDc
                text={"Go Live"}
                action={handleStreamExt}
                type="primary"
                short
                disabled
              />
            </div>
          </div>
          <ButtonDc text={"Cancel"} action={handleCancel} type="cancel" />
        </div>
      </div>
      <Menu
        visible={visibleOptionsMenu}
        onClose={() => setVisibleOptionsMenu(false)}
      />
      <NotificationModal
        visible={visibleNotifications}
        onClose={() => setVisibleNotifications(false)}
      />
    </div>
  );
}
