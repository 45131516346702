import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import {
  setStreamCategory,
  setStreamDescription,
  setStreamTags,
  setStreamThumbnail,
  setStreamTitle,
} from "store/streaming/streamingSlice";

import { ReactComponent as UploadIcon } from "assets/streaming/upload.svg";
import { ReactComponent as Thumbnail } from "assets/streaming/thumbnail_empty.svg";

import ButtonDc from "components/Button";
import CloseIcon from "assets/close-button.svg";
import Sidebar from "components/SideBar";
import ProfilePicModal from "components/ProfilePicModal";
import Menu from "components/Menu";
import NotificationModal from "components/Notifications";

enum categories {
  GAMING = "Gaming",
  ENTERTAINMENT = "Entertainment",
  EDUCATION = "Education",
  CREATIVE = "Creative",
  SPORTS = "Sports",
  LIFESTYLE = "Lifestyle",
  OTHER = "Other",
}

export default function LiveSetupScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    streamTitle,
    streamDescription,
    streamTags,
    streamCategory,
    thumbnail,
  } = useAppSelector((state: RootState) => state.streaming);
  const { from } = useParams();
  const [title, setTitle] = useState(streamTitle ? streamTitle : "");
  const [description, setDescription] = useState(
    streamDescription ? streamDescription : ""
  );
  const [tags, setTags] = useState<string[]>(streamTags ? streamTags : []);
  const [category, setCategory] = useState(
    streamCategory ? streamCategory : ""
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(thumbnail ? thumbnail : "");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [visibleOptionsMenu, setVisibleOptionsMenu] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(false);

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    tags: "",
    category: "",
    imageUrl: "",
  });

  const options = [
    { text: "Select category", value: "" },
    { text: "Gaming", value: categories.GAMING },
    { text: "Entertainment", value: categories.ENTERTAINMENT },
    { text: "Education", value: categories.EDUCATION },
    { text: "Creative", value: categories.CREATIVE },
    { text: "Sports", value: categories.SPORTS },
    { text: "Lifestyle", value: categories.LIFESTYLE },
    { text: "Other", value: categories.OTHER },
  ];

  const validateFields = () => {
    let isValid = true;
    let newErrors = {
      title: "",
      description: "",
      tags: "",
      category: "",
      imageUrl: "",
    };

    if (title.trim() === "") {
      newErrors.title = "Title is required.";
      isValid = false;
    }
    if (description.trim() === "") {
      newErrors.description = "Description is required.";
      isValid = false;
    }
    if (tags.length === 0) {
      newErrors.tags = "At least one tag is required.";
      isValid = false;
    }
    if (category === "") {
      newErrors.category = "Please select a category.";
      isValid = false;
    }
    if (imageUrl === "") {
      newErrors.imageUrl = "Thumbnail is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleClose = () => {
    navigate("/stream/hub");
  };

  const handleContinue = () => {
    if (validateFields()) {
      dispatch(setStreamTitle(title));
      dispatch(setStreamDescription(description));
      dispatch(setStreamTags(tags));
      dispatch(setStreamCategory(category));
      dispatch(setStreamThumbnail(imageUrl));
      if (from === "edit")
        return navigate(`/stream/preview-stream/${"in-app"}`);
      navigate("/stream/streaming-method");
    }
  };

  const handleInputTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length === 1 && inputValue[0] === " ") return;
    setTitle(inputValue);
  };

  const handleInputTags = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const tagsArray = inputValue.split(/\s+/).map((tag) => tag.trim());
    setTags(tagsArray);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(e.target.value);
  };

  const updateAvatar = (imgSrc: string) => {
    setImageUrl(imgSrc);
    setModalOpen(false);
  };

  useEffect(() => {
    if (
      title.trim() !== "" &&
      description.trim() !== "" &&
      tags.length > 0 &&
      category !== "" &&
      imageUrl !== ""
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [title, description, tags, category, imageUrl]);

  return (
    <div className="mx-auto w-full bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar
        onOptions={() => setVisibleOptionsMenu(true)}
        onNotifications={() => setVisibleNotifications(true)}
      />
      <div className="w-full md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20">
        <div className="md:mt-[20px] flex flex-row justify-between items-center w-[80vw] max-md:w-[95vw]">
          <span className="text-white text-3xl text-center max-md:text-2xl">
            Set Live Setup
          </span>
          <button
            className="w-[25px] h-[25px] right-10 max-md:w-[15px]"
            onClick={handleClose}
          >
            <img src={CloseIcon} alt="Close" />
          </button>
        </div>
        <div className="flex flex-col md:p-4 gap-[2em] max-md:gap-8 md:gap-[5vh] rounded-2xl bg-componentBackground bg-opacity-80 w-[80vw] h-[80vh] max-md:h-[75vh] max-md:w-[95vw]">
          <span className="text-placeholder text-xl text-center max-md:text-justify max-md:text-lg">
            Fill out the details below to set up your live stream. Provide a
            title, category, and other information to attract viewers.
          </span>
          <div className="flex w-full md:flex-row flex-col gap-5 h-full overflow-y-auto">
            <div className="flex flex-col gap-3 w-full md:w-1/2 justify-between">
              <div className="flex flex-col items-start gap-2">
                <span className="text-white font-semibold text-xl text-center max-md:text-lg">
                  Stream Title:
                </span>
                <div className="w-[37vw] max-md:w-full min-h-[60px] bg-borderColor rounded-lg flex items-center">
                  <input
                    type="text"
                    className="w-full h-full bg-transparent outline-none ml-[20px] placeholder:text-placeholder font-normal size text-[14px] text-white"
                    placeholder="Enter your stream title"
                    value={title}
                    onChange={handleInputTitle}
                  />
                </div>
                {errors.title && (
                  <span className="text-red-500">{errors.title}</span>
                )}
              </div>
              <div className="flex flex-col items-start gap-2">
                <span className="text-white font-semibold text-xl text-center max-md:text-lg">
                  Tags:
                </span>
                <div className="w-[37vw] max-md:w-full min-h-[60px] bg-borderColor rounded-lg flex items-center">
                  <input
                    type="text"
                    className="w-full h-full bg-transparent outline-none ml-[20px] placeholder:text-placeholder font-normal size text-[14px] text-white"
                    placeholder="Add tags (space separated)"
                    value={tags.join(" ")}
                    onChange={handleInputTags}
                  />
                </div>
                {errors.tags && (
                  <span className="text-red-500">{errors.tags}</span>
                )}
                {tags && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    {tags.map((tag, index) => (
                      <span
                        className="bg-primaryActive text-black px-2 py-1 rounded-full"
                        key={index}
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex flex-col items-start gap-2">
                <span className="text-white font-semibold text-xl text-center max-md:text-lg">
                  Description:
                </span>
                <div className="w-[37vw] max-md:w-full max-md:h-[20vh] md:min-h-[calc(40vh-180px)] bg-borderColor rounded-lg flex items-center">
                  <textarea
                    className="bg-borderColor align-top gap-5 max-md:h-[20vh] md:min-h-[calc(40vh-180px)] w-[70vw] md:w-full rounded-xl p-2 outline-none text-left text-white"
                    placeholder="Describe your stream"
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </div>
                {errors.description && (
                  <span className="text-red-500">{errors.description}</span>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-3 w-full md:w-1/2 justify-between">
              <div className="flex flex-col items-start gap-2">
                <span className="text-white font-semibold text-xl text-center max-md:text-lg">
                  Category:
                </span>
                <div className="w-[37vw] max-md:w-full min-h-[60px] bg-borderColor rounded-lg flex items-center p-3">
                  <select
                    className="w-full h-full bg-borderColor outline-none placeholder:text-placeholder font-normal size text-[14px] text-white"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    {options.map((option, index) => (
                      <option
                        key={index}
                        value={option.value}
                        style={{
                          backgroundColor: "#3D3D3D",
                          color: "white",
                        }}
                        className="bg-borderColor text-white"
                      >
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.category && (
                  <span className="text-red-500">{errors.category}</span>
                )}
              </div>
              <div className="flex flex-col items-start gap-2">
                <span className="text-white font-semibold text-xl text-center max-md:text-lg">
                  Thumbnail:
                </span>
                <div className="w-[37vw] max-md:w-full max-md:h-[26vh] md:min-h-[calc(60vh-180px)] h-full bg-borderColor rounded-lg flex items-center justify-center">
                  <button
                    className="text-placeholder"
                    onClick={() => setModalOpen(true)}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="imageUpload"
                        className="h-[30vh] w-[45vh] max-md:h-[20vh] rounded-lg max-md:w-full md:mt-8 flex justify-center items-center md:m-3 border-2 hover:border-primaryActive"
                      />
                    ) : (
                      <div className="flex flex-col gap-2 items-center p-5">
                        <Thumbnail />
                        <span className="flex flex-row gap-2">
                          Upload Thumbnail <UploadIcon />
                        </span>
                      </div>
                    )}
                  </button>
                </div>
                {errors.imageUrl && (
                  <span className="text-red-500">{errors.imageUrl}</span>
                )}
              </div>
              {modalOpen && (
                <ProfilePicModal
                  updateAvatar={updateAvatar}
                  closeModal={() => setModalOpen(false)}
                  title="Upload your Photo"
                  type="COMMUNITY"
                />
              )}
            </div>
          </div>
          <ButtonDc
            text="Continue"
            action={handleContinue}
            type="primary"
            disabled={isButtonDisabled}
          />
        </div>
      </div>
      <Menu
        visible={visibleOptionsMenu}
        onClose={() => setVisibleOptionsMenu(false)}
      />
      <NotificationModal
        visible={visibleNotifications}
        onClose={() => setVisibleNotifications(false)}
      />
    </div>
  );
}
