import { createSlice } from "@reduxjs/toolkit";
import { signOut } from "store/auth/actions";
import { WalletVerification } from "store/wallet/action";
import {
  checkPassword,
  deleteAccount,
  editUser,
  followUser,
  getCommunityDuelists,
  getCommunityFriends,
  getFollowers,
  getFollowing,
  getLeaderboard,
  getOtherUser,
  getReferrals,
  getUser,
  imageUpload,
  techSupport,
  unfollowUser,
} from "./action";
interface User {
  _id: string;
  loader: boolean;
  userInfo: {} | null;
  userToken: string | null;
  error: {} | null;
  success: boolean;
  password: string;
  friends: any[];
  duelists: any[];
  user: {
    _id: string;
    username: string;
    email: string;
    about: string;
    phoneNumber: string;
    profilePicture: string;
    duelsWon: number;
    followersCount: number;
    followingCount: number;
    matchWins: [];
    referralId: string;
    isWithdrawnBefore: boolean;
    isStackedBefore: boolean;
    shippingAddress: {
      name: string;
      address: string;
    };
    duelHistory: any[];
    documentsApproved: boolean;
    documentsSubmitted: boolean;
    hasSetPassword: boolean;
    googleId?: string;
    appleId?: string;
    facebookId?: string;
    googleMail?: string;
    facebookUsername?: string;
    appleMail?: string;
    accountStatus?: string;
    isSuspended?: boolean;
    suspension: {
      eventId: string;
      reason: string;
      cubeWager: number;
    };
  } | null;
  otherUser: {
    _id: string;
    username: string;
    email: string;
    about: string;
    phoneNumber: string;
    profilePicture: string;
    duelsWon: number;
    followersCount: number;
    followingCount: number;
    matchWins: [];
    referralId: string;
    isWithdrawnBefore: boolean;
    isStackedBefore: boolean;
    shippingAddress: {
      name: string;
      address: string;
    };
    duelHistory: any[];
    alreadyFollowing: boolean;
  };
  followers: any[];
  following: any[];
  leaderboard: any[];
}

const initialState: User = {
  _id: "",
  loader: false,
  userInfo: {},
  userToken: null,
  error: null,
  success: false,
  password: "",
  friends: [],
  duelists: [],
  user: {
    _id: "",
    username: "",
    email: "",
    about: "",
    phoneNumber: "",
    profilePicture: "",
    duelsWon: 0,
    followersCount: 0,
    followingCount: 0,
    matchWins: [],
    referralId: "",
    isWithdrawnBefore: false,
    isStackedBefore: false,
    shippingAddress: {
      name: "",
      address: "",
    },
    duelHistory: [],
    documentsSubmitted: false,
    documentsApproved: false,

    hasSetPassword: false,
    googleId: "",
    appleId: "",
    facebookId: "",
    accountStatus: "",
    googleMail: "",
    facebookUsername: "",
    appleMail: "",
    suspension: {
      eventId: "",
      reason: "",
      cubeWager: 0,
    },
  },
  otherUser: {
    _id: "",
    username: "",
    email: "",
    about: "",
    phoneNumber: "",
    profilePicture: "",
    duelsWon: 0,
    followersCount: 0,
    followingCount: 0,
    matchWins: [],
    referralId: "",
    isWithdrawnBefore: false,
    isStackedBefore: false,
    shippingAddress: {
      name: "",
      address: "",
    },
    duelHistory: [],
    alreadyFollowing: false,
  },
  followers: [],
  following: [],
  leaderboard: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setWithdrawnIntro: (state) => {
      if (state.user) {
        state.user.isWithdrawnBefore = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCommunityFriends.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCommunityFriends.fulfilled, (state, action) => {
      state.friends = action.payload?.friendList;
      state.error = null;
      state.loader = false;
    });
    builder.addCase(getCommunityFriends.rejected, (state) => {
      state.error = "Failed to fetch value";
      state.loader = false;
    });
    builder.addCase(getCommunityDuelists.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCommunityDuelists.fulfilled, (state, action) => {
      state.duelists = action.payload?.userList;
      state.error = null;
      state.loader = false;
    });
    builder.addCase(getCommunityDuelists.rejected, (state) => {
      state.error = "Failed to fetch value";
      state.loader = false;
    });
    builder.addCase(getOtherUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getOtherUser.fulfilled, (state, action) => {
      state.otherUser = action.payload?.userDetails;
      state.error = null;
      state.loader = false;
    });
    builder.addCase(getOtherUser.rejected, (state) => {
      state.error = "Failed to fetch value";
      state.loader = false;
    });
    builder.addCase(getUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload?.user;
      if (state.user) {
        state.user.isSuspended =
          action.payload.user.accountStatus === "SUSPEND";
      }
      state.error = null;
      state.loader = false;
    });

    builder.addCase(getUser.rejected, (state) => {
      return initialState;
    });
    builder.addCase(followUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(followUser.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(followUser.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(unfollowUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(unfollowUser.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(unfollowUser.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(getFollowers.fulfilled, (state, action) => {
      state.followers = action.payload?.followers;
    });
    builder.addCase(getFollowing.fulfilled, (state, action) => {
      state.following = action.payload?.following;
    });
    builder.addCase(imageUpload.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(imageUpload.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(imageUpload.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(WalletVerification.fulfilled, (state) => {
      if (state.user) {
        state.user.documentsSubmitted = true;
        state.user.isWithdrawnBefore = true;
      }
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
    builder.addCase(getLeaderboard.fulfilled, (state, action) => {
      state.leaderboard = action.payload?.leaderBoard;
    });
    builder.addCase(checkPassword.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(checkPassword.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(checkPassword.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(editUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(editUser.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(techSupport.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(techSupport.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(techSupport.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(deleteAccount.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(deleteAccount.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(getReferrals.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getReferrals.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(getReferrals.rejected, (state) => {
      state.loader = false;
    });
  },
});

export default userSlice.reducer;
export const { setWithdrawnIntro } = userSlice.actions;
