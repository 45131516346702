import {
  capturePaymentApi,
  checkIfBalanceIsEnoughApi,
  getBalanceApi,
  getPaypalHistoryApi,
  getQuickAccessApi,
  getReceiversApi,
  getTransactionHistoryApi,
  requestMoneyApi,
  requestPayoutApi,
  searchReceiversApi,
  sendMoneyApi,
  walletVerificationApi,
} from "../../api/wallet.api";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface WithCallback {
  payload: any;
  callback?: (data: any) => void;
  failed?: () => void;
}

export const getBalance = createAsyncThunk<any, void>(
  "/wallet/getBalance",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await getBalanceApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const checkIfBalanceIsEnough = createAsyncThunk<any, WithCallback>(
  "/wallet/checkEnoughBalance",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await checkIfBalanceIsEnoughApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const capturePayment = createAsyncThunk<any, WithCallback>(
  "/wallet/capturePayment",
  async ({ payload, callback, failed }, { rejectWithValue }) => {
    try {
      const data: any = await capturePaymentApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      failed?.();
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getQuickAccess = createAsyncThunk<any, void>(
  "/wallet/getQuickAccess",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await getQuickAccessApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getReceivers = createAsyncThunk<any, void>(
  "/wallet/getReceivers",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await getReceiversApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const sendMoney = createAsyncThunk<any, WithCallback>(
  "/wallet/sendMoney",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await sendMoneyApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const requestMoney = createAsyncThunk<any, WithCallback>(
  "/wallet/requestMoney",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await requestMoneyApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const WalletVerification = createAsyncThunk<any, WithCallback>(
  "/wallet/WalletVerification",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await walletVerificationApi(payload.images);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const requestPayout = createAsyncThunk<any, WithCallback>(
  "/wallet/requestPayout",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await requestPayoutApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getTransactionHistory = createAsyncThunk<any, any>(
  "/wallet/getTransactionHistory",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getTransactionHistoryApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const searchReceivers = createAsyncThunk<any, WithCallback>(
  "/wallet/searchReceivers",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await searchReceiversApi(payload.query);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getPaypalHistory = createAsyncThunk<any, any>(
  "/wallet/getPaypalHistoryApi",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getPaypalHistoryApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
