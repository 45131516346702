import React from "react";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

const PrimarySwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#FFD482",
    "&:hover": {
      backgroundColor: "rgba(255, 215, 0, 0.08)",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#FFD482",
  }, "& .MuiSwitch-switchBase.Mui-disabled": {
    color: "#4b5563",
  },
  "& .MuiSwitch-track.Mui-disabled": {
    backgroundColor: "#4b5563",
  },
}));

const CustomSwitch: React.FC<SwitchProps> = (props) => {
  return <PrimarySwitch {...props} />;
};

export default CustomSwitch;
