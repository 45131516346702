import React, { useEffect, useRef, useState } from "react";
import Loader from "./Loader";
import EmojiPicker, { EmojiClickData, Theme } from "emoji-picker-react";
import { subscribeToTopic } from "store/fcm/actions";
import { ReactComponent as CloseIcon } from "assets/close-button.svg";
import { ReactComponent as Dots } from "assets/dots.svg";
import { ReactComponent as Send } from "assets/send.svg";
import { ReactComponent as PrivateIcon } from "assets/community/private_icon.svg";
import { ReactComponent as PublicIcon } from "assets/community/public_icon.svg";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteCommunity, getCommunityList, getPrivateChatList, leaveCommunity } from "store/community/action";
import {
  updateCommunityChatMessages,
  updatePersonalChatMessages,
} from "store/messaging/messagingSlice";
import {
  deletePrivateChat,
  getCommunityChatMessages,
  getPersonalChatMessages,
  sendCommunityMessage,
  sendPersonalMessage,
} from "store/messaging/action";
import {
  addNewChatToList,
  removeDeletedOrLeftCommunity,
  removePersonalChat,
  setCommunitiesName,
  setCommunityId,
  setCommunityState,
  setEditComID,
  updateLastMessage,
} from "store/community/communitySlice";
import CommunitySettings from "./CommunitySettings";
import miscsStyles from "styles/miscs/miscs.module.scss";
import ImageHolder from "./ImagePlaceholder";

interface CommunicationChatModalProps {
  type?: String;
  chat?: any;
  isMatch?: boolean;
  closeModal: () => void;
}
interface MessageProps {
  user: string;
  message: string;
  time: string;
  username?: string;
  profilePicture?: string;
}
const CommunicationChatModal: React.FC<CommunicationChatModalProps> = ({
  closeModal,
  chat,
  type,
  isMatch,
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [dotModalOpen, setDotModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesData = useAppSelector(
    (state: RootState) => state.messaging.chats
  );
  const communityMessagesData = useAppSelector(
    (state: RootState) => state.messaging.communityChats
  );
  const user = useAppSelector((state: RootState) => state.user);
  const loader = useAppSelector((state: RootState) => state.messaging.loader);
  const currentUser = user?.user;
  const chatId = type === "COMMUNITIES" ? chat?._id : chat?._id;
  const fcmMessage = useAppSelector((state) => state.fcm);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const latestMessageRef = useRef<HTMLDivElement>(null);
  const dotModalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dotModalRef.current &&
        !dotModalRef.current.contains(event.target as Node) &&
        dotModalOpen
      ) {
        setDotModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dotModalOpen]);

  useEffect(() => {
    dispatch(getPrivateChatList(1));
  }, [dispatch, dotModalOpen === false]);

  const handleEmojiSelect = (emojiData: EmojiClickData) => {
    setMessage(message + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  const closeBothModals = () => {
    setSettingsModalOpen(false);
    setDotModalOpen(false);
    closeModal();

  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    const date = new Date().toISOString();

    if (message.trim() !== "") {
      if (type === "CHAT") {
        if (messagesData && currentUser) {
          const data = {
            _id: "temp",
            message: message,
            user: currentUser?._id,
            time: date,
          };

          dispatch(updatePersonalChatMessages(data));
          dispatch(
            sendPersonalMessage({
              payload: { to: chat?.user, message: message },
              callback: () => {
                const newchat = {
                  _id: chatId,
                  user: chat?.user,
                  latest: {
                    _id: "temp",
                    message: message,
                    user: currentUser?._id,
                    time: date,
                  },
                };
                setMessage("");
                dispatch(addNewChatToList(newchat));
                dispatch(updateLastMessage({ chatId, newMessage: data }));
              },
            })
          );
        }
      } else if (type === "COMMUNITIES") {
        if (communityMessagesData && currentUser) {
          const data = {
            _id: "temp",
            message: message,
            user: currentUser?._id,
            time: date,
            username: user.user?.username,
            profilePicture: user.user?.profilePicture,
          };

          dispatch(updateCommunityChatMessages(data));
          dispatch(
            sendCommunityMessage({
              payload: { to: chatId, message: message },
              callback: () => {
                const newchat = {
                  _id: chatId,
                  user: chat?.user,

                  latest: {
                    _id: "temp",
                    message: message,
                    user: currentUser?._id,
                    time: date,
                  },
                };
                setMessage("");
              },
            })
          );
        }
      }

      setMessage("");
    }
  };

  const pushToChats = (messageData: any) => {
    const date = new Date().toISOString();
    const data = messageData?.data;
    const chatId = type === "COMMUNITIES" ? data?.communityId : data?.chatId;
    if (data.type !== "COMMUNITY_MESSAGE" && chatId !== chat?._id) return;
    if (data.type === "COMMUNITY_MESSAGE" && data.senderId === currentUser?._id)
      return;
    const newMsg = {
      _id: messageData.messageId,
      message: data?.message,
      user: data?.senderId,
      time: date,
    };

    const newCommunityMsg = {
      _id: data?.senderId,
      username: data?.sender,
      profilePicture: data?.senderPicture,
      time: date,
      message: data?.message,
    };
    if (type === "COMMUNITIES")
      return dispatch(updateCommunityChatMessages(newCommunityMsg));
    dispatch(updatePersonalChatMessages(newMsg));
  };
  useEffect(() => {
    if (type === "COMMUNITIES") dispatch(subscribeToTopic({ topic: chatId }));
  }, [chatId]);

  useEffect(() => {
    if (
      fcmMessage.data.type !== "PRIVATE_MESSAGE" &&
      fcmMessage.data.type !== "COMMUNITY_MESSAGE"
    )
      return;
    if (fcmMessage?.data) {
      pushToChats(fcmMessage);
      const newchat = {
        _id: chat?._id,
        user: chat?.user,
        latest: {
          _id: fcmMessage.data?.chatId,
          message: fcmMessage?.data.message,
          time: new Date().toISOString(),
          user: fcmMessage?.data.senderId,
        },
      };
      if (fcmMessage.data.type !== "PRIVATE_MESSAGE") {
        dispatch(addNewChatToList(newchat));
      }
      dispatch(
        updateLastMessage({
          chatId: chat?._id,
          newMessage: {
            _id: fcmMessage.data?.chatId,
            message: fcmMessage?.data.message,
            time: new Date().toISOString(),
            user: fcmMessage?.data.senderId,
          },
        })
      );
    }
  }, [fcmMessage]);

  useEffect(() => {
    dispatch(getPersonalChatMessages(`/${chatId}`));
    dispatch(getCommunityChatMessages(`/${chatId}`));
  }, [chatId]);

  useEffect(() => {
    if (chatContainerRef.current && latestMessageRef.current) {
      latestMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messagesData, communityMessagesData]);

  const groupMessagesByDate = (messages: any[]) => {
    if (!Array.isArray(messages)) {
      console.error(
        "Expected messages to be an array, but received:",
        messages
      );
      return {};
    }

    return messages.reduce((groupedMessages: any, message: any) => {
      const date = new Date(message.time).toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "2-digit",
        year: "numeric",
      });

      if (!groupedMessages[date]) {
        groupedMessages[date] = [];
      }

      groupedMessages[date].push(message);
      return groupedMessages;
    }, {});
  };

  const groupedMessages = groupMessagesByDate(
    type === "COMMUNITIES"
      ? communityMessagesData.messages
      : messagesData?.messages
  );

  const Message: React.FC<MessageProps> = ({
    user,
    username,
    message,
    time,
    profilePicture,
  }) => {
    const formattedTime = new Date(time).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return (
      <div
        className={`flex ${user === currentUser?._id ? "justify-end" : "justify-start"
          } mt-4`}
        style={{
          maxWidth: "100%",
        }}
      >
        <div
          className={`flex ${user === currentUser?._id ? "items-end" : "items-start"
            } flex-col py-1 px-2 rounded-md `}
        >
          <div
            className={`flex gap-2 ${user === currentUser?._id ? "flex-row-reverse " : "flex-row "
              } `}
          >
            {type === "COMMUNITIES" && (
              <div>
                <ImageHolder
                  uri={profilePicture}
                  className="w-12 rounded-full"
                />
              </div>
            )}
            <p
              className={`inline-block ${user === currentUser?._id ? " bg-darkGray" : "bg-cardBackground"
                } p-2 min-w-16 rounded-xl whitespace-pre-wrap break-words text-left text-white`}
            >
              {type === "COMMUNITIES" && user !== currentUser?._id && (
                <div className="mt-1">
                  <p className="text-xs " style={{ color: "#ffffff80" }}>
                    {username}
                  </p>
                </div>
              )}
              {message}
            </p>
          </div>
          <div className="mt-1">
            <p className="text-xs italic" style={{ color: "#ffffff80" }}>
              {formattedTime}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const deleteChat = () => {
    dispatch(deletePrivateChat(chatId));
    dispatch(removePersonalChat(chatId));
    setDotModalOpen(false);
    toast("Successfully deleted");
    closeModal?.();
  };

  const deleteCommunityClick = async () => {
    if (chat?.owner === currentUser?._id) {
      dispatch(deleteCommunity({ query: `/${chatId}` }));
    } else {
      dispatch(leaveCommunity({ query: `/${chatId}` }));
    }
    setDotModalOpen(!dotModalOpen);
    dispatch(removeDeletedOrLeftCommunity(chatId));
    setTimeout(() => {
      toast(
        chat?.owner === user?.user?._id
          ? "Successfully deleted"
          : "Successfully left"
      );
      closeModal?.();
    }, 2000);
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >

      < >
        {(chat?.name || chat?.user?.username) ? (< >
          <div className="flex max-md:h-screen max-md:w-full justify-center max-md:p-0 text-center">
            <div className="relative max-md:w-full md:bg-opacity-95 text-slate-100 text-left shadow-xl transition-all">
              <div className="px-4 py-4 w-[48vw] bg-backgroundGray">
                <button
                  className="rounded-md p-1 inline-flex items-center justify-center absolute md:top-8 top-[30px] right-8"
                  onClick={() => setDotModalOpen(!dotModalOpen)}
                >
                  <Dots className="w-6 md:h-6 h-5 " />
                </button>
                <div className=" absolute top-8 right-[120px] ">
                  {type === "COMMUNITIES" &&
                    chat?.type?.toLowerCase()?.trim() === "public" ? (
                    <PublicIcon className=" w-8 md:h-8 h-6" />
                  ) : chat?.type?.toLowerCase()?.trim() === "private" ? (
                    <PrivateIcon className=" w-8 md:h-8 h-6" />
                  ) : (
                    ""
                  )}
                </div>
                {dotModalOpen && (
                  <div ref={dotModalRef} className="absolute flex flex-col gap-3 top-16 right-20 bg-opacity-95 p-2 bg-cardBackground shadow-lg rounded-md z-50">
                    {type === "COMMUNITIES" &&
                      chat?.owner === currentUser?._id && (
                        <button
                          className="flex  w-full px-2 py-1 text-sm font-bold text-white hover:bg-primary hover:text-black rounded-md"
                          onClick={() => {
                            dispatch(setEditComID(chatId));
                            dispatch(setCommunityState("EDIT"));
                            navigate("/communication/select-community-image");
                          }}
                        >
                          Edit Community
                        </button>
                      )}
                    {type === "COMMUNITIES" && (
                      <button
                        className="flex  w-full px-2 py-1 text-sm font-bold text-white hover:bg-primary hover:text-black rounded-md"
                        onClick={() => {
                          setSettingsModalOpen(true);
                          setDotModalOpen(!dotModalOpen);
                        }}
                      >
                        Community Settings
                      </button>
                    )}
                    {type === "COMMUNITIES" && (
                      <button
                        className="flex  w-full px-2 py-1 text-sm font-bold text-white hover:bg-primary hover:text-black rounded-md"
                        onClick={() => {
                          dispatch(setCommunitiesName(chat.name));
                          dispatch(setCommunityId(chatId));
                          navigate(
                            "/communication/invite-to-community/invite-duelists"
                          );
                        }}
                      >
                        Invite Duelists
                      </button>
                    )}
                    {type === "COMMUNITIES" && (
                      <div className="bg-white h-[1px] mt-2 w-full"></div>
                    )}
                    <button
                      className="flex  w-full px-2 py-1 text-sm font-bold text-brightRed hover:bg-primary rounded-md"
                      onClick={
                        type === "COMMUNITIES"
                          ? deleteCommunityClick
                          : deleteChat
                      }
                    >
                      {type === "COMMUNITIES"
                        ? chat?.owner === currentUser?._id
                          ? "Leave & Delete Community "
                          : "Leave Community"
                        : "Delete chat"}
                    </button>
                  </div>
                )}

                <div className="flex flex-row items-center gap-10 max-md:gap-3">
                  <ImageHolder
                    className="flex md:w-[50px] md:h-[50px] max-md:w-[40px] max-md:h-[40px] rounded-full p-1"
                    uri={
                      type === "COMMUNITIES"
                        ? chat?.coverImage
                        : chat.user?.profilePicture
                    }
                  />
                  <div className="flex w-[25vw] gap-2 items-center">
                    <div className="flex flex-row w-full justify-between">
                      <span className="text-white text-lg max-md:text-sm">
                        {type === "COMMUNITIES"
                          ? chat?.name
                          : chat.user?.username}
                      </span>
                    </div>
                  </div>
                </div>

              </div>
              <div
                className={`container h-[calc(68vh-240px)] max-md:h-[75vh] md:w-[48vw] md:bg-borderColor  px-10 py-4  ${miscsStyles.beautifulScrollbarHidden}`}
              >
                <div
                  className={`chat-messages ${miscsStyles.beautifulScrollbarHidden}`}
                  ref={chatContainerRef}
                >
                  <Loader chat loading={loader} />
                  {Object.keys(groupedMessages).length === 0 ? (
                    <div className="text-subText flex text-center h-[50vh] justify-center items-center font-bold">
                      To start a conversation, send a message
                    </div>
                  ) : (
                    Object.entries(groupedMessages)?.map(
                      ([date, messages]: any) => (
                        <div key={date}>
                          <div className="text-center my-4">
                            <span className="text-white font-bold">{date}</span>
                          </div>
                          {messages?.map((msg: any, index: number) => (
                            <div
                              key={msg?._id}
                              ref={
                                index === messages.length - 1
                                  ? latestMessageRef
                                  : null
                              }
                            >
                              <Message
                                user={msg.user}
                                message={msg.message}
                                time={msg.time}
                                username={msg?.username}
                                profilePicture={msg?.profilePicture}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    )
                  )}

                </div>
              </div>
              <div className="flex justify-center mb-5 w-[48vw]">
                <div className="max-md:absolute md:w-[55vw] w-4/5 justify-center bottom-10 ">
                  <div className="flex flex-row items-center mt-4">
                    <button
                      type="button"
                      className="bg-white hover:bg-primary rounded-xl text-2xl h-10 w-14"
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    >
                      😊
                    </button>
                    {showEmojiPicker && (
                      <div className="absolute bottom-16 left-10">
                        <EmojiPicker
                          theme={Theme.DARK}
                          onEmojiClick={handleEmojiSelect}
                        />
                      </div>
                    )}
                    <input
                      type="text"
                      className="flex-grow p-2 mx-2 rounded-xl bg-white text-black"
                      placeholder="Type your message and press Enter to send"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <button
                      type="button"
                      className="bg-primary flex-col justify-center items-center h-10 w-14 rounded-xl text-black"
                      onClick={handleSendMessage}
                    >
                      <Send fill="#000000" className="h-10 w-10" />
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
          {settingsModalOpen && (
            <CommunitySettings
              isMatch={isMatch}
              chat={chat}
              closeSettingsModal={() => {
                setSettingsModalOpen(false);
              }}
              closeBothModals={closeBothModals}
            />
          )}</>) : (
          <div className="flex h-[60vh] max-md:w-full items-center justify-center bg-borderColor">
            <span className="bg-backgroundGray text-white text-center p-2 rounded-lg">{type === "COMMUNITIES" ? 'Select a Community to start messaging' : "Select a Chat to start messaging"}</span>
          </div>
        )}
      </>

    </div>

  );
};

export default CommunicationChatModal;
