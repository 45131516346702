import { toast } from "react-toastify";
import {
  enableStakingApi,
  getStakeAmountApi,
  getStakedMatchesApi,
  stakeApi,
} from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface WithCallback {
  payload?: any;
  callback?: (data: any) => void;
}

export const getStakeAmount = createAsyncThunk<any, any>(
  "match/getStakeAmount",
  async (matchID, { rejectWithValue }) => {
    try {
      const data: any = await getStakeAmountApi(matchID);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const setStake = createAsyncThunk<any, WithCallback>(
  "match/setStake",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await stakeApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getStakedMatches = createAsyncThunk<any, void>(
  "match/getStakedMatches",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await getStakedMatchesApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const enableStaking = createAsyncThunk<any, WithCallback>(
  "user/enableStaking",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await enableStakingApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error?.data.message);
      if (!error?.data.message) {
        throw error;
      }
      return rejectWithValue(error.data.message);
    }
  }
);
