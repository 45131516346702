export enum notificationActionTypes {
  JOIN_MATCH = "JOIN_MATCH",
  JOIN_DUEL = "JOIN_DUEL",
  OPPONENT_JOIN_MATCH = "OPPONENT_JOIN_MATCH",
  JOIN_TOURNAMENT = "JOIN_TOURNAMENT",
  JOIN_COMMUNITY = "JOIN_COMMUNITY",
  REQUEST_MONEY = "REQUEST_MONEY",
  JOIN_PRESET = "JOIN_PRESET",
  OPPONENT_JOIN_CHALLENGE = "OPPONENT_JOIN_CHALLENGE",
}
