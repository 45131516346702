import CloseIcon from "assets/close-button.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch } from "store/index";
import Logo from "assets/success.svg";
import ButtonDc from "components/Button";
import { WalletStatus } from "types/constants";
import LoadingAnim from "components/Loader";

export default function TransactionCompleteScreen({}) {
  const { from } = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const { sendAmount, receiver, loader } = useAppSelector(
    (state) => state.wallet
  );

  const onCancel = () => {
    navigate("/wallet/main");
  };

  const onContinue = () => {
    navigate(`/wallet/transaction-receipt/${from}`);
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="text-3xl text-white items-center font-normal">
                  {from === WalletStatus.SEND
                    ? "Send Money"
                    : from === WalletStatus.WITHDRAW
                    ? "Withdraw Funds"
                    : "Request Money"}
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <div className="flex flex-row justify-center items-center h-full">
              <div className="flex flex-col items-center justify-center gap-10">
                <div className="flex flex-row gap-8 items-center justify-center mt-8">
                  <div className="flex flex-col justify-center items-center gap-10">
                    <span className="text-white text-3xl max-md:text-lg">
                      {from === WalletStatus.SEND
                        ? "Transaction Successful!"
                        : "Request Sent Successful!"}
                    </span>
                    <img
                      src={Logo}
                      alt="Logo"
                      className=" w-[10em] rounded-2xl"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <span className="text-primary text-3xl">
                    {`$${Number(sendAmount).toFixed(2)}`}
                  </span>

                  {from !== WalletStatus.WITHDRAW ? (
                    <span className="text-placeholder text-xl mt-8">
                      {from === WalletStatus.SEND
                        ? "Sending to"
                        : "Request from "}
                      {"\t\t"}
                      <span className="text-primary">{receiver?.username}</span>
                    </span>
                  ) : (
                    <span className="text-placeholder text-xl mt-8">
                      Withdraw Request Sent
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center gap-10 mt-16 max-md:flex-col">
            <div>
              <ButtonDc
                text="Back to Wallet"
                short
                action={() => navigate("/wallet/main")}
              />
            </div>
            {from !== WalletStatus.REQUEST && (
              <div>
                <ButtonDc
                  text="Invoice"
                  short
                  type={"primary"}
                  action={() => onContinue()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
}
