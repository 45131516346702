import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "assets/close-button.svg";
import { useAppDispatch } from "store/hooks";
import { AppDispatch } from "store/index";

import LoadingAnim from "components/Loader";
import { ReactComponent as Info } from "assets/info.svg";
import { ReactComponent as People } from "assets/people.svg";
import { ReactComponent as Warning } from "assets/warning.svg";
import { ReactComponent as Privacy } from "assets/privacy.svg";
import { ReactComponent as Share } from "assets/share_icon.svg";
import { ReactComponent as Language } from "assets/language.svg";
import { ReactComponent as Arrow } from "assets/arrow-right.svg";
import { ReactComponent as Delete } from "assets/recycle_bin.svg";
import { ReactComponent as UserVoice } from "assets/user_voice.svg";
import { ReactComponent as Notification } from "assets/notification.svg";
import { ReactComponent as Contact } from "assets/contact.svg";
import { ReactComponent as SettingsIcon } from "assets/settings.svg";

const SettingsScreen = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();

  const handleClose = () => {
    navigate("/");
  };

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen">
      <div className="container flex items-center flex-col gap-y-[20px] md:my-16 max-md:p-3">
        <div className="backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5 w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh] overflow-y-auto">
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <div className="flex flex-row items-center gap-4">
              <SettingsIcon className="h-8 w-8 max-md:h-6 max-md:w-6" />
              <span className="text-3xl max-md:text-xl text-white font-normal">
                Settings
              </span>
            </div>
            <button
              className=" h-8 w-8 max-md:h-6 max-md:w-6"
              onClick={handleClose}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex flex-col max-md:w-full  justify-center max-md:mt-12 mt-6">
            <span className="text-white text-2xl">Account</span>
            <div className="flex md:flex-row flex-col justify-center items-center md:gap-16 gap-2 p-2 mt-2">
              <button
                className="flex h-16 w-2/3 max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4 hover:bg-darkGray"
                onClick={() => navigate("/settings/manage-accounts")}
              >
                <div className="flex flex-row items-center gap-4">
                  <People />
                  <span className="text-white md:text-lg text-[14px]">
                    Manage Accounts
                  </span>
                </div>
                <Arrow />
              </button>
              <button
                className="flex h-16 w-2/3 max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4 hover:bg-darkGray"
                onClick={() => navigate("/settings/refer-friend")}
              >
                <div className="flex flex-row items-center gap-4">
                  <Share />
                  <span className="text-white md:text-lg text-[14px]">
                    Refer A Friend
                  </span>
                </div>
                <Arrow />
              </button>
            </div>
            <span className="text-white text-2xl mt-4">App Settings</span>
            <div className="flex  md:flex-row flex-col items-center gap-16 p-2">
              <button
                className="flex h-16 w-[calc(50%-30px)]  max-md:w-full rounded-lg items-center justify-between md:gap-16 gap-2  bg-borderColor p-4 hover:bg-darkGray"
                onClick={() => navigate("/settings/delete")}
              >
                <div className="flex flex-row items-center gap-4">
                  <Delete width={20} height={20} />
                  <span className="text-white md:text-lg text-[14px]">
                    Delete Account
                  </span>
                </div>
                <Arrow />
              </button>
            </div>
            <span className="text-white text-2xl mt-4">Help & Support</span>
            <div className="flex  md:flex-row flex-col justify-center items-center md:gap-16 gap-2  p-2 mt-2">
              <button
                className="flex h-16 w-2/3  max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4 hover:bg-darkGray"
                onClick={() => navigate(`/disputes/settings`)}
              >
                <div className="flex flex-row items-center gap-4">
                  <Info />
                  <span className="text-white md:text-lg text-[14px]">
                    Disputes
                  </span>
                </div>
                <Arrow />
              </button>
              <button
                className="flex h-16 w-2/3  max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4 hover:bg-darkGray"
                onClick={() => navigate("/settings/support")}
              >
                <div className="flex flex-row items-center gap-4">
                  <UserVoice />
                  <span className="text-white md:text-lg text-[14px]">
                    Request Support
                  </span>
                </div>
                <Arrow />
              </button>
            </div>
            <div className="flex  md:flex-row flex-col items-center gap-16 p-2">
              <button
                className="flex h-16 w-[calc(50%-30px)]  max-md:w-full rounded-lg items-center justify-between md:gap-16 gap-2  bg-borderColor p-4 hover:bg-darkGray"
                onClick={() => navigate("/settings/contact-us")}
              >
                <div className="flex flex-row items-center gap-4">
                  <Contact width={20} height={20} />
                  <span className="text-white md:text-lg text-[14px]">
                    Contact us
                  </span>
                </div>
                <Arrow />
              </button>
            </div>
            <span className="text-white text-2xl mt-4">Legalities</span>
            <div className="flex  md:flex-row flex-col justify-center items-center md:gap-16 gap-2 p-2 mt-2">
              <button
                className="flex h-16 w-2/3  max-md:w-full rounded-lg items-center justify-between md:gap-16 gap-2  bg-borderColor p-4 hover:bg-darkGray"
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_TERMS_URL,
                    "_blank",
                    "noreferrer"
                  );
                }}
              >
                <div className="flex flex-row items-center gap-4">
                  <Privacy />
                  <span className="text-white md:text-lg text-[14px]">
                    Privacy
                  </span>
                </div>
                <Arrow />
              </button>
              <button
                className="flex h-16 w-2/3  max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4 hover:bg-darkGray"
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_POLICY_URL,
                    "_blank",
                    "noreferrer"
                  );
                }}
              >
                <div className="flex flex-row items-center gap-4">
                  <Info />
                  <span className="text-white md:text-lg text-[14px]">
                    Terms of Service
                  </span>
                </div>
                <Arrow />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsScreen;
