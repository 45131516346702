import React, { useEffect, useState } from "react";
import CloseIcon from "../assets/close-button.svg";
import { ReactComponent as Warning } from "assets/warning.svg";
import ButtonDc from "../components/Button";
interface StreamModalProps {
  onPressYes?: () => void;
  onPressNo?: () => void;
  onClose?: () => void;
  visible?: boolean;
  retry?: boolean;
  isNotRequired?: boolean;
}

const formatTime = (countdown: number) => {
  const minutes = Math.floor(countdown / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (countdown % 60).toString().padStart(2, "0");
  return `${minutes}:${seconds}`;
};

const StreamModal: React.FC<StreamModalProps> = ({
  visible,
  onPressYes,
  onPressNo,
  onClose,
  retry,
  isNotRequired ,
}) => {
  const [countdown, setCountdown] = useState(299);

  useEffect(() => {
    if (!visible) return;

    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      onPressYes?.();
    }

    return () => clearInterval(timer);
  }, [countdown, visible]);

  return (
    <>
      {visible && (
        <div className="w-full h-full fixed z-10 ">
          <div
            className="w-3/5 md:min-w-[400px] max-md:h-full h-4/5 p-[30px] bg-cardBackground rounded-[34px] shadow-lg absolute top-1/2 
          left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-[50px] max-md:w-full"
          >
            <div className="flex flex-col gap-[1em] mt-5">
              <div className="w-full rounded-t-[34px] flex items-center justify-between">
                <span className="text-2xl text-white items-center font-normal">
                  Start Streaming
                </span>
                <button
                  className=" w-[25px] h-[25px] absolute right-10"
                  onClick={onClose}
                >
                  <img src={CloseIcon} alt="Close" />
                </button>
              </div>
            </div>

            <div className="flex flex-col justify-evenly items-center gap-20 mt-20">
              {retry && <Warning />}
              <span className="text-2xl text-white items-center font-normal">
                {retry
                  ? "Can't find your stream"
                  : "Setup your stream on Twitch"}
              </span>

              <span className=" text-placeholder text-center justify-center text-xl font-normal">
                {retry
                  ? "Please ensure your stream is live on Twitch and click Retry, or click Skip Stream to continue without Streaming"
                  : ` You have ${formatTime(
                    countdown
                  )} minutes to set up your stream on Twitch. After setting up, click the 'Continue' button`}
              </span>

              <div className="flex flex-col gap-8 max-md:w-4/5">
                <ButtonDc
                  text={retry ? "Retry" : "Continue"}
                  action={() => {
                    onPressYes?.();
                  }}
                  type="primary"
                />
                {isNotRequired && <ButtonDc
                  text={retry ? "Skip streaming" : "I don’t Want to Stream"}
                  action={() => onPressNo?.()}
                  type="error"
                />}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StreamModal;
