import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "store/index";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setEnableStaking, setMatchCubes } from "store/matchMaking/matchMakingSlice";
import { setTournamentCubes } from "store/tournament/tournamentSlice";
import { toast } from "react-toastify";
import { numberRegex } from "constant/validations";
import CloseIcon from "assets/close-button.svg";
import BackIcon from "assets/arrow-right.svg";
import ButtonDc from "components/Button";

const HowManyCubesScreen = () => {
  const [value, setValue] = useState("");
  let { from } = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const { selectedDuelPath } = useAppSelector((state) => state.matchMaking);
  const matchMaking = useAppSelector((state: RootState) => state.matchMaking);
  const tournaments = useAppSelector((state: RootState) => state.tournament);
  const navigate = useNavigate();

  const onCancel = () => {
    navigate("/wagering");
  };
  const onBack = () => {
    navigate("/duels/how-many-rounds");
  };

  const handleFreePlay = () => {
    const cubeValue = Number(value);
    if (selectedDuelPath === "CREATE_TOURNAMENT") {
      dispatch(setTournamentCubes(cubeValue));
    } else {
      dispatch(setMatchCubes(cubeValue));
    }
    if (from) return navigate("/duels/duel-overview");
    if (selectedDuelPath === "CREATE_MATCHMAKING")
      return navigate("/duels/matchMaking");
    dispatch(setEnableStaking(false))
    navigate("/duels/duel-overview");
  };

  const handleNext = () => {
    const cubeValue = Number(value);

    if (cubeValue < 5 || cubeValue > 10000) {
      toast.warning("Please enter a value between $5 and $10,000.");
      return;
    }
    if (selectedDuelPath === "CREATE_TOURNAMENT") {
      dispatch(setTournamentCubes(cubeValue));
    } else {
      dispatch(setMatchCubes(cubeValue));
    }
    if (from) return navigate("/duels/duel-overview");
    if (selectedDuelPath === "CREATE_MATCHMAKING")
      return navigate("/duels/matchMaking");
    if ((cubeValue) === 0) return navigate("/duels/duel-overview");
    if (selectedDuelPath === 'CREATE_TOURNAMENT') return navigate("/duels/duel-overview"); //TODO -> Remove Stakings For Tournaments
    navigate("/duels/staking");
    // TODO -->> check stream
  };

  const handleInputTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (!numberRegex.test(inputValue) && inputValue !== "") {
      toast.warning("Please enter only numeric values.");
      return;
    }
    setValue(inputValue);
  };
  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen ">
      <div className="container flex flex-col gap-y-[20px] items-center md:my-16 max-md:p-2">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-120px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="w-full mb-5 flex justify-between">
            {matchMaking?.selectedDuelPath === "CREATE_TOURNAMENT" ? <span className="text-lg text-subText items-center font-normal uppercase">
              {matchMaking?.selectedDuelPath} {'>'} {tournaments?.selectedGame?.name} {'>'} {tournaments?.selectedPlatform} {'>'} {tournaments?.selectedGameType.type} {'>'} {tournaments?.selectedRound.name}
            </span>
              :
              <span className="text-lg text-subText items-center font-normal uppercase">
                {matchMaking?.selectedDuelPath} {'>'} {matchMaking?.matchSchedule} {'>'} {matchMaking?.selectedGame?.name} {'>'} {matchMaking?.selectedPlatform} {'>'} {matchMaking?.selectedGameType.type} {'>'} {matchMaking?.selectedRound.name}
              </span>
            }
          </div>
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between max-md:flex-col">
              <div className="flex w-full flex-row items-center gap-5">
                <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                  <img src={BackIcon} alt="Close" />
                </button>
                <span className="md:text-3xl text-xl text-white items-center font-normal">
                  How Many Cubes?
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex justify-evenly items-center desktop:flex-row flex-col desktop:mt-0 mt-5">
            {/* <div className="flex flex-col gap-6 ">
              <button className="flex flex-row items-center justify-center eve rounded-2xl mr-4 w-[300px] desktop:mt-5 desktop:h-[120px] h-12 bg-primary hover:bg-primaryActive"
                onClick={() => {
                  setValue("0");
                  handleFreePlay();
                }}>
                <span className="text-black text-center font-inter font-semibold desktop:text-3xl  leading-normal tracking-tight text-2xl">
                  Free Play
                </span>
              </button>

              <div className="flex flex-row items-center justify-center eve rounded-2xl mr-4 w-[300px]  desktop:h-[120px] h-14 ">
                <span className="text-primary text-center font-inter font-semibold desktop:text-3xl  leading-normal tracking-tight text-2xl">
                  Total Price ${value}
                </span>
              </div>

            </div> */}
            <div className="flex h-[60vh] justify-center items-center flex-col gap-20 mt-5">
              <div className="flex justify-center items-center flex-col gap-5">
                <input
                  type="text"
                  className="flex flex-row items-center justify-center px-2.5 pb-2.5 pt-5 eve rounded-2xl mr-4 w-[300px] text-white outline-none border-b-2 text-center font-inter font-semibold desktop:text-5xl tracking-wide leading-normal text-5xl md:h-[120px] h-14 focus:outline-none focus:ring-0 focus:border-primaryYellow peer"
                  placeholder="$0.00"
                  value={value}
                  onChange={handleInputTitle}
                />

                <span className="text-subText text-center text-lg font-normal">
                  *  Min Cubes per player is $5 and <br />Max is $10,000
                </span>
              </div>
              <div className="w-full flex desktop:flex-row flex-col gap-4 justify-center">
                <ButtonDc
                  text="Free Play"
                  action={() => {
                    setValue("0");
                    handleFreePlay();
                  }}
                />
                <ButtonDc
                  type="primary"
                  text="Next"
                  action={handleNext}
                  disabled={Number(value) < 5 || Number(value) > 10000}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowManyCubesScreen;
