import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "store/index";
import { useAppDispatch } from "store/hooks";
import { setMatchCubes } from "store/gameHub/gameHubSlice";
import { numberRegex } from "constant/validations";
import { toast } from "react-toastify";
import CloseIcon from "assets/close-button.svg";
import ButtonDc from "components/Button";

const CubesGameMatchScreen = () => {
  const [value, setValue] = useState("");
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const onCancel = () => {
    navigate(`/gaming/select-game/${"challenge"}`);
  };

  const handleNext = () => {
    const cubeValue = Number(value);

    if (cubeValue < 5 || cubeValue > 10000) {
      toast.warning("Please enter a value between $5 and $10,000.");
      return;
    }
    dispatch(setMatchCubes(value));
    navigate("/gaming/overview");
  };

  const handleInputTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (!numberRegex.test(inputValue) && inputValue !== "") {
      toast.warning("Please enter only numeric values.");
      return;
    }
    setValue(inputValue);
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen ">
      <div className="container flex flex-col gap-y-[20px] items-center md:my-16 max-md:p-2">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-120px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between max-md:flex-col">
              <span className="text-3xl text-white items-center font-normal max-md:mt-10">
                How Many Cubes?
              </span>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex h-[60vh] justify-center items-center flex-col gap-20 mt-5">
            <div className="flex justify-center items-center flex-col gap-5">
              <input
                type="text"
                className="flex flex-row items-center justify-center px-2.5 pb-2.5 pt-5 eve rounded-2xl mr-4 w-[300px] text-white outline-none border-b-2 text-center font-inter font-semibold desktop:text-5xl tracking-wide leading-normal text-5xl desktop:h-[120px] h-14 focus:outline-none focus:ring-0 focus:border-primaryYellow peer"
                placeholder="$0.00"
                value={value}
                onChange={handleInputTitle}
              />

              <span className="text-subText text-center text-lg font-normal">
                * Max Cubes per player is<br /> $10,000
              </span>
            </div>
            <div className="w-full justify-center">
              <ButtonDc
                type="primary"
                text="Next"
                action={handleNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CubesGameMatchScreen;
