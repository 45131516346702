import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "assets/close-button.svg";
import ButtonDc from "components/Button";
import SearchBar from "components/Search";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { inviteToMatch } from "store/matchMaking/actions";
import ImageHolder from "components/ImagePlaceholder";
import { inviteToTournament } from "store/tournament/action";
import { getStagedPlayers } from "store/liveBrackets/actions";
import LoadingAnim from "components/Loader";
import { toast } from "react-toastify";
import miscsStyles from "styles/miscs/miscs.module.scss";

const InviteOthersScreen = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();

  const [challengeInitiated, setChallengeInitiated] = useState(false);
  const matchMaking = useAppSelector((state: RootState) => state.matchMaking);
  const friends = useAppSelector((state: RootState) => state.user.friends);
  const duelists = useAppSelector((state: RootState) => state.user.duelists);
  const [results, setResults] = useState<any[]>([]);
  const currMatchId = useParams();
  const matchId = currMatchId.matchId
    ? currMatchId.matchId
    : matchMaking.setMatch?.matchId;
  const currentMatch = useAppSelector((state: RootState) => state.currentMatch);
  const isTournament = currentMatch.screenStatus === "CREATE_TOURNAMENT";
  const tournamentId = useAppSelector(
    (state: RootState) => state.tournament
  ).tournamentId;
  const tournament = useAppSelector((state: RootState) => state.tournament);
  const spectators = matchMaking.spectators;
  const players = useAppSelector((state: RootState) => state.liveBrackets)
    .tournamentInfo?.players;

  const [challengedPlayers, setChallengedPlayers] = useState<number[]>(
    matchMaking?.invitedPlayers ? matchMaking?.invitedPlayers : []
  );
  const challengedFilter = [...players, ...challengedPlayers].filter(
    (value: any, index, self) =>
      index ===
      self.findIndex(
        (t: any) =>
          t._id === value._id &&
          t.profilePicture === value.profilePicture &&
          t.username === value.username
      )
  );
  const challengedFiltered = challengedFilter.map((player) => player._id);

  useEffect(() => {
    if (isTournament) dispatch(getStagedPlayers({ payload: tournamentId }));
  }, []);

  const handleClose = () => {
    onPressSkip();
  };

  const onPressSkip = () => {
    if (!isTournament) return navigate(`/duels/challenge-others`);
    navigate(`/tournament/live-bracket/${tournamentId}`);
  };

  const renderPlayers = () => {
    const handleChallenge = (id: number) => {
      setChallengedPlayers((prevPlayers) =>
        prevPlayers.includes(id)
          ? prevPlayers.filter((playerId) => playerId !== id)
          : [...prevPlayers, id]
      );
      if (isTournament) {
        dispatch(
          inviteToTournament({
            payload: { userId: id, tournamentId },

          })
        );
      } else {
        dispatch(inviteToMatch({ matchId: matchId, userId: id }));
      }
      setChallengeInitiated(true);
    };

    return friends.map(
      (
        player: {
          profilePicture: string;
          username: string;
          _id: any;
        },
        index: React.Key
      ) => (
        <>
          {!spectators.includes(player._id) && (
            <div key={index} className="flex flex-col items-center ">
              <div
                className="flex flex-row bg-borderColor items-center justify-between gap-5 w-3/5 
          h-16 rounded-lg p-2 max-md:w-full max-md:p-1"
              >
                <div className="flex flex-row items-center justify-between gap-5 ">
                  <ImageHolder
                    className="flex w-[50px] h-[50px] rounded-full p-1"
                    uri={player?.profilePicture}
                  />

                  <span className="text-placeholder">{player.username}</span>
                </div>
                <button
                  className={`flex max-w-[150px] h-[35px] rounded-full p-1 items-center justify-center w-2/5 max-md:w-1/3 ${challengedPlayers.includes(player._id)
                    ? "bg-darkGray"
                    : "bg-primary"
                    }`}
                  onClick={() => handleChallenge(player._id)}
                >
                  <span>
                    {challengedPlayers.includes(player._id)
                      ? "Cancel"
                      : "Invite"}
                  </span>
                </button>
              </div>
            </div>
          )}
        </>
      )
    );
  };

  const handleSearch = (id: any) => {
    setChallengedPlayers((prevPlayers) =>
      prevPlayers.includes(id._id)
        ? prevPlayers.filter((playerId) => playerId !== id._id)
        : [...prevPlayers, id._id]
    );
    if (isTournament) {
      dispatch(
        inviteToTournament({ payload: { userId: id._id, tournamentId } })
      );
    } else {
      dispatch(inviteToMatch({ matchId: matchId, userId: id._id }));
    }
    setChallengeInitiated(true);
  };
  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen ">
      <div className="container flex items-center flex-col gap-y-[20px] md:my-16 max-md:p-3">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5
        w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal">
              Invite Others
            </span>
            <button className=" w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex flex-row justify-center md:mt-10 mt-[50px] max-md:w-full">
            <div className="absolute text-white md:w-[58vw] w-[85vw]">
              <SearchBar
                searchIcon
                placeholder="Search people"
                type="invitePeople"
                setResults={(v) => setResults(v)}
                onClick={handleSearch}
                selected={challengedPlayers}
              />
            </div>
          </div>
          <div
            className={`gap-3 h-1/2 flex flex-col md:mt-20 mt-24 ${miscsStyles.beautifulScrollbarHidden}`}
          >
            {renderPlayers()}
          </div>
          <div className="flex flex-row justify-evenly items-center mt-8">
            <div className="flex flex-col gap-8 max-md:w-1/2 ">
              <ButtonDc
                text={challengeInitiated ? "Next" : "Skip"}
                action={() => onPressSkip?.()}
                type="primary"
              />
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={tournament.loading || matchMaking.loader} />
    </div>
  );
};

export default InviteOthersScreen;
