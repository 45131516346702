import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { useState } from "react";
import { nameWithSpace, paragraphRegex } from "constant/validations";
import { techSupport } from "store/user/action";
import { toast } from "react-toastify";
import { ReactComponent as Support } from "assets/settings/support.svg";
import { ReactComponent as UserVoice } from "assets/user_voice.svg";

import LoadingAnim from "components/Loader";
import CloseIcon from "assets/close-button.svg";
import ButtonDc from "components/Button";
enum categories {
  ACCOUNT = "Account",
  BILLING = "Billing",
  TECHNICAL = "Technical",
  GAMEPLAY = "Gameplay",
  FEEDBACK = "Feedback",
  BUG = "Bug",
  UI = "User Interface",
  OTHER = "Other",
}

const RequestSupportScreen = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const [category, setCategory] = useState("");
  const [problem, setProblem] = useState("");

  const options = [
    { text: "Select Problem Category", value: "" },
    { text: "Account Issues", value: categories.ACCOUNT },
    { text: "Billing and Payments", value: categories.BILLING },
    { text: "Technical Support", value: categories.TECHNICAL },
    { text: "Gameplay Issues", value: categories.GAMEPLAY },
    { text: "Feedback and Suggestions", value: categories.FEEDBACK },
    { text: "Report a Bug", value: categories.BUG },
    { text: "User Interface Issues", value: categories.UI },
    { text: "Other", value: categories.OTHER },
  ];

  const handleClose = () => navigate("/settings");

  const onSubmit = () => {
    if (category.length && problem.length < 0)
      return toast.error("Please enter valid data");
    dispatch(
      techSupport({
        payload: { topic: category, description: problem },
        callback: () => {
          toast.success("Message sent successfully");
          setCategory("");
          setProblem("");
        },
      })
    );
  };
  const isSubmitButtonDisabled =
    !(nameWithSpace.test(category) && category.length > 0) ||
    !(paragraphRegex.test(problem) && problem.length > 0);
  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen">
      <div className="container flex items-center flex-col gap-y-[20px] md:my-16 max-md:p-3">
        <div className="backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5 w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh] overflow-y-auto">
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <div className="flex flex-row items-center gap-4">
              <UserVoice className="h-8 w-8 max-md:h-6 max-md:w-6" />
              <span className="text-3xl max-md:text-xl text-white font-normal">
                Request Support
              </span>
            </div>
            <button
              className=" h-8 w-8 max-md:h-6 max-md:w-6"
              onClick={handleClose}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>

          <div className="flex flex-col max-md:w-full items-center justify-center gap-5 mt-12 ">
            <Support className="w-[16vh] h-[16vh]" />
            <div className=" bg-cardBackground border border-white rounded-3xl h-[40vh] md:min-h-[300px] md:w-[30vw] w-full md:min-w-[500px] flex flex-col gap-5 md:p-10 p-5">
              <select
                className="bg-borderColor align-center h-16 rounded-lg p-2 w-full outline-none text-left text-white"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {options.map((option, index) => (
                  <option
                    key={index}
                    value={option.value}
                    style={{
                      backgroundColor: "#3D3D3D",
                      color: "white",
                    }}
                    className="bg-borderColor text-white flex"
                  >
                    {option.text}
                  </option>
                ))}
              </select>

              <textarea
                className=" bg-borderColor align-center h-[30vh] rounded-lg p-2 w-full outline-none text-left text-white"
                placeholder="Problem Description"
                value={problem}
                onChange={(e) => setProblem(e.target.value)}
              />
            </div>
            <ButtonDc
              text="Submit"
              type={"primary"}
              action={onSubmit}
              disabled={isSubmitButtonDisabled}
            />
          </div>
        </div>
      </div>
      <LoadingAnim loading={user.loader} />
    </div>
  );
};

export default RequestSupportScreen;
