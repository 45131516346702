import { challengeTypesEnum } from "./constants";

import Game2048 from "assets/games/2048/game2048.svg";
import Game2048Logo from "assets/games/2048/2048-logo.svg";
import Game2048Bg from "assets/games/2048/2048-bg.svg";

import BubbleShooter from "assets/games/BubbleShooter/BubbleShooter.svg";
import BubbleShooterBg from "assets/games/BubbleShooter/bubbleShooterBg.svg";
import BubbleShooterLogo from "assets/games/BubbleShooter/bubbleShooterLogo.png";

import BubbleShooterSpace from "assets/games/BubbleShooterSpace/bubble-shooter-space.svg";
import BubbleShooterSpaceBg from "assets/games/BubbleShooterSpace/BubbleShooterSpaceBg.svg";
import BubbleShooterSpaceLogo from "assets/games/BubbleShooterSpace/BubbleShooterSpaceLogo.png";

import MemoryMatch1 from "assets/games/MemoryMatch1/memoryMatch.svg";
import MemoryMatch1Bg from "assets/games/MemoryMatch1/MemoryMatch1bg.svg";
import MemoryMatch1Logo from "assets/games/MemoryMatch1/MemoryMatch1Logo.svg";

import MemoryMatch2 from "assets/games/MemoryMatch2/memoryMatch2.svg";
import MemoryMatch2Bg from "assets/games/MemoryMatch2/MemoryMatch2Bg.svg";
import MemoryMatch2Logo from "assets/games/MemoryMatch2/MemoryMatch2Logo.svg";

import MemoryMatch3 from "assets/games/MemoryMatch3/memoryMatch3.svg";
import MemoryMatch3Bg from "assets/games/MemoryMatch3/MemoryMatch3Bg.svg";
import MemoryMatch3Logo from "assets/games/MemoryMatch3/MemoryMatch3Logo.svg";

import TapRush from "assets/games/TapRush/tapRush.svg";
import TapRushBg from "assets/games/TapRush/tapRushBg.svg";
import TapRushLogo from "assets/games/TapRush/tapRushLogo.png";

import BombSweeper from "assets/games/BombSweeper/BombSweeper.svg";
import BombSweeperBg from "assets/games/BombSweeper/BombSweeperBg.svg";
import BombSweeperLogo from "assets/games/BombSweeper/BombSweeperLogo.png";

import Chess from "assets/games/Chess/chess.svg";
import ChessBg from "assets/games/Chess/chessBg.svg";
import ChessLogo from "assets/games/Chess/chessLogo.png";

import Snake from "assets/games/Snake/snake.svg";
import SnakeBg from "assets/games/Snake/snakeBg.svg";
import SnakeLogo from "assets/games/Snake/snakeLogo.png";

import GuessTheName from "assets/games/GuessTheName/GuessTheName.svg";
import GuessTheNameBg from "assets/games/GuessTheName/guessTheNameBg.svg";
import GuessTheNameLogo from "assets/games/GuessTheName/guessTheNameLogo.png";

import FlyKite from "assets/games/FlyKite/flyKite.svg";
import FlyKiteBg from "assets/games/FlyKite/flyKiteBg.png";
import FlyKiteLogo from "assets/games/FlyKite/flyKiteLogo.png";

import RPS from "assets/games/Rps/rps.svg";
import RPSBg from "assets/games/Rps/rpsBg.svg";
import RPSLogo from "assets/games/Rps/rpsLogo.png";

import Puzzle15 from "assets/games/15Puzzle/puzzle.svg";
import Puzzle15Bg from "assets/games/15Puzzle/15PuzzleBg.svg";
import Puzzle15Logo from "assets/games/15Puzzle/15PuzzleLogo.png";

import Sudoku from "assets/games/Sudoku/sudoku.svg";
import SudokuBg from "assets/games/Sudoku/sudokuBg.svg";
import SudokuLogo from "assets/games/Sudoku/sudokuLogo.png";

import Connect4 from "assets/games/Connect4/connect4.svg";
import Connect4Bg from "assets/games/Connect4/connect4Bg.png";
import Connect4Logo from "assets/games/Connect4/connect4Logo.png";

import PopTheBalloon from "assets/games/PopTheBalloon/popBalloon.svg";
import PopTheBalloonBg from "assets/games/PopTheBalloon/popBalloonBg.png";
import PopTheBalloonLogo from "assets/games/PopTheBalloon/popBalloonLogo.png";

import ColorSort from "assets/games/ColorSort/colorSort.svg";
import ColorSortBg from "assets/games/ColorSort/colorSortBg.png";
import ColorSortLogo from "assets/games/ColorSort/colorSortLogo.png";

import Dino from "assets/games/PlayDino/dino.svg";
import DinoBg from "assets/games/PlayDino/dinoBg.png";
import DinoLogo from "assets/games/PlayDino/dinoLogo.png";

import Tetris from "assets/games/Tetris/tetris.svg";
import TetrisBg from "assets/games/FallingBall/fallingBallBg.png";
import TetrisLogo from "assets/games/Tetris/tetrisLogo.png";

import BrickBraker from "assets/games/BrickBraker/brickBreaker.svg";
import BrickBrakerBg from "assets/games/BrickBraker/brickBreakerBg.png";
import BrickBrakerLogo from "assets/games/BrickBraker/brickBreakerLogo.png";

import FallingBall from "assets/games/FallingBall/fallingBall.svg";
import FallingBallBg from "assets/games/FallingBall/fallingBallBg.png";
import FallingBallLogo from "assets/games/FallingBall/fallingBallLogo.png";

import NinetyNine from "assets/games/NinetyNine/ninetyNine.svg";
import NinetyNineBg from "assets/games/NinetyNine/ninetyNineBg.png";
import NinetyNineLogo from "assets/games/NinetyNine/ninetyNineLogo.png";

import SuperSavivor from "assets/games/SuperSavivor/superSavivor.svg";
import SuperSavivorBg from "assets/games/SuperSavivor/superSavivorBg.png";
import SuperSavivorLogo from "assets/games/SuperSavivor/superSavivorLogo.png";

import CubeDodge from "assets/games/CubeDodge/cubeDodge.svg";
import CubeDodgeBg from "assets/games/CubeDodge/cubeDodgeBg.png";
import CubeDodgeLogo from "assets/games/CubeDodge/cubeDodgeLogo.png";

import HangMan from "assets/games/HangMan/hangMan.svg";
import HangManBg from "assets/games/HangMan/hangManBg.png";
import HangManLogo from "assets/games/HangMan/hangManLogo.png";

import Lumines from "assets/games/Lumines/lumines.svg";
import LuminesBg from "assets/games/Lumines/luminesBg.png";
import LuminesLogo from "assets/games/Lumines/luminesLogo.png";

import WordMemory from "assets/games/WordMemory/wordMemory.svg";
import WordMemoryBg from "assets/games/WordMemory/wordMemoryBg.png";
import WordMemoryLogo from "assets/games/WordMemory/wordMemoryLogo.png";

import LockPick from "assets/games/LockPick/LockPick.svg";
import LockPickBg from "assets/games/LockPick/LockPickBg.png";
import LockPickLogo from "assets/games/LockPick/LockPickLogo.png";

import TileBreaker from "assets/games/TileBreaker/TileBreaker.svg";
import TileBreakerBg from "assets/games/TileBreaker/TileBreakerBg.png";
import TileBreakerLogo from "assets/games/TileBreaker/TileBreakerLogo.png";

const dev = process.env.REACT_APP_ENV === "DEV"

export const games = [
  {
    id: dev ? "6721dd32207175c598295586" : "672323f82753bbcacc6adf6e",
    title: "2048",
    image: Game2048,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/2048game",
    backgroundImage: Game2048Bg,
    gameLogo: Game2048Logo,
    textColor: "#AD25D0",
  },
  {
    id: dev ? "6721de81207175c5982955a5" : "672323b52753bbcacc6adf5f",
    title: "Bubble Shooter",
    image: BubbleShooter,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/bubble-shooter",
    backgroundImage: BubbleShooterBg,
    gameLogo: BubbleShooterLogo,
    textColor: "#AC1598",
  },
  {
    id: dev ? "6721de92207175c5982955a8" : "672323a12753bbcacc6adf5c",
    title: "Bubble Shooter Space",
    image: BubbleShooterSpace,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/bubble-shooter-space",
    backgroundImage: BubbleShooterSpaceBg,
    gameLogo: BubbleShooterSpaceLogo,
    textColor: "#44024A",
    textColor2: "#C2EF00",

  },
  {
    id: dev ? "6721de5d207175c59829559c" : "672323e92753bbcacc6adf6b",
    title: "Memory Match",
    image: MemoryMatch1,
    gameType: challengeTypesEnum.COUNTDOWN,
    route: "/memory-match1",
    backgroundImage: MemoryMatch1Bg,
    gameLogo: MemoryMatch1Logo,
    textColor: "#00451B",
  },
  {
    id: dev ? "6721de6e207175c59829559f" : "672323e42753bbcacc6adf68",
    title: "Memory Match 2",
    image: MemoryMatch2,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/memory-match2",
    backgroundImage: MemoryMatch2Bg,
    gameLogo: MemoryMatch2Logo,
    textColor: "#FE5D29",
  },
  {
    id: dev ? "6721de72207175c5982955a2" : "672323dc2753bbcacc6adf65",
    title: "Memory Match 3",
    image: MemoryMatch3,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/memory-match3",
    backgroundImage: MemoryMatch3Bg,
    gameLogo: MemoryMatch3Logo,
    textColor: "#6C822D",
  },
  {
    id: dev ? "6721dea3207175c5982955ab" : "6723238e2753bbcacc6adf59",
    title: "Tap Rush",
    image: TapRush,
    gameType: challengeTypesEnum.COUNTDOWN,
    route: "/tap-rush",
    backgroundImage: TapRushBg,
    gameLogo: TapRushLogo,
    textColor: "#3D3A65",
  },
  {
    id: dev ? "6721defe207175c5982955b4" : "6723235b2753bbcacc6adf53",
    title: "Bomb Sweeper",
    image: BombSweeper,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/bomb-sweeper",
    backgroundImage: BombSweeperBg,
    gameLogo: BombSweeperLogo,
    textColor: "#000000",
    textColor2: "#FFFFFF",
  },
  {
    id: dev ? "6721deda207175c5982955b1" : "6723237b2753bbcacc6adf56",
    title: "Chess",
    image: Chess,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/chess",
    backgroundImage: ChessBg,
    gameLogo: ChessLogo,
    textColor: "#000000",
    textColor2: "#FFFFFF",
  },
  {
    id: dev ? "6721df12207175c5982955b7" : "67231e882753bbcacc6adf4a",
    title: "Snake",
    image: Snake,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/snake",
    backgroundImage: SnakeBg,
    gameLogo: SnakeLogo,
    textColor: "#2E7D32",
  },
  {
    id: dev ? "6721df20207175c5982955ba" : "67231e7a2753bbcacc6adf47",
    title: "Guess The Name",
    image: GuessTheName,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/guess-the-name",
    backgroundImage: GuessTheNameBg,
    gameLogo: GuessTheNameLogo,
    textColor: "#75B325",
  },

  {
    id: dev ? "6721df2f207175c5982955bd" : "67231e5f2753bbcacc6adf44",
    title: "Fly Kite",
    image: FlyKite,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/fly-kite",
    backgroundImage: FlyKiteBg,
    gameLogo: FlyKiteLogo,
    textColor: "#FD5143",
  },

  {
    id: dev ? "6721df3d207175c5982955c0" : "67231e332753bbcacc6adf3e",
    title: "Rock Paper Scissors",
    image: RPS,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/rps",
    backgroundImage: RPSBg,
    gameLogo: RPSLogo,
    textColor: "#F7931E",
  },
  {
    id: dev ? "6721df5d207175c5982955c6" : "67231e262753bbcacc6adf3b",
    title: "15 Puzzle",
    image: Puzzle15,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/15puzzle",
    backgroundImage: Puzzle15Bg,
    gameLogo: Puzzle15Logo,
    textColor: "#2E7D32",
  },
  {
    id: dev ? "6721df68207175c5982955c9" : "67231e1c2753bbcacc6adf38",
    title: "Sudoku",
    image: Sudoku,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/sudoku",
    backgroundImage: SudokuBg,
    gameLogo: SudokuLogo,
    textColor: "#2B2F31",
    textColor2: "#48CFF7"
  },
  {
    id: dev ? "6721df70207175c5982955cc" : "67231e112753bbcacc6adf35",
    title: "Connect4",
    image: Connect4,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/connect-four",
    backgroundImage: Connect4Bg,
    gameLogo: Connect4Logo,
    textColor: "#0072D2",
    textColor2: "#FFCE67"
  },
  {
    id: dev ? "6721df7e207175c5982955cf" : "67231e032753bbcacc6adf32",
    title: "Pop the Balloon",
    image: PopTheBalloon,
    gameType: challengeTypesEnum.COUNTDOWN,
    route: "/pop-balloon",
    backgroundImage: PopTheBalloonBg,
    gameLogo: PopTheBalloonLogo,
    textColor: "#F91A0B"
  },
  {
    id: dev ? "6721dfb6207175c5982955d7" : "67231de22753bbcacc6adf2c",
    title: "Color Sort",
    image: ColorSort,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/color-sort",
    backgroundImage: ColorSortBg,
    gameLogo: ColorSortLogo,
    textColor: "#000000",

  },
  {
    id: dev ? "6721df9d207175c5982955d2" : "67231df32753bbcacc6adf2f",
    title: "Dino",
    image: Dino,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/dino",
    backgroundImage: DinoBg,
    gameLogo: DinoLogo,
    textColor: "#000000",
  },
  {
    id: dev ? "6721dfe1207175c5982955dd" : "67231dd22753bbcacc6adf29",
    title: "Tetris",
    image: Tetris,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/tetris",
    backgroundImage: TetrisBg,
    gameLogo: TetrisLogo,
    textColor: "#FF0A1F",
  },
  {
    id: dev ? "6721dfee207175c5982955e0" : "67231d512753bbcacc6adf23",
    title: "Brick Breaker",
    image: BrickBraker,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/brick-breaker",
    backgroundImage: BrickBrakerBg,
    gameLogo: BrickBrakerLogo,
    textColor: "#9505E3",
  },
  {
    id: dev ? "6721e004207175c5982955e3" : "67231d3e2753bbcacc6adf20",
    title: "Falling Ball",
    image: FallingBall,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/falling-ball",
    backgroundImage: FallingBallBg,
    gameLogo: FallingBallLogo,
    textColor: "#3D3A65",
  },
  {
    id: dev ? "6721e015207175c5982955e6" : "67231d2c2753bbcacc6adf1d",
    title: "Ninety Nine",
    image: NinetyNine,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/ninety-nine",
    backgroundImage: NinetyNineBg,
    gameLogo: NinetyNineLogo,
    textColor: "#000000",
  },
  {
    id: dev ? "6721e025207175c5982955e9" : "67231d1c2753bbcacc6adf1a",
    title: "Space Survivor",
    image: SuperSavivor,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/space-survivor",
    backgroundImage: SuperSavivorBg,
    gameLogo: SuperSavivorLogo,
    textColor: "#AD25D0",
  },
  {
    id: dev ? "6721e034207175c5982955ec" : "67231d042753bbcacc6adf17",
    title: "CubeDodge",
    image: CubeDodge,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/cube-dodge",
    backgroundImage: CubeDodgeBg,
    gameLogo: CubeDodgeLogo,
    textColor: "#FF0A1F",
  },
  {
    id: dev ? "6721e05e207175c5982955f4" : "67231b9f2753bbcacc6adf0e",
    title: "Hangman",
    image: HangMan,
    gameType: challengeTypesEnum.SPEEDRUN,
    route: "/hangman",
    backgroundImage: HangManBg,
    gameLogo: HangManLogo,
    textColor: "#000000",
  },
  {
    id: dev ? "6721e07d207175c5982955f7" : "67231b912753bbcacc6adf0b",
    title: "Lumines",
    image: Lumines,
    gameType: challengeTypesEnum.COUNTDOWN,
    route: "/lumines",
    backgroundImage: LuminesBg,
    gameLogo: LuminesLogo,
    textColor: "#E46718",
  },
  {
    id: dev ? "6721e0b5207175c598295600" : "67231b4e2753bbcacc6adf02",
    title: "Word Memory",
    image: WordMemory,
    gameType: challengeTypesEnum.COUNTDOWN,
    route: "/word-memory",
    backgroundImage: WordMemoryBg,
    gameLogo: WordMemoryLogo,
    textColor: "#005AE0",
  },

  {
    id: dev ? "6721e085207175c5982955fa" : "67231b822753bbcacc6adf08",
    title: "Lock Pick",
    image: LockPick,
    gameType: challengeTypesEnum.COUNTDOWN,
    route: "/lock-pick",
    backgroundImage: LockPickBg,
    gameLogo: LockPickLogo,
    textColor: "#2E86D5",
  },
  {
    id: dev ? "6721e0a3207175c5982955fd" : "67231b672753bbcacc6adf05",
    title: "Tile Breaker",
    image: TileBreaker,
    gameType: challengeTypesEnum.COUNTDOWN,
    route: "/tile-breaker",
    backgroundImage: TileBreakerBg,
    gameLogo: TileBreakerLogo,
    textColor: "#48809F",
  },
];
