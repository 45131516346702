import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { setDefaultStream } from "store/streaming/streamingSlice";
import {
  createStream,
  streamForExternal,
} from "store/streaming/actions";
import { streamModes } from "types/constants";
import { toast } from "react-toastify";

import { ReactComponent as SteamingIcon } from "assets/sideBar/streaming.svg";
import { ReactComponent as DcLogo } from "assets/streaming/dc_logo.svg";
import { ReactComponent as Clipboard } from "assets/clipboard.svg";
import { useState } from "react";
import CloseIcon from "assets/close-button.svg";

import miscsStyles from "styles/miscs/miscs.module.scss";
import ButtonDc from "components/Button";
import Sidebar from "components/SideBar";
import StreamingLiveViewer from "components/StreamingLiveViewer";
import LoadingAnim from "components/Loader";
import Menu from "components/Menu";
import NotificationModal from "components/Notifications";

export default function PreviewStreamScreen({ }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loader } = useAppSelector((state) => state.streaming);
  const [visibleOptionsMenu, setVisibleOptionsMenu] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(false);

  const user = useAppSelector((state: RootState) => state.user.user);
  const {
    streamTitle,
    streamDescription,
    streamTags,
    streamCategory,
    thumbnail,
    otherStreamKey,
    otherStreamUrl,
  } = useAppSelector((state: RootState) => state.streaming);
  const { from } = useParams();

  const metadata = {
    title: streamTitle,
    category: streamCategory,
    description: streamDescription,
    tags: streamTags,
  };
  const handleClose = () => {
    navigate(`/stream/streaming-method`);
  };

  const handleCancel = () => {
    navigate(`/stream/hub`);
  };

  const handleEdit = () => {
    navigate(`/stream/live-setup/${"edit"}`);
  };

  const handleGoLive = () => {

    dispatch(
      createStream({
        payload: {
          streamType: "IN_APP",
          streamMode: streamModes.HUB,
          metadata,
          image: thumbnail,
        },
        callback: (data) => {
          if (otherStreamKey.length > 0) {
            dispatch(
              streamForExternal({
                payload: { otherStreams: [otherStreamKey, otherStreamUrl] },
                callback: () => { },
              })
            );
          }

          if (data.streamRoomId) {
            dispatch(
              setDefaultStream({
                streamRoomId: data.streamRoomId,
                streamToken: data.streamToken,
                streamTitle: "",
                streamerId: "",
                thumbnail: { thumbnail },
                upstreamKey: "",
                upstreamUrl: "",
                isStreaming: true,
                streamType: "IN_APP",
                _id: user?._id,
                username: user?.username,
                profilePicture: user?.profilePicture,
                isHost: true,
                createdAt: data.createdAt ? data?.createdAt : new Date(),
              })
            );
            navigate(`/stream/preview-live-stream`);
          } else {
            toast.error(
              "You are currently streaming. Please end your current stream to continue!"
            );
          }
        },
      })
    );
  };

  return (
    <div className="mx-auto w-full h-screen  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar
        onOptions={() => setVisibleOptionsMenu(true)}
        onNotifications={() => setVisibleNotifications(true)}
      />
      <div className=" w-full md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20 ">
        <div className="md:mt-[20px] flex flex-row justify-between items-center w-[80vw] max-md:w-[95vw]">
          <span className="text-white text-3xl text-center max-md:text-2xl">
            Stream Preview
          </span>
          <button
            className=" w-[25px] h-[25px] right-10 max-md:w-[15px]"
            onClick={handleClose}
          >
            <img src={CloseIcon} alt="Close" />
          </button>
        </div>
        <div
          className={`flex flex-col md:p-4 gap-10 max-md:gap-8 items-center md:gap-10 rounded-2xl  bg-componentBackground md:bg-opacity-80 w-[80vw] h-[80vh] max-md:w-[95vw] ${miscsStyles.beautifulScrollbarHidden}`}
        >
          {from === "in-app" && (
            <span className=" text-placeholder text-xl font-normal">
              After configuring all settings, you can broadcast your stream by
              tapping the Go Live button.
            </span>
          )}

          <div className="flex flex-col w-full gap-8 items-center">
            {from === "in-app" ? (
              <div className="relative flex justify-center items-center desktop:w-1/3 border-[6px] min-h-[300px] max-w-[800px] md:w-2/3 border-borderColor rounded-[46px]">
                <img
                  src={thumbnail}
                  alt="imageUpload"
                  className=" min-h-[300px]  w-full rounded-[46px]"
                />
                <div className=" bg-darkerGray opacity-95 p-2 w-2/3 h-1/2 rounded-xl absolute flex flex-col justify-center items-center">
                  <DcLogo />
                  <span className="  text-white text-sm mt-3 font-normal">
                    Stream Starting Soon
                  </span>
                </div>
              </div>
            ) : (
              <StreamingLiveViewer
                controls
                hideDetails
                userName={"TESTUSER"}
                uri="https://www.w3schools.com/html/mov_bbb.mp4"
                watchingCount={10}
                streamTitle={"Manu Live Gaming is live Farlight84 Episode 10"} //TODO
              // onPressStream={handleOnPressStream}
              />
            )}

            {from === "external" && (
              <>
                <div className="flex flex-col desktop:w-2/3 max-w-[500px] gap-2 w-full">
                  <span className="text-white text-lg">
                    Start sending us your video from your streaming software
                  </span>
                  <div className="flex flex-row items-center justify-between bg-borderColor rounded-xl p-2 px-4">
                    <div className="flex flex-col truncate desktop:max-w-[30vw]">
                      <span className=" text-placeholder text-sm">
                        Stream Key
                      </span>
                      <span className="text-white text-lg">{"streamKey"}</span>
                    </div>
                    <button
                      className="p-2 bg-darkenGray rounded-full hover:bg-placeholder"
                      onClick={() => navigator.clipboard.writeText("streamKey")}
                    >
                      <Clipboard />
                    </button>
                  </div>
                  <div className="flex flex-row items-center justify-between bg-borderColor rounded-xl p-2 px-4 ">
                    <div className="flex flex-col truncate desktop:max-w-[30vw] ">
                      <span className="text-placeholder text-sm">
                        Upstream URL
                      </span>
                      <span className="text-white text-lg ">
                        {"upstreamUrl"}
                      </span>
                    </div>

                    <button
                      className="p-2 bg-darkenGray rounded-full  hover:bg-placeholder"
                      onClick={() =>
                        navigator.clipboard.writeText("upstreamUrl")
                      }
                    >
                      <Clipboard />
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className=" rounded-xl p-4 flex flex-col gap-8 w-2/3 max-md:w-full justify-between">
              <div className="flex  flex-col gap-2">
                <div className="flex flex-row justify-between">
                  <span className="text-white font-bold ">{streamTitle}</span>
                  <button
                    className="text-white border max-md:h-10 rounded-lg px-3 py-1 hover:border-primaryActive"
                    onClick={handleEdit}
                  >
                    Edit
                  </button>
                </div>
                {streamTags && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    {streamTags.map((tag, index) => (
                      <span
                        className="text-blueBg max-md:text-justify"
                        key={index}
                      >
                        #{tag}
                      </span>
                    ))}
                  </div>
                )}

                <span className="text-white max-md:text-justify">
                  {streamDescription}
                </span>
              </div>
            </div>
          </div>
          {from === "in-app" && (
            <div className="flex flex-row gap-5 max-md:flex-col-reverse max-md:w-full">
              <ButtonDc
                text={"Cancel"}
                action={handleCancel}
                type="cancel"
                short
              />
              <ButtonDc
                text="Go Live"
                action={handleGoLive}
                type="primary"
                icon={<SteamingIcon className="mr-2" />}
                short
              />
            </div>
          )}
        </div>
      </div>
      <LoadingAnim loading={loader} />
      <Menu
        visible={visibleOptionsMenu}
        onClose={() => setVisibleOptionsMenu(false)}
      />
      <NotificationModal
        visible={visibleNotifications}
        onClose={() => setVisibleNotifications(false)}
      />
    </div>
  );
}
