import { messaging } from "firebaseConfig/firebase";
import { onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setMessage } from "store/fcm/fcmSlice";
import { useAppSelector } from "store/hooks";
import { handleChatNotificationCount, handleMatchCommentsNotificationCount, handleNotificationCount } from "utils/noitificationHandler";
import { RootState } from "@store/index";
import { ReactComponent as ChatIcon } from "assets/sideBar/chats.svg";

const FCMStore = () => {
  const dispatch = useDispatch();
  const userId = useAppSelector((state: RootState) => state.user).user?._id;
  const currentMatch = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;

  const freePlay = currentMatch?.cubeWager === 0;

  onMessage(messaging, (payload) => {
    dispatch(setMessage(payload));

    if (userId === payload?.data?.senderId) {
      return;
    }
    switch (payload?.data?.type || payload?.data?.types) {
      case "DO NOT SHOW":
        break;

      case "PRIVATE_MESSAGE":
      case "COMMUNITY_MESSAGE":
        handleChatNotificationCount(1);
        return toast(`You have a new message from ${payload?.data?.sender}`, {
          icon: <ChatIcon />,
        });

      case "MATCH_MESSAGE":
        handleMatchCommentsNotificationCount(1);
        return toast(`You have a new match comment from ${payload?.data?.sender}`, {
          icon: <ChatIcon />,
        });

      case "TOURNAMENT_INVITATION":
      case "MATCH_CHALLENGE":
      case "CHALLENGE_INVITE":
        toast.info("You have received a new Invitation/Challenge");
        handleNotificationCount(1);
        return;

      case "READY_FOR_TOURNAMENT":
        toast.info("Your scheduled tournament will start in 10 minutes");
        return;

      case "APPROVE_WALLET":
        toast.info(payload?.data?.message);
        handleNotificationCount(1);
        return;

      case "NEW_FOLLOWER":
        toast.info(payload?.data?.message);
        handleNotificationCount(1);
        return;

      default:
        if (!freePlay) toast.info(payload?.data?.message);
    }
  });
  return <></>;
};

export default FCMStore;
