import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { setSelectedRound } from "store/matchMaking/matchMakingSlice";
import { setSelectedTournamentRound } from "store/tournament/tournamentSlice";
import CloseIcon from "assets/close-button.svg";
import BackIcon from "assets/arrow-right.svg";
import ButtonDc from "components/Button";
export default function HowManyRoundsScreen({ }) {
  let { from } = useParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const dispatch: AppDispatch = useAppDispatch();
  const matchMaking = useAppSelector((state: RootState) => state.matchMaking);
  const isNextDisabled = !selected;
  const duelPath = matchMaking.selectedDuelPath;
  const tournaments = useAppSelector((state: RootState) => state.tournament);
  const rounds =
    duelPath === "CREATE_TOURNAMENT"
      ? tournaments.selectedGame?.rounds
      : matchMaking.selectedGame?.rounds;

  const onCancel = () => {
    navigate("/wagering");
  };
  const onBack = () => {
    navigate("/duels/select-game-type");
  };

  const handleNext = () => {
    if (duelPath === "CREATE_TOURNAMENT") {
      dispatch(setSelectedTournamentRound(selected));
    } else {
      dispatch(setSelectedRound(selected));
    }
    if (from) return navigate("/duels/duel-overview");
    navigate("/duels/how-many-cubes");
  };

  const renderRounds = (item: any, index: number) => {
    const selectedItem = item === selected;
    return (
      <button
        className={`bg-borderColor h-16 w-52 rounded-lg p-1.5 transform transition-transform hover:scale-105 hover:shadow-lg ${selectedItem ? "border-4 border-[#FFD482]" : ""
          }`}
        key={index}
        onClick={() => setSelected(item)}
      >
        <span className="text-white text-xl">{item.name}</span>
      </button>
    );
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="w-full mb-5 flex justify-between">
            {duelPath === "CREATE_TOURNAMENT" ? <span className="text-lg text-subText items-center font-normal uppercase">
              {duelPath} {'>'} {tournaments?.selectedGame?.name} {'>'} {tournaments?.selectedPlatform} {'>'} {tournaments?.selectedGameType.type}
            </span>
              :
              <span className="text-lg text-subText items-center font-normal uppercase">
                {duelPath} {'>'} {matchMaking?.matchSchedule} {'>'} {matchMaking?.selectedGame?.name} {'>'} {matchMaking?.selectedPlatform} {'>'} {matchMaking?.selectedGameType.type}
              </span>
            }

          </div>
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row items-center gap-5">
                <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                  <img src={BackIcon} alt="Close" />
                </button>
                <span className="text-3xl text-white items-center font-normal">
                  How many rounds?
                </span>
              </div>

              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <span className="text-subText ml-11 text-xl font-normal">
              How many rounds you would like to play...
            </span>
          </div>

          <div className="flex flex-row items-center justify-center gap-[3em] my-8 max-md:flex-col">
            {rounds?.map(renderRounds)}
          </div>
          <ButtonDc
            type="primary"
            className="absolute bottom-10 max-md:w-full "
            textClassName="max-md:text-sm"
            text={'Next'}
            action={handleNext}
            disabled={isNextDisabled}
          />
        </div>
      </div>
    </div>
  );
}
