import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { addPaypalAccount, getLinkedAccounts } from "store/linkedAccounts/action";
import ButtonDc from "components/Button";
import LoadingAnim from "components/Loader";
import CloseIcon from "assets/close-button.svg";
import { ReactComponent as PaypalIcon } from "assets/paypal.svg";
import { ReactComponent as PaypalLogo } from "assets/paypal-circled.svg";
import { setLoaderState } from "store/linkedAccounts/linkedAccountsSlice";

export default function PaypalScreen({ }) {
  const { from } = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => state.linkedAccounts)?.loading;
  const linkedAccounts = useAppSelector(
    (state: RootState) => state.linkedAccounts
  );
  const renderRedirectUrl = () => {
    switch (from) {
      case "wallet":
        return "wallet/withdraw-funds";
      case "accounts":
        return "settings/manage-accounts";
      case 'savePaypal':
        return "duels/duel-overview/paypalSaved";
      case 'connectPaypal':
        return "duels/duel-overview/paypalConnected"
      default:
        return "duels/duel-overview/paypalConnected"
    }
  };

  const handleClose = () => {
    if (from === "accounts") return navigate("/settings/manage-accounts");
    navigate("/");
  };

  const onPressHowToVerify = () => {
    const url = process.env.REACT_APP_PAYPAL_VERIFY_URL;
    window.open(url, "_blank");
  };

  const onPressConnectPaypal = () => {
    window.location.href = (`${process.env.REACT_APP_PAYPAL_URL}`)
  };

  useEffect(() => {
    if (window.location?.href.includes("paypalattributes")) {
      const code = window.location?.href?.split("?code=")?.[1]?.split("&scope=")?.[0];
      if (code) onContinue(code)
    }
  }, [window.location?.href]);

  const onContinue = (code: string) => {
    dispatch(setLoaderState(true));
    dispatch(
      addPaypalAccount({
        payload: { paypalCode: code },
        callback: () => {
          dispatch(getLinkedAccounts()).then(() => {
            dispatch(setLoaderState(false))
            if (from === undefined || !from) return navigate('/duels/duel-overview/paypalConnected');
            return navigate(`/${renderRedirectUrl()}`);
          })
        },
      })
    );
  };

  return (
    <>
      <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat gap-[7em] flex flex-col items-center justify-center h-screen max-md:h-full">
        <LoadingAnim loading={isLoading} />
        <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
          <div
            className="backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(70vw-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh]"
          >
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <button
                className=" w-[25px] h-[25px] absolute right-10 top-10"
                onClick={handleClose}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <div className="flex flex-col items-center gap-[1em] h-[60vh] justify-center mt-10 max-md:flex-col ">
              <PaypalIcon />
              <span className="text-2xl max-md:text-xl text-white items-center text-center font-bold ">
                Sign in To Paypal
              </span>
              <span className="text-xl max-md:text-sm text-subText items-center text-center ">
                You'll need to connect your PayPal account to withdraw your
                earnings.
              </span>
              <button
                className="p-4 bg-borderColor rounded-[20px]"
                onClick={onPressHowToVerify}
              >
                <span className="text-xl text-primary items-center text-center ">
                  How Do I Verify?
                </span>
              </button>
              <span className="text-base max-md:text-sm text-subText items-center text-center m-4 max-md:mx-1">
                We require all users connect a bank before withdrawing to make
                sure you're a real person
              </span>
            </div>
            <ButtonDc
              type="primary"
              className="max-md:w-full "
              textClassName="max-md:text-sm"
              text={
                linkedAccounts.accounts.paypal
                  ? "Change paypal account"
                  : "Connect your paypal"
              }
              icon={<PaypalLogo />}
              action={onPressConnectPaypal}
            />
          </div>
        </div>
      </div>
    </>
  );
}
