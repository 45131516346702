import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { toast } from "react-toastify";
import { getGameChallenge, getWinnerGameChallenge } from "store/gameHub/action";
import { capturePayment } from "store/wallet/action";
import ButtonDc from "components/Button";
import ImageHolder from "components/ImagePlaceholder";
import PaymentMethod from "components/PaymentMethod";
import LoadingAnim from "components/Loader";
import LostFrame from "assets/lost.png";
import WinnerBg from "assets/winner-bg.svg";
import WinnerFrame from "assets/won.png";
import DrawFrame from 'assets/handshake.png';
import { playerTypes, setResults } from "store/gameHub/gameHubSlice";

const ResultsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isWon, setIsWon] = useState(false);
  const [readyToPay, setReadyToPay] = useState(false);

  const { challengeId } = useParams();
  const { currentGame, loader } = useAppSelector((state: RootState) => state.gameHub)
  const isLoading = useAppSelector((state) => state.wallet)?.loader;
  const isChallengeDraw = currentGame?.isDraw;
  const userId = useAppSelector((state: RootState) => state.user).user?._id;
  const userProfile = useAppSelector((state: RootState) => state.user).user?.profilePicture;

  const hostPlayerId = currentGame?.host;

  const players = currentGame?.players ?? [];

  const opponentPlayerId = players.filter((player: playerTypes) => player._id !== hostPlayerId) ?? null;
  const gameHost = players.find((player: playerTypes) => player?._id === hostPlayerId);
  const gameOpponent = players.find((player: any) => player?._id === opponentPlayerId[0]?._id);

  const res: any = (currentGame?.results) || {};
  const challengeResults = JSON.parse(res)
  const [opponent, setOpponent] = useState({ score: '' });
  const player = challengeResults[hostPlayerId];
  useEffect(() => {
    for (const id in challengeResults) {
      if (id !== hostPlayerId) {
        setOpponent(challengeResults[id]);
        break;
      }
    }
  }, []);

  const cubeWager = currentGame?.cubeWager;
  const hostScore = player?.score;
  const opponentScore = opponent?.score;

  useEffect(() => {
    dispatch(getWinnerGameChallenge({
      matchId: challengeId,
      callback: (data) => {
        dispatch(setResults(data))
        setIsWon(userId === data.winner);
        dispatch(getGameChallenge({ matchId: challengeId }));
      }
    }));
  }, []);

  const handlePaymentSuccess = () => {
    setReadyToPay(false);
    toast.success("Payment successful.");
    navigate(`/gaming/game-payment-status/paid/${challengeId}?payment=success`);
  };

  const handlePay = () => {
    if (cubeWager === 0) {
      dispatch(
        capturePayment({
          payload: {
            orderId: false,
            paymentId: challengeId,
          },
          callback: () => navigate(`/gaming/game-payment-status/${challengeId}?payment=success`),
        })
      );
    } else setReadyToPay(true);
  }

  const onPayPaypal = (token: any) => {
    dispatch(
      capturePayment({
        payload: {
          orderId: token,
          paymentId: token,
        },
        callback: handlePaymentSuccess,
      })
    );
  };

  useEffect(() => {
    const token = window.location?.search
      ?.split("&token=")?.[1]
      ?.split("&")?.[0];
    if (!token) return;
    onPayPaypal(token);
  }, []);

  useEffect(() => {
    window.onbeforeunload = () => {
      return "";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex min-h-screen h-full">
      <LoadingAnim loading={isLoading} />
      {readyToPay && (
        <PaymentMethod
          amount={cubeWager}
          payId={challengeId}
          onSuccess={handlePaymentSuccess}
          from="GAME_HUB"
        />
      )}
      <div className="container flex  flex-col gap-y-[20px] md:my-16 items-center justify-center max-md:p-5">
        <div className=" flex flex-col  gap-y-[20px] md:mb-16 items-center justify-center max-md:mt-24">
          {isWon && <img src={WinnerBg} alt="" className="absolute " />}
          <div className="flex flex-col  items-center justify-center gap-5 mt-16 ">
            <div className="items-center justify-center translate-y-[-10px]">
              <img src={isWon ? WinnerFrame : isChallengeDraw ? DrawFrame : LostFrame} alt="" />
              {!isChallengeDraw && <ImageHolder
                uri={userProfile}
                className="absolute w-[170px] h-[170px] rounded-full top-[22px] left-[87px]"
              />}
            </div>
            <span className="text-5xl text-white items-center font-normal max-md:text-3xl">
              {isWon ? "CONGRATULATIONS!" : isChallengeDraw ? "IT'S A DRAW!" : "LOST!"}
            </span>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-6">
            <div className="justify-center items-center  text-center">
              <span className="text-2xl text-white items-center font-normal text-center max-md:text-xl">
                {isWon ? "YOU ARE THE CHAMPION!" : isChallengeDraw ? "Both players were evenly matched!" : "BETTER LUCK NEXT TIME!"}
              </span>
            </div>
            <div className="justify-center items-center  text-center flex flex-col">
              <span className="text-2xl text-white items-center font-normal text-center max-md:text-xl">
                {gameHost?.username} scored: {hostScore}
              </span>
              <span className="text-2xl text-white items-center font-normal text-center max-md:text-xl">
                {gameOpponent?.username} scored: {opponentScore}
              </span>
            </div>
          </div>
        </div>
        <ButtonDc text="Next" action={handlePay} type="primary" />
      </div>
      <LoadingAnim loading={loader || isLoading} />
    </div>
  );
};

export default ResultsScreen;
