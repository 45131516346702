import Api from "../services/api";
import {
  capturePaymentUrl,
  checkBalanceUrl,
  getBalanceUrl,
  getPaypalHistoryUrl,
  getQuickAccessUrl,
  getReceiversUrl,
  getTrasactionHistoryUrl,
  requestMoneyUrl,
  requestPayoutUrl,
  searchReceiversUrl,
  sendMoneyUrl,
  walletIdUrl,
} from "./urls";

export const getBalanceApi = () => {
  return Api.request({
    method: "get",
    url: getBalanceUrl,
    publicApi: false,
  });
};

export const checkIfBalanceIsEnoughApi = (body: object) => {
  return Api.request({
    method: "put",
    url: checkBalanceUrl,
    body,
  });
};

export const capturePaymentApi = (body: any) =>
  Api.request({
    method: "post",
    url: capturePaymentUrl,
    body,
  });
export const getQuickAccessApi = () => {
  return Api.request({
    method: "get",
    url: getQuickAccessUrl,
  });
};

export const getReceiversApi = () =>
  Api.request({
    method: "get",
    url: getReceiversUrl,
  });

export const sendMoneyApi = (body: object) => {
  return Api.request({
    method: "put",
    url: sendMoneyUrl,
    body,
  });
};
export const requestPayoutApi = (body: object) => {
  return Api.request({
    method: "post",
    url: requestPayoutUrl,
    body,
  });
};

export const requestMoneyApi = (body: object) => {
  return Api.request({
    method: "post",
    url: requestMoneyUrl,
    body,
  });
};

export const walletVerificationApi = (images: any[]) => {
  const formData = new FormData();
  const imagePromises = images.map((image, index) => {
    return fetch(image)
      .then((res) => res.blob())
      .then((blob) => {
        formData.append(`wallet-image`, blob, `image-${index}.png`);
      })
      .catch((error) => {
        console.error(
          `Error in converting image at index ${index} to blob:`,
          error
        );
      });
  });
  return Promise.all(imagePromises)
    .then(() => {
      return Api.request({
        method: "post",
        url: walletIdUrl,
        body: formData,
        file: true,
      });
    })
    .catch((error) => {
      console.error("Error in uploading images:", error);
    });
};

export const getTransactionHistoryApi = (page: number) => {
  return Api.request({
    method: "get",
    url: getTrasactionHistoryUrl + "?page=" + page,
  });
};

export const searchReceiversApi = (query: string) => {
  return Api.request({
    method: "get",
    url: searchReceiversUrl,
    data: {
      query,
    },
  });
};

export const getPaypalHistoryApi = (page: number) => {
  return Api.request({
    method: "get",
    url: getPaypalHistoryUrl + "?paymentMethod=PAYPAL" + "&page=" + page,
  });
};
