import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import {
  resetStream,
  setParticipantsCount,
} from "store/streaming/streamingSlice";
import {
  endStream,
  stopRecording,
} from "store/streaming/actions";
import {
  Constants,
  MeetingProvider,
  useMeeting,
  useParticipant,
  usePubSub,
} from "@videosdk.live/react-sdk";
import { capturePayment } from "store/wallet/action";
import { toast } from "react-toastify";
import EmojiPicker, { Theme } from "emoji-picker-react";
import { setDonation } from "store/wallet/walletSlice";
import { ReactComponent as Live } from "assets/streaming/live.svg";
import { ReactComponent as Emoji } from "assets/streaming/emoji.svg";
import { ReactComponent as Gift } from "assets/streaming/gift.svg";
import { ReactComponent as Dots } from "assets/dots.svg";
import { ReactComponent as MicOn } from "assets/streaming/mic_on.svg";
import { ReactComponent as MicOff } from "assets/streaming/mic_off.svg";
import { ReactComponent as ShareOn } from "assets/streaming/share_on.svg";
import { ReactComponent as ShareOff } from "assets/streaming/share_off.svg";
import { ReactComponent as VideoOn } from "assets/streaming/video_on.svg";
import { ReactComponent as VideoOff } from "assets/streaming/video_off.svg";
import { ReactComponent as EndStream } from "assets/streaming/end.svg";

import CloseIcon from "assets/close-button.svg";
import miscsStyles from "styles/miscs/miscs.module.scss";
import Sidebar from "components/SideBar";
import ImageHolder from "components/ImagePlaceholder";
import ReactPlayer from "react-player";
import Hls from "hls.js";
import LoadingAnim from "components/Loader";
import PaymentMethod from "components/PaymentMethod";
import Menu from "components/Menu";
import NotificationModal from "components/Notifications";
import io, { Socket } from 'socket.io-client';
import { setLoginModalVisible } from "store/auth/authSlice";

type MessageListProps = {
  currentUser?: string;
};
interface GiftModalProps {
  onClose: () => void;
  onSubmit: (amount: any) => void;
}
const MessageList: React.FC<MessageListProps> = ({ currentUser }) => {
  const dispatch = useAppDispatch();
  const { publish, messages } = usePubSub("CHAT");
  const [message, setMessage] = useState<string>("");
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [isGiftModalOpen, setIsGiftModalOpen] = useState<boolean>(false);
  const [readyToPay, setReadyToPay] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { defaultStream } = useAppSelector(
    (state: RootState) => state.streaming
  );
  const isHost = defaultStream?.isHost;

  const GiftModal: React.FC<GiftModalProps> = ({ onClose, onSubmit }) => {
    const [amount, setAmount] = useState<string>("");
    const { donation } = useAppSelector((state: RootState) => state.wallet);
    const { streamId } = useParams();
    const dispatch = useAppDispatch();
    const [newStreamId, setNewStreamId] = useState<string>("");

    const handleButtonClick = (value: string) => {
      setAmount((prev) => {
        const newAmount = Number(prev) + Number(value);
        return newAmount.toString();
      });
    };

    const handlePaymentSuccess = () => {
      setReadyToPay(false);
      toast.success("Donation successful.");
      onSubmit(donation);
      onClose();
    };

    const handleSubmit = () => {
      const giftAmount = parseInt(amount, 10);

      if (!isNaN(giftAmount) && giftAmount > 0) {
        dispatch(setDonation(giftAmount));
        setReadyToPay(true);
      } else {
        toast.error("Please enter a valid gift amount.");
      }
    };

    useEffect(() => {
      const urlParts = window.location.pathname.split("/");
      const streamId = urlParts[urlParts.length - 1];
      setNewStreamId(streamId);
    }, []);

    const onPayPaypal = (token: any) => {
      dispatch(
        capturePayment({
          payload: {
            orderId: token,
          },
          callback: handlePaymentSuccess,
        })
      );
    };

    useEffect(() => {
      const token = window.location?.search
        ?.split("&token=")?.[1]
        ?.split("&")?.[0];
      if (!token) return;
      onPayPaypal(token);
    }, []);

    return (
      <div>
        <div className="bg-componentBackground rounded-lg p-6 w-80 text-center">
          <div className="flex justify-center items-center gap-2">
            <Gift />
            <h3 className="text-lg text-white font-semibold ">Gift</h3>
          </div>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            onKeyDown={handleKeyDown}
            className="flex-1 bg-borderColor rounded-xl my-4 p-2 max-md:text-sm text-left text-white focus:outline-none focus:ring-0 border-inputGray border-b-2 focus:border-primaryYellow peer"
            placeholder="Enter your gift amount"
          />
          <div className="grid grid-cols-3 gap-2 mb-4">
            {[5, 10, 15, 20, 50, 100].map((num) => (
              <button
                key={num}
                onClick={() => handleButtonClick(num.toString())}
                className="py-2 text-lg font-semibold bg-gray-200 hover:bg-gray-300 rounded"
              >
                ${num}
              </button>
            ))}
          </div>
          <div className="flex justify-between">
            <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-primary hover:bg-primaryYellow text-black rounded"
            >
              OK
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded"
            >
              Cancel
            </button>
          </div>
        </div>
        {readyToPay && donation && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <PaymentMethod
              amount={donation}
              payId={streamId ? streamId : newStreamId}
              onSuccess={handlePaymentSuccess}
              onClose={() => setReadyToPay(false)}
              back
              donation
              from="LIVE"
            />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    const token = window.location?.search
      ?.split("&token=")?.[1]
      ?.split("&")?.[0];
    if (!token) return;
    setIsGiftModalOpen(true);
  }, []);

  const ISODateToTime = (ISODate: string) => {
    return new Date(ISODate).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const handleGiftClick = () => {
    setShowEmojiPicker(false);
    setIsGiftModalOpen(!isGiftModalOpen);
  };
  const handleCloseClick = () => {
    setIsGiftModalOpen(false);
  };
  const handleGiftSubmit = (amount: number) => {
    publish(`⭐ 🎁 Gifted $${amount} 🎁 ⭐ ` + `+${currentUser}`, {
      persist: true,
    });
  };
  const handleSendMessage = () => {

    if (message.trim() !== "") {
      publish(message + `+${currentUser}`, { persist: true });
      setMessage("");
    }
  };
  const handleOnEmojiSelected = (emoji: any) => {
    setMessage(message + emoji.emoji);
    setShowEmojiPicker(false);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <div
        className={`max-md:max-h-[20vh] md:max-h-[40vh] desktop:min-h-[calc(80vh-160px)] px-5 ${miscsStyles.beautifulScrollbarHidden}`}
      >
        {messages?.map((msg, index) => {
          const [chatMessage, userId] = msg.message.split("+");

          const time = ISODateToTime(msg.timestamp);
          return (
            <div className="flex flex-col pb-2.5" key={index}>
              <div
                className={`flex flex-col rounded-lg p-2 max-w-[70%] break-words ${userId === currentUser
                  ? "self-end bg-gray-600 text-black"
                  : "self-start bg-inputGray text-black"
                  }`}
              >
                <span
                  className={`text-xs mb-1 text-primary ${userId === currentUser ? "text-right" : "text-left"
                    }`}
                >
                  {msg.senderName}
                </span>
                <span className="text-sm text-white">{chatMessage}</span>
              </div>
              <span
                className={`text-xs text-gray-400 mt-0.5 ${userId === currentUser ? "self-end" : "self-start"
                  }`}
              >
                {time}
              </span>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>
      <div className="flex  rounded-lg bg-borderColor">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          className="flex-1 bg-borderColor rounded-l-xl md:w-[30vw] max-md:w-full p-2 max-md:text-sm text-left text-white focus:outline-none focus:ring-0 border-inputGray border-b-2 focus:border-primaryYellow peer"
          placeholder="Type your message here..."
        />
        <button
          className={`flex items-center justify-center hover:border-b-2 h-10 w-10 focus:ring-0 border-primaryYellow peer ${showEmojiPicker ? "border-b-2" : ""
            }`}
          onClick={() => {
            setShowEmojiPicker(!showEmojiPicker);
            setIsGiftModalOpen(false);
          }}
        >
          <Emoji />
        </button>
        {showEmojiPicker && (
          <div className="absolute bottom-36 right-26">
            <EmojiPicker
              theme={Theme.DARK}
              onEmojiClick={handleOnEmojiSelected}
            />
          </div>
        )}
        {!isHost && (
          <button
            onClick={handleGiftClick}
            className={`flex items-center justify-center hover:border-b-2 h-10 w-10 focus:ring-0 border-primaryYellow peer ${isGiftModalOpen ? "border-b-2" : ""
              }`}
          >
            <Gift />
          </button>
        )}
        {isGiftModalOpen && (
          <div className="absolute bottom-[150px] right-26">
            <GiftModal onClose={handleCloseClick} onSubmit={handleGiftSubmit} />
          </div>
        )}
        <button
          onClick={handleSendMessage}
          className="bg-primary hover:bg-primaryYellow text-black px-4 py-2 rounded-r-lg"
        >
          Send
        </button>
      </div>
    </>
  );
};

export default function PreviewLiveStreamScreen({ }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const { loader } = useAppSelector((state) => state.streaming);
  const { streamTitle, streamDescription, streamTags, defaultStream } =
    useAppSelector((state: RootState) => state.streaming);
  const isHost = defaultStream?.isHost;
  const RoomId = defaultStream ? defaultStream.stream.inApp.streamRoomId : "";
  const token = defaultStream ? defaultStream.stream.inApp.streamToken : "";
  const startedTime = defaultStream?.createdAt;
  const userAuth = useAppSelector((state: RootState) => state.auth);

  const isMobile = window.innerWidth < 768;
  const [visible, setVisible] = useState(false);
  const [record, setRecord] = useState(false);
  const [hlsState, setHlsState] = useState("");
  const [joined, setJoined] = useState<string | null>(null);
  const [visibleOptionsMenu, setVisibleOptionsMenu] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(false);

  const calculateDaysPassed = () => {
    const streamDate = new Date(startedTime ? startedTime : new Date());
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - streamDate.getTime();
    const secondsPassed = Math.floor(timeDifference / 1000);
    if (secondsPassed < 60) {
      return "just now";
    }
    const minutesPassed = Math.floor(secondsPassed / 60);
    if (minutesPassed < 60) {
      return `${minutesPassed} ${minutesPassed === 1 ? "minute" : "minutes"
        } ago`;
    }
    const hoursPassed = Math.floor(minutesPassed / 60);
    if (hoursPassed < 24) {
      return `${hoursPassed} ${hoursPassed === 1 ? "hour" : "hours"} ago`;
    }
    const daysPassed = Math.floor(hoursPassed / 24);
    if (daysPassed < 7) {
      return `${daysPassed} ${daysPassed === 1 ? "day" : "days"} ago`;
    }
    const weeksPassed = Math.floor(daysPassed / 7);
    if (weeksPassed < 4) {
      return `${weeksPassed} ${weeksPassed === 1 ? "week" : "weeks"} ago`;
    }
    const monthsPassed = Math.floor(daysPassed / 30);
    return `${monthsPassed} ${monthsPassed === 1 ? "month" : "months"} ago`;
  };

  const mMeeting = useMeeting({
    onMeetingJoined: () => {
      setJoined("JOINED");
    },
    onMeetingLeft: () => {
      setJoined('LEFT');
    },
  });

  const mMeetingRef = useRef(mMeeting);

  useEffect(() => {
    mMeetingRef.current = mMeeting;
  }, [mMeeting]);

  const handleClose = () => {
    navigate(`/stream/hub`);
    dispatch(resetStream());
  };

  const auth_token = useAppSelector((state: RootState) => state.auth).userToken;
  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    const socketUrl = `${process.env.REACT_APP_BASE_URL}/sockets`;
    socketRef.current = io(`${socketUrl}/livestream`, {
      query: { auth_token: auth_token },
    });

    const socket = socketRef.current;

    socket.on('connect', () => {
      console.log('Socket connected:', socket.connected);
    });
    socket.on('disconnect', () => {
      console.log('Socket disconnected:', socket.connected);
    });

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  function Controls() {
    const {
      hlsState,
      startHls,
      stopHls,
      localScreenShareOn,
      localMicOn,
      leave,
      localWebcamOn,
    } = useMeeting();

    const mMeeting = useMeeting();

    const _handleHLS = () => {
      if (!hlsState || hlsState === "HLS_STOPPED") {
        // @ts-ignore
        startHls({
          layout: {
            type: "SPOTLIGHT",
            priority: "SPEAKER",
            gridSize: 4,
          },
          theme: "DARK",
          orientation: "landscape",
          quality: "med",
          mode: "video-and-audio",
        });
        setHlsState("HLS_STARTED");
      } else if (hlsState === "HLS_STARTED" || hlsState === "HLS_PLAYABLE") {
        if (record)
          return dispatch(
            stopRecording(() => {
              setRecord(!record);
              stopHls();
              setHlsState("HLS_STOPPED");
            })
          );
        stopHls();
        setHlsState("HLS_STOPPED");
      }
    };


    const handleEndStream = () => {
      stopHls();
      leave();
      if (hlsState === "HLS_STOPPED") {
        socketRef.current?.emit('endStream', 'Stream-ended');
        dispatch(
          endStream({
            payload: {},
            callback: () => {
              handleClose();
            },
          })
        );
      } else if (hlsState === "HLS_PLAYABLE") {
        alert("Please stop your streaming to end live!");
      }
    };

    return (
      <div className="gap-5 flex justify-center">
        {hlsState === "HLS_STARTED" ||
          hlsState === "HLS_STOPPING" ||
          hlsState === "HLS_STARTING" ||
          hlsState === "HLS_PLAYABLE" ? (
          <>
            <button
              onClick={() => {
                _handleHLS();
              }}
              className="bg-fineRed hover:bg-brightRed rounded-lg p-1"
            >
              {hlsState === "HLS_STARTED"
                ? "Live Starting"
                : hlsState === "HLS_STOPPING"
                  ? "Live Stopping"
                  : hlsState === "HLS_PLAYABLE"
                    ? "Stop Live"
                    : "Loading..."}
            </button>
            <button
              onClick={() => {
                mMeeting.toggleScreenShare();
              }}
              className={`border-2  hover:border-primary rounded-lg p-1 ${localScreenShareOn ? "border-primary" : "border-darkGray"
                }`}
            >
              {localScreenShareOn ? <ShareOn /> : <ShareOff />}
            </button>
            <button
              onClick={() => mMeeting.toggleWebcam()}
              className={`border-2  hover:border-primary rounded-lg p-1 ${localWebcamOn ? "border-primary" : "border-darkGray"
                }`}
            >
              {localWebcamOn ? <VideoOn /> : <VideoOff />}
            </button>
            <button
              onClick={() => mMeeting.toggleMic()}
              className={`border-2  hover:border-primary rounded-lg p-1 ${localMicOn ? "border-primary" : "border-darkGray"
                }`}
            >
              {localMicOn ? <MicOn /> : <MicOff />}
            </button>


          </>
        ) : (
          <>
            <button
              onClick={() => {
                _handleHLS();
              }}
              className="bg-brightRed hover:bg-fineRed rounded-lg px-4 p-1"
            >
              Go Live
            </button>
            <button
              onClick={handleEndStream}
              className={`border-2  hover:border-brightRed rounded-lg p-1 `}
            >
              <EndStream />
            </button>
          </>
        )}
      </div>
    );
  }
  function ParticipantView(props: any) {
    const micRef = useRef(null);
    const { webcamStream, micStream, webcamOn, micOn, isLocal } =
      useParticipant(props.participantId);
    const videoStream = useMemo(() => {
      if (webcamOn && webcamStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(webcamStream.track);
        return mediaStream;
      }
    }, [webcamStream, webcamOn]);

    useEffect(() => {
      if (micRef.current) {
        if (micOn && micStream) {
          const mediaStream = new MediaStream();
          mediaStream.addTrack(micStream.track);
          // @ts-ignore
          micRef.current.srcObject = mediaStream;
          micRef.current
            // @ts-ignore
            .play()
            .catch((error: any) =>
              console.error("videoElem.current.play() failed", error)
            );
        } else {
          // @ts-ignore
          micRef.current.srcObject = null;
        }
      }
    }, [micStream, micOn]);

    return (
      <div className=" flex justify-end px-3">
        <div className=" absolute ">
          <audio ref={micRef} autoPlay playsInline muted={isLocal} />
          {webcamOn && (
            <ReactPlayer
              playsinline // very very imp prop
              pip={true}
              light={false}
              controls={true}
              muted={true}
              volume={0}
              playing={true}
              url={videoStream}
              height={"150px"}
              width={"180px"}
              onError={(err: any) => {
                console.log(err, "participant video error");
              }}
            />
          )}
        </div>
      </div>
    );
  }

  function SpeakerView() {
    const { participants } = useMeeting();
    const mMeeting = useMeeting({
      onMeetingJoined: () => {
        if (mMeetingRef.current.localParticipant.mode === "CONFERENCE") {
          // @ts-ignore
          mMeetingRef.current.localParticipant.pin();
        }
      },
    });

    const mMeetingRef = useRef(mMeeting);

    useEffect(() => {
      mMeetingRef.current = mMeeting;
    }, [mMeeting]);

    const speakers = useMemo(() => {
      // @ts-ignore
      const speakerParticipants = [...participants.values()].filter(
        (participant) => {
          return participant.mode === Constants.modes.CONFERENCE;
        }
      );
      return speakerParticipants;
    }, [participants]);

    return (
      <div className="rounded-b-[46px] py-1 text-center text-white ">
        {joined && joined === "JOINED" ? (
          <>
            {speakers.map((participant) => (
              <ParticipantView
                participantId={participant.id}
                key={participant.id}
              />
            ))}
            {isHost && <Controls />}
          </>
        ) : joined === "LEFT" ? (
          <div className="mb-2">
            <p>Session ended</p>
          </div>
        ) : (
          <div className="mb-2">
            <p>Joining...</p>
          </div>
        )}
      </div>
    );
  }

  function ViewerView() {
    const playerRef = useRef(null);

    const { hlsUrls, hlsState, participants } = useMeeting();

    useEffect(() => {
      const attendees = Array.from(participants?.values() || []).filter(
        (participant) => participant.mode === "VIEWER"
      );
      const uniqueDisplayNames = new Set(
        attendees.map((participant) => participant.displayName)
      );
      dispatch(setParticipantsCount(uniqueDisplayNames.size));
    }, [participants]);

    useEffect(() => {
      if (hlsUrls.downstreamUrl && hlsState === "HLS_PLAYABLE") {
        if (Hls.isSupported()) {
          const hls = new Hls({
            capLevelToPlayerSize: true,
            maxLoadingDelay: 4,
            minAutoBitrate: 0,
            autoStartLoad: true,
            defaultAudioCodec: "mp4a.40.2",
          });
          let player = document.querySelector("#hlsPlayer");
          hls.loadSource(hlsUrls.downstreamUrl);
          // @ts-ignore
          hls.attachMedia(player);
        } else {
          // @ts-ignore
          if (typeof playerRef.current?.play === "function") {
            // @ts-ignore
            playerRef.current.src = hlsUrls.downstreamUrl;
            // @ts-ignore
            playerRef.current.play();
          }
        }
      }
    }, [hlsUrls, hlsState, playerRef.current]);

    return (
      <div
        className={
          "flex justify-center items-center p-5 h-[40vh] min-h-[250px]"
        }
      >
        {hlsState !== "HLS_PLAYABLE" ? (
          <div className="mt-1 text-center justify-center align-middle text-placeholder">
            <span>
              {isHost
                ? "Please Click Go Live Button to start Stream"
                : "Waiting for host to start"}
            </span>
          </div>
        ) : (
          hlsState === "HLS_PLAYABLE" && (
            <div>
              <video
                ref={playerRef}
                id="hlsPlayer"
                autoPlay={true}
                controls
                playsInline
                muted={true}
                onError={(err) => {
                  console.log(err, "hls video error");
                }}
                className={`h-[40vh] w-full `}
              ></video>
            </div>
          )
        )}
      </div>
    );
  }

  return (
    <div className="mx-auto w-full h-screen  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar
        onOptions={() => setVisibleOptionsMenu(true)}
        onNotifications={() => setVisibleNotifications(true)}
      />
      <div className=" w-full md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20 ">
        <div className="md:mt-[20px] flex flex-row justify-between items-center w-[80vw] max-md:w-[95vw]">
          <span className="text-white text-3xl text-center max-md:text-2xl">
            Live Stream Preview
          </span>
          <button
            className=" w-[25px] h-[25px] right-10 max-md:w-[15px]"
            onClick={handleClose}
          >
            <img src={CloseIcon} alt="Close" />
          </button>
        </div>
        <MeetingProvider
          config={{
            meetingId: RoomId,
            micEnabled: false,
            webcamEnabled: false,
            name: user.user?.username ? user.user?.username : "Guest",
            mode: isHost ? "CONFERENCE" : "VIEWER",
            debugMode: true,
          }}
          joinWithoutUserInteraction
          token={token}
        >
          <div
            className={`flex desktop:flex-row flex-col md:p-4 gap-10 max-md:gap-8 items-center md:gap-10 md:bg-componentBackground bg-opacity-90 rounded-2xl w-[80vw] h-[80vh] max-md:w-[95vw] ${miscsStyles.beautifulScrollbarHidden}`}
          >
            <div className="flex flex-col gap-3 w-full justify-between h-full">
              <div className="flex flex-col gap-8 items-center ">
                <div className="bg-background  border-[6px] min-h-[300px] max-w-[75vh] w-full border-borderColor rounded-xl ">
                  <ViewerView />
                  <SpeakerView />
                </div>
                <div className="bg-backgroundGray rounded-xl p-4 flex flex-col gap-8 w-full justify-between">
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row gap-3 items-center">
                        <div className="flex flex-col items-center">
                          <ImageHolder
                            uri={defaultStream?.profilePicture}
                            className="w-16 rounded-full border-4 border-brightRed"
                          />
                          <Live />
                        </div>
                        <div className="flex flex-col">
                          <span className="text-white font-bold">
                            {streamTitle}
                          </span>
                          <span className="text-white text-sm ">
                            {defaultStream?.username} |{" "}
                            {defaultStream?.participants} watching | Started{" "}
                            {calculateDaysPassed()}{" "}
                            {isMobile && (
                              <button
                                className="text-primary text-left"
                                onClick={() => setVisible(!visible)}
                              >
                                | {visible ? "Hide" : "See more"}
                              </button>
                            )}
                          </span>
                        </div>
                      </div>
                      <button className="text-white rounded-lg px-3 py-1 ">
                        <Dots className="h-5" />
                      </button>
                    </div>

                    {(visible || !isMobile) && (
                      <>
                        {streamTags && (
                          <div className="flex flex-wrap md:gap-2 mt-2">
                            {streamTags.map((tag, index) => (
                              <span
                                className="text-blueBg max-md:text-justify max-md:text-sm"
                                key={index}
                              >
                                #{tag}
                              </span>
                            ))}
                          </div>
                        )}
                        <span className="text-white max-md:text-justify max-md:text-sm">
                          {streamDescription}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-backgroundGray flex flex-col rounded-xl p-4 gap-3 w-full desktop:w-1/3 h-full">
              <span className=" text-white text-2xl font-bold">Live Chat</span>

              <div className="flex-1 overflow-y-auto">
                {userAuth.userToken ?
                  (<MessageList currentUser={user.user?._id} />)
                  : (<div className="flex w-full h-full justify-center items-center">
                    <button className="mt-4 px-4 py-2 bg-primary text-black rounded hover:bg-primaryActive focus:outline-none" onClick={() => dispatch(setLoginModalVisible(true))}>
                      Log in to chat
                    </button>
                  </div>)}
              </div>
            </div>
          </div>
        </MeetingProvider>
      </div>
      <LoadingAnim loading={loader} />
      <Menu
        visible={visibleOptionsMenu}
        onClose={() => setVisibleOptionsMenu(false)}
      />
      <NotificationModal
        visible={visibleNotifications}
        onClose={() => setVisibleNotifications(false)}
      />
    </div>
  );
}
