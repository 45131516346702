import { createSlice } from "@reduxjs/toolkit";
import { signOut } from "store/auth/actions";

interface FCMSlice {
  data: any;
}

const initialState: FCMSlice = {
  data: {},
};

export const fcmSlice = createSlice({
  name: "leaders",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      console.log("PAYLOAD =>", action.payload);
      state.data = { ...action.payload?.data };
    },
    cleanFcmData: (state) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
  },
});

export default fcmSlice.reducer;
export const { setMessage, cleanFcmData } = fcmSlice.actions;
