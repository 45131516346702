import PaypalLogo from "assets/platForms/paypal.svg";
import Play from "assets/settings/playstation.svg";
import XboxLogo from "assets/settings/xbox-logo.svg";
import EpicLogo from "assets/settings/epic-games.svg";
import { ReactComponent as LogoActivition } from "assets/settings/activision.svg";
import Activition from "assets/settings/LogoActivition.svg";
import LogoEA from "assets/settings/ea-logo.svg";
import EaLogo from "assets/settings/ea-circled.svg";
import Street_Fighter from "assets/settings/street-fighter.svg";
import Venmo from "assets/settings/venmo-circled.svg";
import Mlb from "assets/settings/mlb-icon.svg";
import Battle from "assets/settings/Battle.svg";
import Nintendo from "assets/settings/Nintendo.svg";
import Origin from "assets/settings/Origin.svg";
import Steam from "assets/settings/Steam.svg";

export const PAYPAL_USER = [
  {
    console: "PAYPAL_USER",
    logo: PaypalLogo,
    header: "Sign In To PayPal",
    message:
      "We’ll need you to sign in with a verified PayPal before withdrawing",
  },
];

export const ACTIVITION: any[] = [
  {
    platForm: "Activition",
    logo: Activition,
    btnLogo: LogoActivition,
    header: "Connect Activision",
    message:
      "We’ll need you to connect your Activition account to match you with other players",
  },
];

export const EA = [
  {
    platForm: "EA",
    logo: EaLogo,
    btnLogo: LogoEA,
    header: "Connect EA",
    message:
      "We’ll need you to connect your EA account to match you with other players",
  },
];

export const XBOX = [
  {
    console: "Xbox",
    logo: XboxLogo,
    header: "Enter Your Xbox Gamer Tag",
    message: "You will not be able to change this",
  },
];

export const PLAYSTATION = [
  {
    console: "PlayStation",
    logo: Play,
    header: "Enter Your PlayStation ID",
    message: "You will not be able to change this",
  },
];

export const EPIC = [
  {
    console: "Epic",
    logo: EpicLogo,
    header: "Enter Your Epic ID",
    message: "You will not be able to change this",
  },
];

export const STREETFIGHTER = [
  {
    console: "StreetFighter",
    logo: Street_Fighter,
    header: "Enter Your StreetFighter ID",
    message: "You will not be able to change this",
  },
];

export const VENMO = [
  {
    console: "Venmo",
    logo: Venmo,
    header: "Enter Your Venmo ID",
    message: "You will not be able to change this",
  },
];

export const MLB = [
  {
    console: "Mlb",
    logo: Mlb,
    header: "Enter Your Mlb ID",
    message: "You will not be able to change this",
  },
];

export const BATTLE_NET = [
  {
    console: "Battle",
    logo: Battle,
    header: "Enter Your Battle.net ID",
    message: "You will not be able to change this",
  },
];

export const NINTENDO = [
  {
    console: "Nintendo",
    logo: Nintendo,
    header: "Enter Your Nintendo ID",
    message: "You will not be able to change this",
  },
];

export const STEAM = [
  {
    console: "Steam",
    logo: Steam,
    header: "Enter Your Steam ID",
    message: "You will not be able to change this",
  },
];

export const ORIGIN = [
  {
    console: "Origin",
    logo: Origin,
    header: "Enter Your Origin ID",
    message: "You will not be able to change this",
  },
];
