import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { setMatchPlatform } from "store/matchMaking/matchMakingSlice";
import { setTournamentPlatform } from "store/tournament/tournamentSlice";
import {
  ACTIVITION,
  BATTLE_NET,
  EA,
  EPIC,
  MLB,
  NINTENDO,
  ORIGIN,
  PLAYSTATION,
  STEAM,
  STREETFIGHTER,
  XBOX,
} from "constant/platForms";
import { updateActivitionID, updateBattleId, updateEAID, updateEpicID, updateMLBID, updateNintendoId, updateOriginId, updatePsID, updateSteamId, updateStreetFighterID, updateXboxID } from "store/user/action";
import { toast } from "react-toastify";
import BackIcon from "assets/arrow-right.svg";
import ButtonDc from "components/Button";
import CloseIcon from "assets/close-button.svg";
import PlatFormsModal from "components/PlatForms";
import { getLinkedAccounts } from "store/linkedAccounts/action";

export default function SelectPlatformScreen({ }) {
  const [selected, setSelected] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any>([]);

  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const isNextDisabled = !selected;
  const matchMaking = useAppSelector((state: RootState) => state.matchMaking);
  const { selectedGame } = useAppSelector((state: RootState) => state.tournament);
  const duelpath = matchMaking.selectedDuelPath;
  const providers = duelpath === "CREATE_TOURNAMENT" ? selectedGame?.providers : matchMaking.selectedGame?.providers;

  const linkedAccounts = useAppSelector(
    (state: RootState) => state.linkedAccounts
  );

  const handleClose = () => {
    navigate("/wagering");
  };
  const onBack = () => {
    navigate("/duels/select-game");
  };

  const handleConnected = () => {
    if (duelpath === "CREATE_TOURNAMENT") {
      dispatch(setTournamentPlatform(selected.type));
    } else {
      dispatch(setMatchPlatform(selected.type));
    }
    navigate("/duels/select-game-type");
  }
  const handleNext = () => {
    checkPlatformConnection()

  };

  const checkPlatformConnection = () => {
    switch (
    selected.type
    ) {
      case "EPIC":
        if (linkedAccounts.accounts.epic) return handleConnected();
        setVisible(true);
        setData(EPIC);
        dispatch(getLinkedAccounts());
        break;
      case "ACTIVISION":
        if (linkedAccounts.accounts.activisionId) return handleConnected();
        setVisible(true);
        setData(ACTIVITION);
        dispatch(getLinkedAccounts());
        break;
      case "ORIGIN":
        if (linkedAccounts.accounts.originId) return handleConnected();
        setVisible(true);
        setData(ORIGIN);
        dispatch(getLinkedAccounts());
        break;
      case "STEAM":
        if (linkedAccounts.accounts.steamId) return handleConnected();
        setVisible(true);
        setData(STEAM);
        dispatch(getLinkedAccounts());
        break;
      case "PSN":
        if (linkedAccounts.accounts.psn) return handleConnected();
        setVisible(true);
        setData(PLAYSTATION);
        dispatch(getLinkedAccounts());
        break;
      case "XBL":
        if (linkedAccounts.accounts.xbox) return handleConnected();
        setVisible(true);
        setData(XBOX);
        dispatch(getLinkedAccounts());
        break;
      case "EA":
        if (linkedAccounts.accounts.ea) return handleConnected();
        setVisible(true);
        setData(EA);
        dispatch(getLinkedAccounts());
        break;
      case "XBOX":
        if (linkedAccounts.accounts.xbox) return handleConnected();
        setVisible(true);
        setData(XBOX);
        dispatch(getLinkedAccounts());
        break;
      case "MLB":
        if (linkedAccounts.accounts.mlbId) return handleConnected();
        setVisible(true);
        setData(MLB);
        dispatch(getLinkedAccounts());
        break;
      case "BATTLE_NET":
        if (linkedAccounts.accounts.battleId) return handleConnected();
        setVisible(true);
        setData(BATTLE_NET);
        dispatch(getLinkedAccounts());
        break;
      case "NINTENDO":
        if (linkedAccounts.accounts.nintendoId) return handleConnected();
        setVisible(true);
        setData(NINTENDO);
        dispatch(getLinkedAccounts());
        break;

    }
  };

  const onPressPlatformContinue = (id: any) => {
    if (!id) return toast.error("Please enter valid details");
    switch (data) {
      case ACTIVITION:
        return dispatch(
          updateActivitionID({
            payload: { activisionId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case EA:
        return dispatch(
          updateEAID({
            payload: { eaId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case XBOX:
        return dispatch(
          updateXboxID({
            payload: { gammerTag: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case PLAYSTATION:
        return dispatch(
          updatePsID({
            payload: { psnGamerTag: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case EPIC:
        return dispatch(
          updateEpicID({
            payload: { epicId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case STREETFIGHTER:
        return dispatch(
          updateStreetFighterID({
            payload: { streetFighterId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case MLB:
        return dispatch(
          updateMLBID({
            payload: { mlbId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case BATTLE_NET:
        return dispatch(
          updateBattleId({
            payload: { battleNetId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case NINTENDO:
        return dispatch(
          updateNintendoId({
            payload: { nintendoId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case STEAM:
        return dispatch(
          updateSteamId({
            payload: { steamId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case ORIGIN:
        return dispatch(
          updateOriginId({
            payload: { originId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
    }
  }

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex gap-[7em] flex-col items-center  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
          overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]   max-md:h-[95vh]"
        >

          <div className="w-full mb-5 flex justify-between">
            {duelpath === "CREATE_TOURNAMENT" ? <span className="text-lg text-subText items-center font-normal uppercase">
              {duelpath} {'>'} {selectedGame?.name}
            </span>
              :
              <span className="text-lg text-subText items-center font-normal uppercase">
                {duelpath} {'>'} {matchMaking?.matchSchedule} {'>'} {matchMaking?.selectedGame?.name}
              </span>
            }
          </div>
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <div className="flex flex-row items-center gap-5">
              <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                <img src={BackIcon} alt="Close" />
              </button>
              <span className="text-3xl text-white items-center font-normal">
                Select Platform
              </span>
            </div>
            <button className=" w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex w-full justify-center">
            <div className=" grid grid-cols-1 sm:grid-cols-2 desktop:grid-cols-3 gap-6 justify-center mt-10">
              {providers &&
                providers.map((item: any, index: any) => (
                  <button
                    key={index}
                    className="flex w-[277px] h-[130px] p-[1.5em] items-center justify-center gap-[14.33px] rounded-[17.2px] bg-borderColor transform transition-transform hover:scale-105 hover:shadow-lg focus:ring focus:ring-[#FFD482]"
                    onClick={() => setSelected(item)}
                  >
                    <div className="flex flex-col">
                      <span className="text-center text-white text-[22px]">
                        {item.type}
                      </span>
                      <span className="text-center text-placeholder text-[18px]">
                        {item.subtext}
                      </span>
                    </div>
                  </button>
                ))}
            </div>
          </div>

          <ButtonDc
            type="primary"
            className="absolute bottom-10 max-md:w-full "
            textClassName="max-md:text-sm"
            text={'Next'}
            action={handleNext}
            disabled={isNextDisabled}
          />
        </div>
      </div>
      <PlatFormsModal
        visible={visible}
        data={data}
        onClose={() => setVisible(false)}
        onPress={(e) => onPressPlatformContinue(e)}
      />
    </div>
  );
}
