import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { toast } from "react-toastify";
import { games } from "../../../types/games";
import { setSelectedGame } from "store/gameHub/gameHubSlice";

import miscsStyles from "styles/miscs/miscs.module.scss";
import LoadingAnim from "components/Loader";
import SearchBar from "components/Search";
import CloseIcon from "assets/close-button.svg";

interface GameTileProps {
  title: string;
  image: string;
  onPress: () => void;
}

const GameTile: React.FC<GameTileProps> = ({ title, image, onPress }) => {
  return (
    <div
      className="flex flex-col items-center rounded-lg overflow-hidden p-2"
    >
      <div className="w-[100px] h-[100px] flex justify-center items-center rounded-lg cursor-pointer "
        onClick={onPress}>
        <img src={image} alt={title} className="w-[100px] h-[100px] shadow-xl rounded-md transform transition-transform hover:scale-105 hover:shadow-lg" />
      </div>

      <div className="p-4 text-center">
        <h3 className="text-white text-lg font-semibold">{title}</h3>
      </div>
    </div>
  );
};

export default function SelectMiniGameScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loader } = useAppSelector((state) => state.matchMaking);
  const [results, setResults] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const { path } = useParams();
  const isSuspended = useAppSelector(
    (state: RootState) => state.user
  ).user?.isSuspended;

  useEffect(() => {
    if (searchQuery) {
      const filteredGames = games.filter((game) =>
        game.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setResults(filteredGames);
    } else {
      setResults(games);
    }
  }, [searchQuery]);


  const handleClose = () => {
    navigate("/gaming");
  };

  const handleGameSelect = (gameData: any) => {
    dispatch(
      setSelectedGame({
        id: gameData?.id,
        title: gameData?.title,
        route: gameData?.route,
        gameType: gameData?.gameType,
        isLandscape: gameData?.isLandscape,
        image: gameData?.image,
        gameLogo: gameData?.gameLogo,
        backgroundImage: gameData?.backgroundImage,
        textColor: gameData?.textColor,
        textColor2: gameData?.textColor2,
      })
    );
    if (isSuspended) return toast.warning('Account Suspended');
    navigate("/gaming/cubes", { state: { gameData } });
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen  p-2">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:pt-5 items-center ">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(90vw-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] justify-between flex flex-col  max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal">
              Select a game
            </span>
            <button className=" w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="w-full justify-center flex flex-col items-center gap-5">
            <div className="text-white min-w-[350px] md:w-1/2 justify-center">
              <SearchBar
                searchIcon
                placeholder="Search games"
                type="gameHub"
                onPress={(v) => setSearchQuery(v)}
              />
            </div>
            <div
              className={`flex flex-col md:p-4 gap-[2em] max-md:gap-8 md:gap-[5em] md:bg-cardBackground md:bg-opacity-85 rounded-2xl bg-black bg-opacity-35 md:w-[calc(80vw-200px)] h-[calc(80vh-200px)] max-md:h-[calc(90vh-200px)] ${miscsStyles.beautifulScrollbarHidden}`}
            >
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 desktop:grid-cols-5 ">
                {results.length > 0 ? (
                  results.map((game: any) => (
                    <GameTile
                      key={game.id}
                      title={game.title}
                      image={game.image}
                      onPress={() => handleGameSelect(game)}
                    />
                  ))
                ) : (
                  <p className="text-white text-center col-span-full">
                    No games found.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
}
