import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Constants,
  MeetingProvider,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import { useAppDispatch } from "store/hooks";

import { ReactComponent as MicOn } from "assets/streaming/mic_on.svg";
import { ReactComponent as MicOff } from "assets/streaming/mic_off.svg";
import { ReactComponent as ShareOn } from "assets/streaming/share_on.svg";
import { ReactComponent as ShareOff } from "assets/streaming/share_off.svg";
import { ReactComponent as VideoOn } from "assets/streaming/video_on.svg";
import { ReactComponent as VideoOff } from "assets/streaming/video_off.svg";
import { ReactComponent as RecordOn } from "assets/streaming/record_on.svg";
import { ReactComponent as RecordOff } from "assets/streaming/record_off.svg";
import { ReactComponent as EndStream } from "assets/streaming/end.svg";

import Hls from "hls.js";
import ReactPlayer from "react-player";
import { AppDispatch } from "store/index";
import { startRecording, stopRecording } from "store/streaming/actions";
interface StreamerViewProps {
  streamRoomId: string;
  streamToken: string;
  isHost?: boolean;
  userName?: string;
  height?: number;
  width?: number;
}

const StreamerView: React.FC<StreamerViewProps> = ({
  streamRoomId,
  streamToken,
  isHost,
  userName,
  height,
  width,
}) => {
  const [record, setRecord] = useState(false);
  const [hlsState, setHlsState] = useState("");
  const dispatch: AppDispatch = useAppDispatch();
  const [joined, setJoined] = useState<string | null>(null);


  const mMeeting = useMeeting({
    onMeetingJoined: () => {
      setJoined("JOINED");
    },
    onMeetingLeft: () => {
      setJoined('LEFT');
    },
  });

  const mMeetingRef = useRef(mMeeting);

  useEffect(() => {
    mMeetingRef.current = mMeeting;
  }, [mMeeting]);

  function ParticipantView(props: any) {
    const micRef = useRef(null);
    const { webcamStream, micStream, webcamOn, micOn, isLocal } =
      useParticipant(props.participantId);
    const videoStream = useMemo(() => {
      if (webcamOn && webcamStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(webcamStream.track);
        return mediaStream;
      }
    }, [webcamStream, webcamOn]);

    useEffect(() => {
      if (micRef.current) {
        if (micOn && micStream) {
          const mediaStream = new MediaStream();
          mediaStream.addTrack(micStream.track);
          // @ts-ignore
          micRef.current.srcObject = mediaStream;
          micRef.current
            // @ts-ignore
            .play()
            .catch((error: any) =>
              console.error("videoElem.current.play() failed", error)
            );
        } else {
          // @ts-ignore
          micRef.current.srcObject = null;
        }
      }
    }, [micStream, micOn]);

    return (
      <div className=" flex justify-end px-3">
        <div className=" absolute ">
          <audio ref={micRef} autoPlay playsInline muted={isLocal} />
          {webcamOn && (
            <ReactPlayer
              playsinline // very very imp prop
              pip={true}
              light={false}
              controls={true}
              muted={true}
              playing={true}
              url={videoStream}
              height={"150px"}
              width={"180px"}
              onError={(err: any) => {
                console.log(err, "participant video error");
              }}
            />
          )}
        </div>
      </div>
    );
  }

  function Controls() {
    const {
      hlsState,
      startHls,
      stopHls,
      localScreenShareOn,
      localMicOn,
      leave,
      localWebcamOn,
    } = useMeeting();

    const mMeeting = useMeeting();

    const _handleHLS = () => {
      console.log("hlsState", hlsState);
      if (!hlsState || hlsState === "HLS_STOPPED") {
        // @ts-ignore
        startHls({
          layout: {
            type: "SPOTLIGHT",
            priority: "SPEAKER",
            gridSize: 4,
          },
          theme: "DARK",
          orientation: "landscape",
          quality: "med",
          mode: "video-and-audio",
        });
        setHlsState("HLS_STARTED");
      } else if (hlsState === "HLS_STARTED" || hlsState === "HLS_PLAYABLE") {
        if (record)
          return dispatch(
            stopRecording(() => {
              setRecord(!record);
              stopHls();
              setHlsState("HLS_STOPPED");
            })
          );
        stopHls();
        setHlsState("HLS_STOPPED");
      }
    };

    const handleRecording = () => {
      if (record) {
        dispatch(stopRecording(setRecord(!record)));
      } else {
        dispatch(startRecording(setRecord(!record)));
      }
    };
    const handleEndStream = () => {
      stopHls();
      leave();
    }

    return (
      <div className="gap-5 flex justify-center">
        {hlsState === "HLS_STARTED" ||
          hlsState === "HLS_STOPPING" ||
          hlsState === "HLS_STARTING" ||
          hlsState === "HLS_PLAYABLE" ? (
          <>
            <button
              onClick={() => {
                _handleHLS();
              }}
              className="bg-fineRed hover:bg-brightRed rounded-lg p-1"
            >
              {hlsState === "HLS_STARTED"
                ? "Live Starting"
                : hlsState === "HLS_STOPPING"
                  ? "Live Stopping"
                  : hlsState === "HLS_PLAYABLE"
                    ? "Stop Live"
                    : "Loading..."}
            </button>
            <button
              onClick={() => {
                mMeeting.toggleScreenShare();
              }}
              className={`border-2  hover:border-primary rounded-lg p-1 ${localScreenShareOn ? "border-primary" : "border-darkGray"
                }`}
            >
              {!localScreenShareOn ? <ShareOn /> : <ShareOff />}
            </button>
            <button
              onClick={() => mMeeting.toggleWebcam()}
              className={`border-2  hover:border-primary rounded-lg p-1 ${localWebcamOn ? "border-primary" : "border-darkGray"
                }`}
            >
              {!localWebcamOn ? <VideoOn /> : <VideoOff />}
            </button>
            <button
              onClick={() => mMeeting.toggleMic()}
              className={`border-2  hover:border-primary rounded-lg p-1 ${localMicOn ? "border-primary" : "border-darkGray"
                }`}
            >
              {!localMicOn ? <MicOn /> : <MicOff />}
            </button>

            <button
              onClick={handleRecording}
              className={`border-2  hover:border-primary rounded-lg p-1 ${record ? "border-primary" : "border-darkGray"
                }`}
            >
              {!record ? <RecordOn /> : <RecordOff />}
            </button>
          </>
        ) : (
          <button
            onClick={() => {
              _handleHLS();
            }}
            className="bg-brightRed hover:bg-fineRed rounded-lg px-4 p-1"
          >
            Go Live
          </button>
        )}
        <button
          onClick={handleEndStream}
          className={`border-2  hover:border-primary rounded-lg p-1 ${record ? "border-primary" : "border-darkGray"
            }`}
        >
          {<EndStream />}
        </button>
      </div>
    );
  }

  function SpeakerView() {
    const { participants } = useMeeting();
    const mMeeting = useMeeting({
      onMeetingJoined: () => {
        if (mMeetingRef.current.localParticipant.mode === "CONFERENCE") {
          // @ts-ignore
          mMeetingRef.current.localParticipant.pin();
        }
      },
    });

    const mMeetingRef = useRef(mMeeting);

    useEffect(() => {
      mMeetingRef.current = mMeeting;
    }, [mMeeting]);

    const speakers = useMemo(() => {
      // @ts-ignore
      const speakerParticipants = [...participants.values()].filter(
        (participant) => {
          return participant.mode === Constants.modes.CONFERENCE;
        }
      );
      return speakerParticipants;
    }, [participants]);

    return (
      <div className="rounded-b-[46px] py-1 text-center text-white ">
        {joined && joined === "JOINED" ? (
          <>
            {speakers.map((participant) => (
              <ParticipantView
                participantId={participant.id}
                key={participant.id}
              />
            ))}
            {isHost && <Controls />}
          </>
        ) : joined === "LEFT" ? (
          <div className="mb-2">
            <p>Session ended</p>
          </div>
        ) : (
          <div className="mb-2">
            <p>Joining...</p>
          </div>
        )}
      </div>
    );
  }

  function ViewerView() {
    // States to store downstream url and current HLS state
    const playerRef = useRef(null);
    //Getting the hlsUrls
    const { hlsUrls, hlsState } = useMeeting();
    //Playing the HLS stream when the downstreamUrl is present and it is playable
    useEffect(() => {
      if (hlsUrls.downstreamUrl && hlsState === "HLS_PLAYABLE") {
        if (Hls.isSupported()) {
          const hls = new Hls({
            capLevelToPlayerSize: true,
            maxLoadingDelay: 4,
            minAutoBitrate: 0,
            autoStartLoad: true,
            defaultAudioCodec: "mp4a.40.2",
          });
          let player = document.querySelector("#hlsPlayer");
          hls.loadSource(hlsUrls.downstreamUrl);
          // @ts-ignore
          hls.attachMedia(player);
        } else {
          // @ts-ignore
          if (typeof playerRef.current?.play === "function") {
            // @ts-ignore
            playerRef.current.src = hlsUrls.downstreamUrl;
            // @ts-ignore
            playerRef.current.play();
          }
        }
      }
    }, [hlsUrls, hlsState, playerRef.current]);

    return (
      <div
        className={`flex justify-center items-center p-5  ${height ? `h-[${height}vh] min-h-[250px]` : "h-[400px]"
          } `}
      >
        {/* Showing message if HLS is not started or is stopped by HOST */}
        {hlsState !== "HLS_PLAYABLE" ? (
          <div className="mt-1 text-center justify-center align-middle text-placeholder">
            <span>
              {isHost
                ? "Please Click Go Live Button to start Stream"
                : "Waiting for host to start"}
            </span>
          </div>
        ) : (
          hlsState === "HLS_PLAYABLE" && (
            <div>
              <video
                ref={playerRef}
                id="hlsPlayer"
                autoPlay={true}
                controls
                // style={{ width: "50%", height: "50%" }}
                playsInline
                muted={true}
                onError={(err) => {
                  console.log(err, "hls video error");
                }}
                className={`h-[380px] w-full `}
              ></video>
            </div>
          )
        )}
      </div>
    );
  }

  return (
    <>
      <MeetingProvider
        config={{
          meetingId: streamRoomId,
          micEnabled: false,
          webcamEnabled: false,
          name: userName ? userName : "Guest",
          mode: isHost ? "CONFERENCE" : "VIEWER",
          debugMode: true,
        }}
        joinWithoutUserInteraction
        token={streamToken}
      >
        <ViewerView />
        <SpeakerView />
      </MeetingProvider>
    </>
  );
};

export default StreamerView;
