import { createSlice } from "@reduxjs/toolkit";
import { enableStaking, getStakeAmount, getStakedMatches } from "./action";
import { signOut } from "store/auth/actions";

interface StakingSlice {
  matchId: string;
  from: string;
  team: string;
  amount: number;
  loading: boolean;
  err: boolean;
  stakeType: boolean;
  events: any[];
  page: number;
  stakeAmount: { team1StakeAmount: number; team2StakeAmount: number };
  stakedEvents: any[];
  success: boolean;
}

const initialState: StakingSlice = {
  matchId: "",
  from: "",
  team: "",
  amount: 0,
  loading: false,
  err: false,
  stakeType: true,
  events: [],
  page: 1,
  stakeAmount: { team1StakeAmount: 0, team2StakeAmount: 0 },
  stakedEvents: [],
  success: false,
};

export const stakeSlice = createSlice({
  name: "stake",
  initialState,
  reducers: {
    setStakeMatch: (state, action) => {
      state.matchId = action?.payload;
    },
    setStakeTeam: (state, action) => {
      state.team = action?.payload;
    },
    setStakeAmount: (state, action) => {
      state.amount = action?.payload;
    },
    setStakeType: (state, action) => {
      state.stakeType = action?.payload;
    },
    setStakeFrom: (state, action) => {
      state.from = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStakeAmount.fulfilled, (state, action) => {
      state.stakeAmount = action?.payload;
    });
    builder.addCase(getStakedMatches.fulfilled, (state, action) => {
      state.stakedEvents = action.payload.stakedMatches;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
    builder.addCase(enableStaking.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(enableStaking.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(enableStaking.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default stakeSlice.reducer;
export const {
  setStakeAmount,
  setStakeTeam,
  setStakeMatch,
  setStakeType,
  setStakeFrom,
} = stakeSlice.actions;
