import Api from "../services/api";
import {
  createMainTournamentUrl,
  createTournamentMatchUrl,
  deleteTournamentMatchUrl,
  deleteTournamentUrl,
  endTournamentUrl,
  getEndedTournamentsUrl,
  getStagedPlayersUrl,
  getTournamentByIdUrl,
  getTournamentMatchesUrl,
  goToNextStageUrl,
  goToYourMatchUrl,
  inviteToTournamentUrl,
  joinTournamentUrl,
  leaveTournamentUrl,
  startTournamentUrl,
  updateTournamentDateUrl,
} from "./urls";

export const createMainTournamentApi = (body: object) => {
  return Api.request({
    method: "post",
    url: createMainTournamentUrl,
    body,
  });
};

export const inviteToTournamentApi = (body: object) => {
  return Api.request({
    method: "put",
    url: inviteToTournamentUrl,
    body,
  });
};

export const getTournamentByIdApi = (tournamentId: string) => {
  return Api.request({
    method: "get",
    url: getTournamentByIdUrl + `/${tournamentId}`,
  });
};

export const deleteTournamentApi = (tournamentId: any) => {
  return Api.request({
    method: "delete",
    url: deleteTournamentUrl + `/${tournamentId}`,
  });
};

export const getStagedPlayersApi = (tournamentId: string) => {
  return Api.request({
    method: "get",
    url: getStagedPlayersUrl + `/${tournamentId}`,
  });
};

export const createTournamentMatchApi = (body: any) => {
  return Api.request({
    method: "put",
    url: createTournamentMatchUrl + `/${body?.tournamentId}`,
    body,
  });
};

export const updateTournamentDateApi = (body: any) => {
  return Api.request({
    method: "post",
    url: updateTournamentDateUrl,
    body,
  });
};

export const startTournamentApi = (tournamentId: string) => {
  return Api.request({
    method: "put",
    url: startTournamentUrl + `/${tournamentId}`,
  });
};

export const goToYourMatchApi = (matchId: string) => {
  return Api.request({
    method: "put",
    url: goToYourMatchUrl + `/${matchId}`,
  });
};

export const goToNextStageApi = (tournamentId: string) => {
  return Api.request({
    method: "put",
    url: goToNextStageUrl + `/${tournamentId}`,
  });
};

export const endTournamentApi = (tournamentId: string) => {
  return Api.request({
    method: "put",
    url: endTournamentUrl + `/${tournamentId}`,
  });
};

export const getTournamentMatchesApi = (tournamentId: string) => {
  return Api.request({
    method: "get",
    url: getTournamentMatchesUrl + `/${tournamentId}`,
  });
};

export const joinTournamentApi = (data:any) => {
  return Api.request({
    method: "put",
    url: joinTournamentUrl + `/${data.id}`,
    body: data.data,
  });
};
export const getEndedTournamentsApi = (page: number) => {
  return Api.request({
    method: "put",
    url: getEndedTournamentsUrl + `${page}`,
  });
};
export const deleteTournamentMatchApi = (body: any) => {
  return Api.request({
    method: "delete",
    url: deleteTournamentMatchUrl,
    body,
  });
};
export const leaveTournamentApi = (tournamentId: string) => {
  return Api.request({
    method: "post",
    url: leaveTournamentUrl + `/${tournamentId}`,
  });
};
