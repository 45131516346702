import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { useAppDispatch } from "store/hooks";
import { gameTypesPlayerCount } from "types/constants";
import { gameTypes } from "types/constants";
import { subscribeToTopic } from "store/fcm/actions";
import { closeMatch, getMatchTimer, leaveLobby } from "store/matchMaking/actions";
import { enableStaking } from "store/stake/action";
import { toast } from "react-toastify";
import { MatchStatus } from "constant/types";
import { matchStatuses } from "types/constants";

import {
  endMatch,
  getCurrentMatch,
  getCurrentMatches,
  setReadyForMatch,
  startMatch,
} from "store/currentMatch/currentMatchActions";
import {
  playerTypes,
  resetCurrentMatch,
  updatePlayerList,
} from "store/currentMatch/currentMatchSlice";
import {
  resetBackgroundMatch,
  resetMatchmakingSlice,
} from "store/matchMaking/matchMakingSlice";
import { ReactComponent as Info } from "assets/info.svg";

import ButtonDc from "components/Button";
import defaultImage from "assets/default_profile_yellow.png";
import CountdownTimer from "components/CountdownTimer";
import Refresh from "assets/refresh_icon.svg";
import LoadingAnim from "components/Loader";
import StreamModal from "components/StreamModal";
import CustomSwitch from "components/Switch";
import TournamentInfoModal from "components/TournamentInfoModal";

const LobbyScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { matchId, stream } = useParams();

  const validMatchId = useAppSelector((state) => state.matchMaking).setMatch
    .matchId;
  const { selectedDuelPath, privacy } = useAppSelector((state) => state.matchMaking);
  const { loading } = useAppSelector((state) => state.currentMatch);
  const loader = useAppSelector((state) => state.stake).loading;
  const userId = useAppSelector((state: RootState) => state.user).user?._id;
  const { streamMethod } = useAppSelector((state: RootState) => state.streaming);
  // const userId = useAppSelector((state: RootState) => state.user._id);
  const fcmData = useAppSelector((state) => state.fcm);
  const currentMatch = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;
  const tournaments = useAppSelector((state: RootState) => state.tournament);

  const matchMaking: any = useAppSelector(
    (state: RootState) => state.matchMaking
  );

  const [isStreamModalVisible, setIsStreamModalVisible] = useState(false);
  const [streamSelected, setStreamSelected] = useState(false);
  const [countdown, setCountdown] = useState(1799);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [showMatchInfo, setShowMatchInfo] = useState(false);

  const inactivityTimeoutSeconds = 1799;
  const matchStatus = currentMatch.matchStatus;
  const isTournament =
    selectedDuelPath === "CREATE_TOURNAMENT" ||
    selectedDuelPath === "OPPONENT_JOIN_MATCH" ||
    selectedDuelPath === "OPPONENT_JOIN";
  const isOpponent = selectedDuelPath === "JOIN_MATCH";
  const matchScheduled = matchStatus === matchStatuses.SCHEDULED;
  const matchWaiting = matchStatus === matchStatuses.WAITING_TO_START;
  const isOneVsOne = currentMatch.gameType === gameTypes.ONE_VS_ONE;
  const players = currentMatch?.players;
  const isPrivate = currentMatch.isPrivate;

  const teamOne = currentMatch.team1
    .map((playerId: string) =>
      players.find((player: playerTypes) => player._id === playerId)
    )
    .filter((player) => player !== undefined);
  const teamTwo = currentMatch.team2
    .map((playerId: string) =>
      players.find((player: playerTypes) => player._id === playerId)
    )
    .filter((player) => player !== undefined);

  const teamOneReady = teamOne.filter((player) => player?.isReady);
  const teamTwoReady = teamTwo.filter((player) => player?.isReady);
  const isUserReady = players.find((player) => player._id === userId)?.isReady;
  const isUserHost = currentMatch.host?._id === userId;

  const isFreePlay = currentMatch.cubeWager === 0;
  const isAllJoined =
    (teamOne.length + teamTwo.length) /
    gameTypesPlayerCount[currentMatch.gameType] ===
    1;
  const allReady =
    (teamOneReady.length + teamTwoReady.length) /
    gameTypesPlayerCount[currentMatch.gameType] ===
    1;
  const startMatchButton = isUserHost || isTournament;
  const isStakingEnabled = currentMatch.stakingEnabled === true;

  const matchTime = new Date(
    matchScheduled ? currentMatch.scheduledTime : currentMatch.createdAt
  ).getTime();
  const matchCreationTime = matchTime ? matchTime : new Date().getTime();
  if (!matchId) {
    matchId = validMatchId;
  }
  const handleInviteButton = () => {
    navigate(`/duels/invite-others/${matchId}`);
  };

  const handleChallengeButton = () => {
    navigate(`/duels/challenge-others/${matchId}`);
  };

  const updateUserReadyState = () => {
    const updatedPlayerList = players.map((player) => {
      if (player._id === userId) {
        return { ...player, isReady: !isUserReady };
      }
      return player;
    });
    dispatch(updatePlayerList(updatedPlayerList));
  };

  const handleStartMatch = () => {
    const callback = () => {
      dispatch(
        getCurrentMatches({
          matchId,
          callback: () => {
            navigate(`/duels/engaged/${matchId}`);
          },
        })
      );
    };

    const handleMatchEnd = () => {
      //TODO navigate to vote screen
    };

    if (matchStatus === matchStatuses.ONGOING) {
      dispatch(endMatch({ matchId, callback: handleMatchEnd }));
      return;
    }

    dispatch(startMatch({ matchId, callback }));
  };
  const handlePlayerReady = () => {
    updateUserReadyState();
    dispatch(
      setReadyForMatch({
        matchId: matchId,
      })
    );
  };
  const handleStreamModalNo = () => {
    navigate(`/duels/lobby/${matchId}/${'no_stream'}`);
    setIsStreamModalVisible(false);
    handlePlayerReady();
    setStreamSelected(true);
  };

  const onTimerEnd = () => {
    if (isTournament) return;
    onRefresh();
  };
  const handleReadyButton = () => {
    if (!streamMethod || !stream) navigate(`/streaming/${matchId}/platform`);
    else handlePlayerReady();
    setStreamSelected(true);
    // handlePlayerReady();
  };

  const handleStartButtonPress = () => {
    handleStartMatch();
    setStreamSelected(false);
  };

  const handleCancelMatch = () => {
    dispatch(
      closeMatch({
        payload: { matchId: matchId },
        callback: () => {
          toast.success("Match cancelled successfully");
          dispatch(resetCurrentMatch());
          dispatch(resetBackgroundMatch());
          dispatch(resetMatchmakingSlice());
          if (isTournament)
            return navigate(`/tournament/live-bracket/${matchId}`);
          onRefresh();
        },
      })
    );
  };
  const handleLeaveMatch = () => {
    dispatch(
      leaveLobby({
        payload: { matchId: matchId },
        callback: () => {
          dispatch(resetCurrentMatch());
          dispatch(resetBackgroundMatch());
          dispatch(resetMatchmakingSlice());
          toast.success("you have successfully leave the match.");
          if (isTournament)
            return navigate(`/tournament/live-bracket/${matchId}`);
          navigate("/");
        },
      })
    );
  };

  const renderPlayer = (player: playerTypes, index: number) => {
    const truncatedUsername =
      player.username.length > 8 ? `${player.username.slice(0, 8)}...` : player.username;
    return (

      <div className="flex flex-col mx-2 justify-center items-center "
        key={index}>
        <div
          style={{
            backgroundImage: !!player.profilePicture
              ? `url(${player.profilePicture})`
              : `url(${defaultImage})`,
          }}
          className="min-w-[48px] min-h-[48px] rounded-full border-primary border-2 bg-no-repeat bg-contain"
        >
        </div>
        <span className="text-white text-[14px] ">{truncatedUsername}</span>
      </div>
    );
  }

  const renderTeamPlayer = (player: any, index: number) => {
    const truncatedUsername =
      player.username.length > 8 ? `${player.username.slice(0, 8)}...` : player.username;
    return (
      <div
        className="flex flex-col mx-2 justify-center items-center "
        key={index}
      >
        <div
          className="w-[48px] h-[48px] rounded-ful bg-no-repeat bg-center bg-contain rounded-full"
          style={{
            backgroundImage: !!player.profilePicture
              ? `url(${player.profilePicture})`
              : `url(${defaultImage})`,
          }}
        />
        <span className="text-white text-[14px] ">{truncatedUsername}</span>
      </div>
    );
  }

  useEffect(() => {
    if (!matchId) return;
    dispatch(getMatchTimer({
      payload: matchId, callback: (data: any) => {
        const now = new Date().getTime();
        const timeElapsed = Math.floor((new Date(data.lobbyTimeout).getTime() - now) / 1000);
        setCountdown(timeElapsed);
      }
    }));
  }, [matchId]);

  useEffect(() => {
    if (stream && !isUserReady) return handlePlayerReady();
  }, [stream]);

  useEffect(() => {
    dispatch(getCurrentMatch({ matchId }));
    dispatch(subscribeToTopic({ topic: matchId }));
    console.log("subscribed to topic ", matchId);
  }, [matchId]);

  useEffect(() => {
    if (currentMatch.matchStatus === matchStatuses.ONGOING) {
      navigate(`/duels/engaged/${matchId}`);
    } else if (currentMatch.matchStatus === matchStatuses.WAITING_YOU_TO_VOTE) {
      navigate(`/duels/vote/${matchId}/LOBBY`);
    }

  }, [currentMatch]);

  useEffect(() => {
    if (fcmData?.data?.type === "PLAYER_READY_STATE" || "START_MATCH")
      dispatch(getCurrentMatch({ matchId }));

    if (fcmData.data.type === "CANCEL_MATCH") {
      return onRefresh;
    }
  }, [fcmData]);

  useEffect(() => {
    const updateCountdown = () => {
      if (isFutureMatch) return;
      setCountdown((prevCountdown) => {
        const newCountdown = Math.max(0, prevCountdown - 1);
        if (newCountdown === 0) {
          clearInterval(timerId);
          if (!allReady) {
            if (isTournament) return prevCountdown;
            if (isUserHost) {
              dispatch(
                closeMatch({
                  payload: { matchId: matchId },
                  callback: () => {
                    toast.error("Match canceled due to inactivity");
                    dispatch(resetCurrentMatch());
                    dispatch(resetBackgroundMatch());
                    dispatch(resetMatchmakingSlice());
                    onRefresh();
                  },
                })
              );
            }
          }
        }
        return newCountdown;
      });
    };

    const timerId = setInterval(updateCountdown, 1000);

    if (!isTournament) updateCountdown();

    return () => clearInterval(timerId);
  }, [dispatch, allReady, matchId, isTournament]);

  useEffect(() => {
    if (!isOpponent) return;
    const now = new Date().getTime();
    const timeElapsed = Math.floor((now - matchCreationTime) / 1000);
    const timeLeft = Math.max(0, inactivityTimeoutSeconds - timeElapsed);
    setCountdown(timeLeft);
  }, [dispatch, allReady, matchId, isTournament]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  const scheduledTime = new Date(currentMatch.scheduledTime);
  const currentTime = new Date();
  const isFutureMatch = scheduledTime > currentTime;

  const onRefresh = () => {
    dispatch(getCurrentMatch({ matchId }));
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      return "";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handleSwitchChange = () => {
    dispatch(
      enableStaking(
        {
          payload: { matchId: matchId }, callback: () => {
            setIsSwitchOn(true);
          }
        }),
    );
  };

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex min-h-screen h-full">
      <div className="container flex flex-col gap-y-[20px] md:mb-16 p-[20px] gap-3 gap-x-4 items-center justify-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 flex flex-col justify-center
        w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] gap-3 max-md:h-[95vh]"
        >
          {isFreePlay && <span className="text-xl max-md:text-lg bg-2048-256 text-black text-center rounded-2xl items-center font-normal">
            Free Play Mode
          </span>}
          <div className="w-full rounded-t-[34px] flex items-center justify-center mb-10">
            <span className="text-3xl text-white items-center font-normal">
              Lobby
            </span>
            <button
              className=" w-[25px] h-[25px] absolute right-10"
              onClick={onRefresh}
            >
              <img src={Refresh} alt="Close" />
            </button>
          </div>
          {!isTournament && <div className="flex justify-center flex-row gap-5 max-md:gap-2">
            <span className="text-white text-xl max-md:text-[12px]">
              Match ID: {currentMatch?.matchId}
            </span>
            <button onClick={() => setShowMatchInfo(true)}>
              <Info />
            </button>
          </div>}
          <div className="justify-center items-center text-center">
            {isFutureMatch && !isTournament && (
              <div className="flex items-center justify-center my-3">
                <CountdownTimer
                  until={currentMatch.scheduledTime}
                  onEnd={onTimerEnd}
                />
              </div>
            )}
            {countdown < 1799 &&
              !allReady &&
              !isTournament &&
              !isFutureMatch && (
                <span className="text-l text-white items-center font-normal text-center">
                  Match will automatically cancel after {formatTime(countdown)},{" "}
                  <br />
                  if players are not available
                </span>
              )}
          </div>
          <div className="flex justify-center gap-2">
            {players.map(renderPlayer)}
          </div>
          <div className="flex justify-center gap-2 w-full min-h-[74px]">
            <div className="w-full max-w-[367px] h-full rounded-[16px] bg-borderColor flex justify-center items-center px-[30px] py-[8px] max-md:overflow-x-auto">
              {teamOneReady.length === 0 && (
                <span className="text-white text-[20px] max-md:text-[12px]">
                  Waiting for players
                </span>
              )}
              {teamOneReady.map(renderTeamPlayer)}
            </div>
            <div className="w-full max-w-[367px] h-full rounded-[16px] bg-borderColor flex justify-center items-center px-[30px] py-[8px] max-md:overflow-x-auto">
              {teamTwoReady.length === 0 && (
                <span className="text-white text-[20px] max-md:text-[12px]">
                  Waiting for players
                </span>
              )}
              {teamTwoReady.map(renderTeamPlayer)}
            </div>
          </div>
          <div className="mt-[40px] mb-[30px] flex flex-col gap-y-[20px]">
            <div className="flex justify-center gap-6">
              {isUserHost && !isOneVsOne && (
                <ButtonDc
                  text="Invite"
                  action={handleInviteButton}
                  style={{ margin: 0 }}
                />
              )}
              {(isUserHost || !isOneVsOne) && <ButtonDc
                text="Challenge"
                action={handleChallengeButton}
                style={{ margin: 0 }}
                disabled={isTournament}
              />}
            </div>

            <div className="flex justify-center">
              <ButtonDc
                text={isUserReady ? "Not ready" : "I am ready"}
                action={handleReadyButton}
                type="primary"
                disabled={isFutureMatch}
              />

            </div>
          </div>
          {!isFreePlay && !isTournament && (  //TODO -> Remove Stakings For Tournaments
            <div className="flex justify-center">
              <div className="flex bg-borderColor h-12 rounded-xl items-center px-7 justify-center w-full max-w-[350px]">
                <span className=" text-white text-sm md:text-lg">Staking {(matchMaking.enableStaking || currentMatch.stakingEnabled) && 'Enabled' || tournaments.enableStaking && 'Enabled' || 'Disabled'}</span>

              </div>
            </div>
          )}
          <div className="flex justify-center gap-2 w-full">
            <div className="w-full max-w-[350px] h-[74px] rounded-[16px] bg-borderColor flex justify-center items-center px-[30px] py-[8px] flex-col">
              <span className="text-white text-[20px]  ">
                {!allReady ? "Waiting for players" : "Waiting to start"}
              </span>
              {currentMatch.gameType && (
                <span className="text-primary text-[20px] ">
                  {teamOneReady.length + teamTwoReady.length}/
                  {gameTypesPlayerCount[currentMatch.gameType]}
                </span>
              )}
            </div>
          </div>
          <StreamModal
            visible={isStreamModalVisible}
            onPressYes={() => navigate(`/streaming/${matchId}/platform`)}
            onPressNo={handleStreamModalNo}
            onClose={() => setIsStreamModalVisible(false)}
          />
          {startMatchButton && (
            <div className="flex justify-center">
              <ButtonDc
                text={"Start match"}
                action={handleStartButtonPress}
                type="primary"
                disabled={!allReady}
              />
            </div>
          )}
          {isUserHost ? (
            <div className="flex justify-center">
              {!isTournament && (
                <ButtonDc
                  text="Cancel game"
                  action={handleCancelMatch}
                  type="error"
                  disabled={isStakingEnabled}
                />
              )}
            </div>
          ) : (
            <div className="flex justify-center">
              {!isTournament && (
                <ButtonDc
                  text="Leave match"
                  action={handleLeaveMatch}
                  type="error"
                  disabled={isStakingEnabled}
                />
              )}
            </div>
          )}
          {showMatchInfo && (
            <TournamentInfoModal
              closeModal={() => {
                setShowMatchInfo(false);
              }}
              data={currentMatch}
            />
          )}
        </div>
      </div>
      <LoadingAnim loading={loading || loader} />
    </div>
  );
};

export default LobbyScreen;
