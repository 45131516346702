import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import firebaseConfigDev from "./firebaseConfigDev.json";
import firebaseConfig from "./firebaseConfig.json";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const env = process.env.REACT_APP_ENV;
const FIREBASE = env === "DEV" ? firebaseConfigDev : firebaseConfig;
const VAPID = process.env.REACT_APP_VAPID_KEY;
const RECAPTCHA_SITE_KEY: any = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const firebase = {
  apiKey: FIREBASE.apiKey,
  authDomain: FIREBASE.authDomain,
  projectId: FIREBASE.projectId,
  storageBucket: FIREBASE.storageBucket,
  messagingSenderId: FIREBASE.messagingSenderId,
  appId: FIREBASE.appId,
};

const app = initializeApp(firebase);
{
  env !== "DEV" &&
    initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(RECAPTCHA_SITE_KEY),
      isTokenAutoRefreshEnabled: true,
    });
}

export default app;

export const messaging = getMessaging(app);

export const getTokenFromFirebase = () => {
  if (!window.Notification || !navigator.serviceWorker)
    return Promise.resolve(null);
  return new Promise(async (resolve) => {
    try {
      const token = await getToken(messaging, {
        vapidKey: VAPID,
      });
      resolve(token);
    } catch (error) {
      console.error("Error getting token: ", error);
      resolve(null);
    }
  });
};
