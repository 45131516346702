import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import {
  setSelectedGameTeam,
  setSelectedGameType,
} from "store/matchMaking/matchMakingSlice";
import {
  setSelectedTournamentGameTeam,
  setSelectedTournamentGameType,
} from "store/tournament/tournamentSlice";
import CloseIcon from "assets/close-button.svg";
import BackIcon from "assets/arrow-right.svg";
import ButtonDc from "components/Button";

export default function SelectGameTypeScreen({ }) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  let { from } = useParams();
  const matchMaking = useAppSelector((state: RootState) => state.matchMaking);
  const tournaments = useAppSelector((state: RootState) => state.tournament);
  const [selectedType, setSelectedType] = useState<any>();
  const [selectedTeam, setSelectedTeam] = useState("");
  const isNextDisabled = !selectedTeam;
  const duelPath = matchMaking.selectedDuelPath;
  const gameTypes =
    duelPath === "CREATE_TOURNAMENT"
      ? tournaments.selectedGame?.gameTypes
      : matchMaking.selectedGame?.gameTypes;

  const onCancel = () => {
    navigate("/wagering");
  };
  const onBack = () => {
    navigate("/duels/select-platform");
  };
  const handleNext = () => {
    if (duelPath === "CREATE_TOURNAMENT") {
      dispatch(setSelectedTournamentGameTeam(selectedTeam));
      dispatch(setSelectedTournamentGameType(selectedType));
    } else {
      dispatch(setSelectedGameTeam(selectedTeam));
      dispatch(setSelectedGameType(selectedType));
    }
    if (from) return navigate("/duels/duel-overview");
    navigate("/duels/how-many-rounds");
  };
  const renderTypes = (item: any, index: number) => {
    const selected = item === selectedType;
    return (
      <button
        className={`flex h-16 w-52 rounded-lg items-center justify-center gap-[14.33px] transform transition-transform hover:scale-105 hover:shadow-lg bg-borderColor  ${selected ? "border-4 border-[#FFD482]" : ""
          }`}
        key={index}
        onClick={() => setSelectedType(item)}
      >
        <span className="text-center text-white text-xl max-md:text-sm max-md:w-[55px]">
          {item.text}
        </span>
      </button>
    );
  };

  const renderTeams = (item: any, index: number) => {
    const selected = item === selectedTeam;
    return (
      <button
        className={`flex w-[277px] h-[130px] p-[1.5em] items-center justify-center gap-[14.33px] rounded-[17.2px] transform transition-transform hover:scale-105 hover:shadow-lg bg-borderColor ${selected ? "border-4 border-[#FFD482]" : ""
          }`}
        key={index}
        onClick={() => setSelectedTeam(item)}
      >
        <span className="text-center text-white text-2xl max-md:text-xl">
          {item.name}
        </span>
      </button>
    );
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat gap-[7em] flex flex-col items-center   h-screen ">
      <div className="container flex flex-col gap-y-[20px] items-center md:my-16 max-md:p-2">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="w-full mb-5 flex justify-between">
            {duelPath === "CREATE_TOURNAMENT" ? <span className="text-lg text-subText items-center font-normal uppercase">
              {duelPath} {'>'} {tournaments?.selectedGame?.name} {'>'} {tournaments?.console.text}
            </span>
              :
              <span className="text-lg text-subText items-center font-normal uppercase">
                {duelPath} {'>'} {matchMaking?.matchSchedule} {'>'} {matchMaking?.selectedGame?.name} {'>'} {matchMaking?.console.text}
              </span>
            }

          </div>
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex md:items-center justify-between  max-md:flex-col ">
              <div className="flex flex-row items-center gap-5">
                <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                  <img src={BackIcon} alt="Close" />
                </button>
                <span className="text-3xl text-white items-center font-normal max-md:mt-10">
                  Select a game type
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <span className=" text-subText text-xl font-normal">
              Here you choose all the games types your looking...
            </span>
          </div>
          <div className="flex flex-row items-center justify-center gap-[3em] my-8 max-md:overflow-x-auto max-md:gap-2">
            {gameTypes?.map(renderTypes)}
          </div>
          <div className="w-full flex gap-x-[40px] gap-y-[20px] flex-wrap overflow-y-auto justify-center p-1 max-md:h-[500px]">
            {selectedType?.teams.map(renderTeams)}
          </div>
        </div>
        <ButtonDc
          type="primary"
          className="absolute bottom-28 max-md:w-full "
          textClassName="max-md:text-sm"
          text={'Next'}
          action={handleNext}
          disabled={isNextDisabled}
        />
      </div>
    </div>
  );
}
