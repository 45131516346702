import React from "react";
import CloseIcon from "../assets/close-button.svg";
import StreamerView from "./StreamerView";
import ImageHolder from "./ImagePlaceholder";
import { ReactComponent as Live } from "assets/streaming/live.svg";

interface InAppStreamViewerProps {
  visible?: boolean;
  streamRoomId: string;
  streamToken: string;
  username: string;
  profilePicture?: string;
  onClose: () => void;
}

const InAppStreamViewer: React.FC<InAppStreamViewerProps> = ({
  visible,
  streamRoomId,
  streamToken,
  username,
  profilePicture,
  onClose,
}) => {
  return (
    <>
      {visible && (
        <div className="w-full h-screen bg-modalFade  fixed z-10 ">
          <div
            className="w-3/5 h-4/5 p-[30px] bg-cardBackground rounded-[34px] shadow-lg absolute top-1/2 
          left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-[50px] max-md:w-full items-center"
          >
            <div className="w-full rounded-t-[34px] flex items-center justify-between mt-5">
              <span className="text-2xl text-white items-center font-normal">
                Watch lives
              </span>
              <button className=" w-[25px] h-[25px]" onClick={onClose}>
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <div className="bg-background  desktop:w-1/2 border-[6px] min-h-[300px] max-w-[800px] w-full border-borderColor rounded-[46px] ">
              <StreamerView
                streamRoomId={streamRoomId}
                streamToken={streamToken}
                isHost={false}
                userName={username}
              />
            </div>
            <div className="flex flex-row w-full justify-start">
              <div className="flex flex-row gap-3 items-center">
                <div className="flex flex-col items-center">
                  <ImageHolder
                    uri={profilePicture}
                    className="w-16 h-16 rounded-full border-4 border-brightRed"
                  />
                  <Live />
                </div>
                <div className="flex flex-col">

                  <span className="text-white text-sm ">
                    {username}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InAppStreamViewer;
