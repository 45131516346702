import React, { useEffect, useState } from "react";
import CloseIcon from "../assets/close-button.svg";

import { ReactComponent as FacebookLogo } from "assets/Facebook.svg";
import { ReactComponent as GoogleLogo } from "assets/Google_Icon.svg";
import { ReactComponent as AppleLogo } from "assets/apple_logo.svg";

import { getTokenFromFirebase } from "firebaseConfig/firebase";
import { AppDispatch, RootState } from "store/index";
import { useDispatch } from "react-redux";
import { signIn } from "store/auth/actions";
import { useNavigate } from "react-router-dom";
import { loginWithFacebook, setLoginModalVisible } from "store/auth/authSlice";
import { toast } from "react-toastify";
import { useAppSelector } from "store/hooks";
interface LoginModalProps {

  visible?: boolean;
}

const LoginModal: React.FC<LoginModalProps> = ({
  visible,

}) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [deviceToken, setDeviceToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { loginModalVisible } = useAppSelector((state: RootState) => state.auth);
  useEffect(() => {
    (async () => {
      const token = (await getTokenFromFirebase()) as string;
      setDeviceToken(token);
    })();
  }, []);

  const handleSignIn = async () => {
    dispatch(
      signIn({
        username: userName,
        password: password,
        deviceToken: deviceToken,
      })
    ).then((data) => {
      dispatch(setLoginModalVisible(false))
      if (data.payload?.type === "VERIFY_FAILED") navigate("/verify-otp/confirm");
      navigate("/");
    });

    if (rememberMe) {
      localStorage.setItem("username", userName);
      localStorage.setItem("password", password);
      localStorage.setItem("rememberMe", "true");
    } else {
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      localStorage.removeItem("rememberMe");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const token = window.location?.search
      ?.split("?Authorization=")?.[1]
      ?.split("?")?.[0];
    const error = window.location?.search.split('?Error=')[1];
    if (error) {
      const encodedError = error;
      const decodedError = decodeURIComponent(encodedError);
      console.log(decodedError);
      toast.error(decodedError);
      return;
    }
    if (!token) return;
    dispatch(loginWithFacebook(token));
    dispatch(setLoginModalVisible(false))
    navigate("/");
  }, [window.location?.href]);

  useEffect(() => {
    const storedUsername = localStorage.getItem("username") || "";
    const storedPassword = localStorage.getItem("password") || "";
    const storedRememberMe = localStorage.getItem("rememberMe") === "true";
    setUserName(storedUsername);
    setPassword(storedPassword);
    setRememberMe(storedRememberMe);
  }, []);

  const handleSignUpWithFacebook = async () => {
    window.open(`${process.env.REACT_APP_BASE_URL}/auth/facebook?deviceToken=${deviceToken}`, "_self");
  };
  const handleSignUpWithGoogle = async () => {
    window.open(`${process.env.REACT_APP_BASE_URL}/auth/google?deviceToken=${deviceToken}`, "_self");
  };
  const handleSignUpWithApple = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}/auth/apple?deviceToken=${deviceToken}`, "_self");
  };

  return (
    <>
      {loginModalVisible && (
        <div className="w-full h-screen bg-modalFade  fixed z-50 ">
          <div
            className="md:max-w-[40vw] md:min-w-[500px] md:min-h-[700px] md:max-h-[70vh] w-[calc(60%-176px)] h-[calc(90%-160px)] p-[30px] bg-cardBackground rounded-[34px] shadow-lg absolute top-1/2
           left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-[50px] max-md:w-full max-md:h-full"
          >
            <div className="flex flex-col items-center justify-center h-full w-full gap-[15px] md:p-10 max-md:py-10">
              <div className="w-full rounded-t-[34px] flex items-center justify-between">
                <div className="flex flex-row items-center gap-3">
                  <h1 className="text-[32px] font-bold text-primaryYellow">Sign In</h1>
                  <h1 className="text-[28px] font-bold text-white">to continue</h1>
                </div>
                <button className=" w-[25px] h-[25px]" onClick={() => dispatch(setLoginModalVisible(false))}>
                  <img src={CloseIcon} alt="Close" />
                </button>
              </div>
              <div className="flex flex-col items-center justify-center h-full w-full gap-[15px] ">

                <div className="flex flex-col items-center max-w-[505px] min-w-[280px] w-[35vw] gap-5">
                  <button
                    onClick={handleSignUpWithFacebook}
                    className="items-center text-white justify-center text-center flex flex-row gap-5 bg-blueBg w-full h-[51.6px] mx-6 mt-5 rounded-xl"
                  >
                    <FacebookLogo width={20} height={20} />
                    Continue with Facebook
                  </button>
                </div>
                <div className="flex flex-col items-center max-w-[505px] min-w-[280px] w-[35vw] gap-5">
                  <button
                    onClick={handleSignUpWithGoogle}
                    className="items-center text-black font-semibold justify-center text-center flex flex-row gap-5 bg-white w-full h-[51.6px] mx-6 rounded-xl"
                  >
                    <GoogleLogo width={24} height={24} />
                    Continue with Google
                  </button>
                </div>
                <div className="flex flex-col items-center max-w-[505px] min-w-[280px] w-[35vw] gap-5">
                  <button
                    onClick={handleSignUpWithApple}
                    className="items-center text-black font-semibold justify-center text-center flex flex-row gap-5 bg-white w-full h-[51.6px] mx-6 rounded-xl"
                  >
                    <AppleLogo width={24} height={24} />
                    Continue with Apple
                  </button>
                </div>
                <div className="flex flex-row items-center justify-center gap-5 max-w-[505px] min-w-[280px] w-[35vw] my-5">
                  <div className="w-[45%] h-[1px] bg-[#d0d0d0]"></div>
                  <p className="text-[#d0d0d0] text-[12px] text-center">Or</p>
                  <div className="w-[45%] h-[1px] bg-[#d0d0d0]"></div>
                </div>
                <div className="flex flex-col max-w-[505px] min-w-[280px] w-[35vw] items-center">

                  <div className="relative w-full">
                    <input
                      type="text"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      id="floating_filled"
                      className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-white outline-none bg-inputGray border-0 border-b-2 border-inputGray appearance-none focus:outline-none focus:ring-0 focus:border-primaryYellow peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="floating_filled"
                      className="absolute text-sm text-white duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primaryYellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                    >
                      Username
                    </label>
                  </div>
                  <div className="relative w-full mt-[20px]">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-white outline-none bg-inputGray border-0 border-b-2 border-inputGray appearance-none focus:outline-none focus:ring-0 focus:border-primaryYellow peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="password"
                      className="absolute text-sm text-white duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primaryYellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                    >
                      Password
                    </label>
                    <button
                      onClick={togglePasswordVisibility}
                      type="button"
                      className="absolute right-4 top-4 text-white"
                    >
                      <i
                        className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                      ></i>
                    </button>
                  </div>
                  <div className="flex flex-col items-center max-w-[505px] min-w-[280px] w-[35vw]  gap-5 mt-[20px]">
                    <button
                      className="items-center text-black justify-center text-center flex flex-row gap-5 font-bold bg-gradient-to-b from-primaryYellow to-secondaryYellow hover:from-secondaryYellow hover:to-primaryYellow ... w-full h-[51.6px] mx-6 rounded-xl"
                      onClick={handleSignIn}
                    >
                      Sign In
                    </button>
                  </div>
                  <p className="mt-3 text-center text-white">
                    Don't have an Account?{" "}
                    <a
                      href="/sign-up"
                      onClick={() => dispatch(setLoginModalVisible(false))}
                      className="text-primaryYellow hover:text-yellow-600 font-semibold"
                    >
                      Sign up
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginModal;
