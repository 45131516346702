import React from "react";
import { ColorRing } from "react-loader-spinner";

interface LoaderProps {
  loading?: boolean;
  notifications?: boolean;
  chat?: boolean;
}

const LoadingAnim: React.FC<LoaderProps> = ({ loading, notifications, chat }) => {
  const isActive = loading || notifications; // Only render if one of them is active

  if (!isActive) return null;

  return (
    <div className={`${chat ? "right-[15vw]" : "w-full h-full "} fixed z-40 flex items-center justify-center  ${loading ? "bg-modalFade" : "md:h-[90vh] md:w-[35vw] md:min-w-[450px]  flex items-center justify-center"}`}>
      <div className="p-[30px] bg-gradient-to-br from-[#1a1a1a] to-[#333] rounded-[34px] shadow-lg relative animate-pulse flex flex-col gap-y-[50px] items-center">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#EEB033", "#fc3e3e", "#EEB033", "#fc3e3e", "#9a031e"]}
        />
        {notifications && (
          <p className="text-white text-lg animate-bounce">Fetching Notifications...</p>
        )}
      </div>
    </div>


  );
};

export default LoadingAnim;
