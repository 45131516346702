import {
  GetAllEventsApi,
  GetAllLivesApi,
  GetAllStakingEventsApi,
  GetAllTournamentsApi,
} from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getEvents = createAsyncThunk<any, void>(
  "event/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await GetAllEventsApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getLives = createAsyncThunk<any, any>(
  "event/getLives",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await GetAllLivesApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getTournaments = createAsyncThunk<any, void>(
  "event/getTournaments",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await GetAllTournamentsApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getStakingEvents = createAsyncThunk<any, void>(
  "event/getStakingEvents",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await GetAllStakingEventsApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
