import React, { useEffect, useState } from "react";
import ButtonDc from "components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { getCurrentMatch } from "store/currentMatch/currentMatchActions";
import { ReactComponent as Arrow } from "assets/arrow-right-gaming.svg";
import PaySuccess from "assets/payment_success.svg";
import PayUnsuccess from "assets/payment_unsuccess.svg";
import Summary from "assets/summary.png";
import ImageHolder from "components/ImagePlaceholder";
import CloseIcon from "assets/close-button.svg";
import { gameTypeValues } from "types/constants";
import { resetCurrentMatch } from "store/currentMatch/currentMatchSlice";
import { resetBackgroundMatch } from "store/matchMaking/matchMakingSlice";
import { resetTournamentMaking } from "store/tournament/tournamentSlice";
import { resetLiveBrackets } from "store/liveBrackets/liveBracketSlice";
import WinnerDisplay from "components/WinnerDisplay";

const PaymentStatusScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const { matchId, paid } = useParams();
  const matchMaking = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;
  const liveBracketTournament = useAppSelector(
    (state: RootState) => state.liveBrackets.tournamentInfo
  );
  const winner = matchMaking?.winner?.toString();
  const gameType = matchMaking?.gameType;
  const rounds = matchMaking?.rounds?.replace("best ", "");
  const gameTypeString = gameTypeValues[gameType];
  const cubes = matchMaking?.cubeWager;
  const players = matchMaking?.players;
  const team1 = matchMaking?.team1;
  const team2 = matchMaking?.team2;
  const [team1JSON, setTeam1JSON] = useState<any>([]);
  const [team2JSON, setTeam2JSON] = useState<any>([]);


  const handleTryAgain = () => {
    //TODO navigate to payment screen
  };
  const paidScreen = paid === "paid"

  const onCancel = () => {
    const isATournamentMatch = matchMaking.matchType === "TOURNAMENT";
    dispatch(resetTournamentMaking());
    dispatch(resetCurrentMatch());
    dispatch(resetBackgroundMatch());
    dispatch(resetLiveBrackets());
    if (isATournamentMatch)
      return navigate(`/tournament/live-bracket/${matchId}`);
    setTimeout(() => {
      navigate("/");
    }, 300);
  };

  useEffect(() => {
    dispatch(getCurrentMatch({ matchId }));
  }, [dispatch, matchId]);

  useEffect(() => {
    const isSuccess = window.location.search?.split("?payment=")?.[1];
    setIsPaymentSuccessful(isSuccess === "success");
    dispatch(resetBackgroundMatch());
  }, []);

  const findPlayerById = (id: never, playersArray: any[]) => {
    return playersArray.find((player) => player._id === id);
  };

  useEffect(() => {
    if (team1 && team1.length > 0) {
      const playersArray: any[] = [];
      team1.forEach((playerId) => {
        const player = findPlayerById(playerId, players);
        if (player) {
          playersArray.push(player);
        }
      });
      setTeam1JSON(playersArray);
    }
  }, [players, team1]);

  useEffect(() => {
    if (team2 && team2.length > 0) {
      const playersArray: any[] = [];
      team2.forEach((playerId) => {
        const player = findPlayerById(playerId, players);
        if (player) {
          playersArray.push(player);
        }
      });
      setTeam2JSON(playersArray);
    }
  }, [players, team2]);

  return (
    <div className=" max-md:p-3  max-md:items-center  max-md:justify-center mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex min-h-screen h-full">
      <div className="container flex flex-col gap-y-[20px] md:my-16 items-center max-md:p-5">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-5 flex flex-col justify-center items-center
        w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] gap-3 max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <button
              className=" w-[25px] h-[25px] absolute right-10 top-10"
              onClick={onCancel}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-center gap-5 md:mt-5">
            {paidScreen ? (<>
              <div className="w-36 h-36  rounded-full flex items-center justify-center">
                <img
                  src={isPaymentSuccessful ? PaySuccess : PayUnsuccess}
                  alt="scale"
                />
              </div>
              <span className="text-2xl text-white text-center items-center font-normal">
                {isPaymentSuccessful
                  ? "Payment Successful"
                  : "Payment Unsuccessful"}
              </span>
            </>) : (<>
              <div className="w-36 h-36  rounded-full flex items-center justify-center">
                <img
                  src={Summary}
                  alt="scale"
                />
              </div>
              <span className="text-2xl mb-10 text-white text-center items-center font-normal">
                Match summary
              </span>
            </>)}
          </div>

          <div className="flex flex-col justify-center items-center gap-y-10 mb-6">
            {paidScreen && <div className="justify-center items-center  text-center">
              <span className=" max-md:text-l text-xl text-subText items-center font-normal text-center">
                {isPaymentSuccessful ? (
                  <>
                    Thank you for your payment! <br />
                    Your transaction has been successfully processed!
                  </>
                ) : (
                  <>
                    Sorry, your payment could not be processed. <br />
                    Please try again.
                  </>
                )}
              </span>
            </div>}
            {!isPaymentSuccessful && (
              <ButtonDc
                text="Try again"
                action={handleTryAgain}
                short={true}
                type="primary"
              />
            )}
            <div className="flex flex-row gap-5 justify-evenly">
              <div className="flex flex-col bg-borderColor py-1 px-4  rounded-[12px]">
                <span className=" max-md:text-sm text-xl text-white font-normal ">
                  Best
                </span>
                <span className=" max-md:text-sm text-xl text-subText font-normal ">
                  {rounds}
                </span>
              </div>
              <div className="flex flex-col bg-borderColor py-1 px-4  rounded-[12px]">
                <span className=" max-md:text-sm text-xl text-white font-normal ">
                  Game Type
                </span>
                <span className=" max-md:text-sm text-xl text-subText font-normal ">
                  {gameTypeString}
                </span>
              </div>
              {cubes === 0 ? (<div className="flex flex-col bg-borderColor py-1 px-4  rounded-[12px]">
                <span className=" max-md:text-sm text-xl text-primary font-normal ">
                  Free Play
                </span>
              </div>) : (<div className="flex flex-col bg-borderColor py-1 px-4  rounded-[12px]">
                <span className=" max-md:text-sm text-xl text-white font-normal ">
                  Cube Wager
                </span>
                <span className=" max-md:text-sm text-xl text-primary font-normal ">
                  ${cubes}
                </span>
              </div>)}
            </div>
            <WinnerDisplay
              team1JSON={team1JSON}
              team2JSON={team2JSON}
              winner={winner}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatusScreen;
