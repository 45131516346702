import React, { useState } from "react";

import { useDispatch } from "react-redux";

import PasswordInput from "./PasswordInput";
import CloseIcon from "../assets/close-button.svg";

import ButtonDc from "./Button";
import { checkPassword, editUser, getUser } from "store/user/action";
import { AppDispatch, RootState } from "store/index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  atLeastOneLowercase,
  atLeastOneNumber,
  atLeastOneUppercase,
  min8chars,
} from "constant/validations";
import LoadingAnim from "./Loader";
import { useAppSelector } from "store/hooks";

export interface PasswordCheckBoxProps {
  onChange?: (value: any) => void;
  onClose?: () => void;
  visible?: boolean;
  type?: string;
}
const PasswordChecker: React.FC<PasswordCheckBoxProps> = ({
  visible,
  onClose,
  type,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [passwordValue, setPasswordValue] = useState("");
  const [modalOpen, setModalOpen] = useState(visible);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const handlePassword = (v: string) => setPasswordValue(v);
  const handleConfirmPassword = (v: string) => setConfirmPassword(v);

  const handleClick = () => {
    if (type === "EDIT_PASSWORD" || type === "NEW_PASSWORD") {
      handleEditPassword();
    } else {
      dispatch(
        checkPassword({
          payload: passwordValue,
          callback: () => {
            setModalOpen(false);
            navigate("/edit-profile/password");
          },
          errorLog: () => {
            toast.error("Incorrect password");
          },
        })
      );

    }
  };

  const Validate = ({ text, regex }: { text: string; regex: RegExp }) => {
    return (
      <div className="flex w-[9em] md:w-[17em] items-center">
        {regex.test(passwordValue) ? (
          <i
            className="fas fa-check-circle text-lightGreen"
            style={{ fontSize: "0.62em" }}
            aria-hidden="true"
          ></i>
        ) : (
          <i
            className="fas fa-sync text-[#d0d0d0]"
            style={{ fontSize: "0.62em" }}
            aria-hidden="true"
          ></i>
        )}
        <span className="text-[#d0d0d0] text-center ml-2 text-[12px]">
          {text}
        </span>
      </div>
    );
  };
  const handleEditPassword = async () => {
    if (
      !atLeastOneLowercase.test(passwordValue) ||
      !atLeastOneUppercase.test(passwordValue) ||
      !atLeastOneNumber.test(passwordValue) ||
      !min8chars.test(passwordValue) ||
      passwordValue !== confirmPassword
    ) {
      toast.error("Please check your password and confirm password fields.");
      return;
    }

    try {
      await dispatch(
        editUser({
          payload: { password: passwordValue },
          callback: () => {
            dispatch(getUser());
            setModalOpen(false);
            onClose?.();
            if (type === "NEW_PASSWORD") navigate("/edit-profile/password");
          },
        })
      );
      setModalOpen(false);

    } catch (error) {
      toast.error("An error occurred while updating the password.");
    }
  };
  return (
    <>
      {modalOpen && (
        <div className="w-full h-full bg-modalFade fixed z-10 ">
          <div
            className={`w-1/2 p-[30px] bg-cardBackground rounded-[34px] shadow-lg absolute top-1/2 
          left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-[50px] max-md:w-full ${type === "EDIT_PASSWORD" || type === "NEW_PASSWORD" ? "h-3/5" : "h-2/5"
              } `}
          >
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <span className="font-semibold text-xl text-white">
                {type === "NEW_PASSWORD" ? "Please set a password" : "Please enter your password"}
              </span>
              <button className=" w-[25px] h-[25px]" onClick={onClose}>
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <div className="w-full h-full flex flex-col justify-center items-center gap-10 overflow-y-auto">
              <div className="w-1/2 max-md:w-full">
                <PasswordInput
                  label={(type === "EDIT_PASSWORD" || type === "NEW_PASSWORD") ? "New password" : "Password"}
                  onChange={(e) => handlePassword(e.target.value)}
                  onFocus={() => {
                    (type === "EDIT_PASSWORD" || type === "NEW_PASSWORD") && setIsPasswordFocused(true);
                  }}
                  onBlur={() => setIsPasswordFocused(false)}
                />
                {(type === "EDIT_PASSWORD" || type === "NEW_PASSWORD") && (
                  <PasswordInput
                    label="Confirm password"
                    onChange={(e) => handleConfirmPassword(e.target.value)}
                    onFocus={() => setIsPasswordFocused(true)}
                    onBlur={() => setIsPasswordFocused(false)}
                  />
                )}
                <div
                  className={`transition-opacity duration-1000 ease-in-out ${isPasswordFocused
                    ? "opacity-100"
                    : "opacity-0 pointer-events-none"
                    } w-full items-center justify-center`}
                >
                  <div className="flex flex-col mt-3 items-center justify-center">
                    <div className="grid grid-cols-2  gap-0 relative ">
                      <Validate
                        regex={atLeastOneUppercase}
                        text="1 uppercase"
                      />
                      <Validate
                        regex={atLeastOneLowercase}
                        text="1 lowercase"
                      />
                      <Validate regex={atLeastOneNumber} text="1 number" />
                      <Validate regex={min8chars} text="min 8 characters" />
                    </div>
                  </div>
                </div>
              </div>
              <ButtonDc
                text={type === "EDIT_PASSWORD" ? "Change" : "Submit"}
                short
                type={"primary"}
                action={handleClick}
              />
            </div>
          </div>
          <LoadingAnim loading={user.loader} />
        </div>
      )}
    </>
  );
};

export default PasswordChecker;
