import React, { useEffect, useState } from "react";
import CloseIcon from "assets/close-button.svg";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import {
  getNotifications,
  handleNotifications,
} from "store/notifications/actions";
import InAppNotifications from "./InAppNotification";
import { notificationActionTypes } from "constant/notificationActions";
import {
  setDate,
  setDuelPath,
  setJoiningMatchId,
} from "store/matchMaking/matchMakingSlice";
import { getCurrentMatch } from "store/currentMatch/currentMatchActions";
import { getOtherUser } from "store/user/action";
import {
  resetCurrentMatch,
  setScreenStatus,
} from "store/currentMatch/currentMatchSlice";
import { setActionData } from "store/notifications/notificationSlice";
import styles from "styles/miscs/miscs.module.scss";
import { batch } from "react-redux";
import { toast } from "react-toastify";
import LoadingAnim from "./Loader";

interface NotificationModalProps {
  visible?: boolean;
  onClose: () => void;
}

const NotificationModal: React.FC<NotificationModalProps> = ({
  visible,
  onClose,
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const { notifications, loader } = useAppSelector(
    (state: RootState) => state.notification
  );
  const fcmData = useAppSelector((state) => state.fcm);
  const user = useAppSelector((state: RootState) => state.auth);
  const isSuspended = useAppSelector(
    (state: RootState) => state.user
  ).user?.isSuspended;

  useEffect(() => {
    if (
      fcmData.data.type === "TOURNAMENT_INVITATION" ||
      fcmData.data.type === "MATCH_CHALLENGE" ||
      fcmData.data.type === "CHALLENGE_INVITE"
    ) {
      fetchData();
    }
  }, [fcmData]);

  const sortedNotifications = [...notifications]
    ?.reverse()
    ?.slice(0, 100)
    ?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const [page, setPage] = useState(1);
  const [invited, setInvited] = useState<string[]>([]);
  const fetchData = () => {
    if (!user.userToken) return;
    dispatch(getNotifications({ page }));
  };

  useEffect(() => {
    fetchData();
  }, [visible]);

  const navigate = useNavigate();
  const handleCancel = () => {
    onClose?.();
  };

  const renderData = (item: any, index: number) => {
    const closeInvitation = () => {
      batch(() => {
        dispatch(resetCurrentMatch());
        dispatch(
          handleNotifications({
            payload: {
              actionData: item.actionData,
              actionType: item.actionType,
              actionState: false,
            },
            callback: fetchData,
          })
        );
      });
    };

    const handleJoinTournament = (item: any) => {
      const tournamentId = item.actionData;
      console.log(item)
      dispatch(setDuelPath("OPPONENT_JOIN_MATCH"));
      dispatch(setScreenStatus("OPPONENT_JOIN_MATCH"));
      dispatch(setActionData(item));
      navigate(`/duels/duel-overview/${tournamentId}`);
    };

    const handleMatchInvitation = async (item: any) => {
      dispatch(setDuelPath("JOIN_MATCH"));
      dispatch(setScreenStatus("JOIN_MATCH"));
      const matchId = item.actionData.split(",")[0];
      dispatch(setJoiningMatchId(matchId));
      dispatch(setActionData(item));
      setTimeout(() => {
        navigate(`/duels/duel-overview/${item.actionData}}`);
      }, 500);
    };

    const handleJoinGameChallenge = (item: any) => {
      const challengeId = item.actionData;

      dispatch(setActionData(item));
      navigate(`/gaming/overview/${challengeId}`);
    };
    const handleEventPress = (event: any) => {
      if (!event?._id) return;
      dispatch(getCurrentMatch(event?._id));
    };

    const joinDuel = async () => {
      console.log("JOIN => ", item);
      handleEventPress(item.actionData);
      dispatch(setJoiningMatchId(item.actionData));
      dispatch(setDate(new Date()));
      navigate(`/duels/duel-overview/${item}`);
    };

    const joinChallenge = async () => {
      const matchId = item.actionData;
      dispatch(setJoiningMatchId(matchId));
    };

    const joinMatch = async () => {
      const matchId = item.actionData.split(",")[0];
      dispatch(setJoiningMatchId(matchId));
      navigate(`/duels/lobby/${matchId}`);
    };

    const joinCommunity = (id: any) => {
      return navigate(`/communication/main/${id}`);
    };

    const sendMoney = async () => {
      const [amount, receiverId, username, profilePicture] =
        item.actionData.split(",");
      const receiver = {
        _id: receiverId,
        username,
        profilePicture:
          profilePicture === "undefined" ? undefined : profilePicture,
      };
      //TODO -> Navigate Amount screen with params (receiver,amount)
    };

    const reFollow = (id: string) => {
      dispatch(getOtherUser({ id }));
    };

    const acceptInvitation = async () => {
      if (isSuspended) return toast.warning('Account Suspended');
      dispatch(setScreenStatus("JOIN_MATCH"));
      if (
        item.actionType === "JOIN_MATCH" ||
        item.actionType === "JOIN_DUEL" ||
        item.actionType === "OPPONENT_JOIN_MATCH"

      )
        return handleMatchInvitation(item);
      if (item.actionType === "JOIN_TOURNAMENT")
        return handleJoinTournament(item);
      if (item.actionType === "OPPONENT_JOIN_CHALLENGE")
        return handleJoinGameChallenge(item);
      dispatch(
        handleNotifications({
          payload: {
            actionData: item.actionData,
            actionType: item.actionType,
            actionState: true,
          },
        })
      ).then((data) => {
        setInvited((prevArray) => [...prevArray, item._id]);
        switch (item.actionType) {
          case notificationActionTypes.JOIN_DUEL:
            joinDuel();
            break;
          case notificationActionTypes.JOIN_COMMUNITY:
            joinCommunity(item.actionData);
            break;
          case notificationActionTypes.JOIN_MATCH:
            joinMatch();
            break;
          case notificationActionTypes.OPPONENT_JOIN_MATCH:
            joinChallenge();
            break;
          case notificationActionTypes.JOIN_TOURNAMENT:
            handleJoinTournament(item);
            break;
          case notificationActionTypes.REQUEST_MONEY:
            sendMoney();
            break;
          default:
            reFollow(item.actionData);
        }
      });
    };

    const pressNotification = async (actionType: any) => {
      if (actionType === "TRANSACTION") {
        //TODO -> Navigate to wallet
      }
    };
    return (
      <InAppNotifications
        key={index}
        image={item.image}
        text={item.body}
        date={item.date}
        type={item.actionType}
        state={item.actionState}
        invited={invited}
        _id={item._id}
        onClose={closeInvitation}
        onAccept={acceptInvitation}
        onPressNotification={() => pressNotification(item?.actionType)}
      />
    );
  };

  if (visible) {
    return (
      <div
        className="w-full h-screen fixed z-10 overflow-hidden"
        onClick={() => onClose?.()}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="w-full h-full md:w-2/5 md:min-w-[500px] md:h-[95vh] md:p-[30px] bg-cardBackground md:rounded-[34px] shadow-lg absolute md:top-1/2 md:left-[190px] m:transform  md:-translate-y-1/2 flex flex-col p-2 overflow-y-auto"
        >
          <button
            className="w-[15px] h-[15px] absolute md:right-10 right-[20px] top-[20px]"
            onClick={handleCancel}
          >
            <img src={CloseIcon} alt="Close" />
          </button>
          <div className="flex flex-row items-center justify-center mt-4">
            <span className="text-white text-lg">Notifications</span>
          </div>
          <div
            className={`flex flex-col mt-5 gap-y-4 overflow-x-hidden ${styles.beautifulScrollbar}`}
          >
            {sortedNotifications.map(renderData)}
          </div>
          <LoadingAnim
            notifications={loader}
          />
        </div>
      </div>
    );
  }
  return <></>;
};

export default NotificationModal;
