import { createSlice } from "@reduxjs/toolkit";

import {
  createMainTournament,
  createTournamentMatch,
  inviteToTournament,
  goToTournamentMatch,
  getEndedTournaments,
} from "./action";

import { getStagedPlayers } from "store/liveBrackets/actions";
import { signOut } from "store/auth/actions";

type platformType = {
  xbox: boolean;
  playstation: boolean;
  pc: boolean;
};

type teamTypes = {
  name: string;
  roundOptions: number;
  _id: string;
};

type gameTypeTypes = {
  type: string;
  text: string;
  teams: teamTypes[];
  _id: string;
};

type roundTypes = {
  name: string;
  _id: string;
};

type consoleType = {
  type: string;
  provider: string;
  text: string;
  _id: string;
  subtext: string;
  consoleType: string;
};

type providersType = {
  type: string;
  text: string;
  subtext: string;
};
type gameType = {
  platforms: platformType;
  _id: string;
  name: string;
  image: string;
  consoles: consoleType[];
  rounds: roundTypes[];
  gameTypes: gameTypeTypes[];
  providers: providersType[];
  streamRequired: boolean;
};

interface TournamentSlice {
  team1: any[];
  team2: any[];
  endedTournaments: any[];
  tournamentType: string;
  selectedRound: {
    _id: string;
    name: string;
  };
  cubes: string;
  disputes: any[];
  winner: boolean;
  selectedInvitees: any[];
  tournamentStarting: boolean;
  tournamentDate: string;
  tournamentMatches: any[];
  tournamentState?: string;
  tournamentId: string;
  tournamentNow: boolean;
  settingNextRound: boolean;
  stagedPlayers?: any[];
  loading: boolean;
  isStreaming?: boolean;
  currentTournament: any;
  privacy: string;
  selectedGame: gameType | null;
  console: {
    type?: string;
    provider?: string;
    text?: string;
    _id?: string;
    subtext?: string;
    consoleType?: string;
  };
  selectedPlatform: string;
  selectedGameType: gameTypeTypes;
  selectedTeam: string;
  enableStaking: boolean;
}

const initialState: TournamentSlice = {
  team1: [],
  team2: [],
  endedTournaments: [],
  tournamentType: "",
  selectedRound: {
    _id: "",
    name: "",
  },
  cubes: "",
  disputes: [],
  winner: false,
  selectedInvitees: [],
  tournamentStarting: false,
  tournamentDate: new Date().toISOString(),
  tournamentMatches: [],
  tournamentId: "",
  tournamentNow: false,
  settingNextRound: false,
  stagedPlayers: [],
  loading: false,
  isStreaming: false,
  currentTournament: {},
  privacy: "Private",
  selectedGame: {
    _id: "",
    platforms: { xbox: false, playstation: false, pc: false },
    name: "",
    image: "",
    consoles: [],
    rounds: [],
    gameTypes: [],
    providers: [],
    streamRequired: false,
  },
  console: {},

  selectedPlatform: "",
  selectedGameType: { _id: "", text: "", type: "", teams: [] },
  selectedTeam: "",
  enableStaking: false,
};

export const tournamentSlice = createSlice({
  name: "tournament",
  initialState,
  reducers: {
    setMatchDate: (state, action) => {
      state.tournamentDate = action.payload;
    },
    setTournamentStarting: (state, action) => {
      state.tournamentStarting = action.payload;
    },
    setTournamentMatches: (state, action) => {
      state.tournamentMatches = [...state.tournamentMatches, ...action.payload];
    },
    setTeamOne: (state, action) => {
      state.team1 = [...action.payload];
    },
    setTeamTwo: (state, action) => {
      state.team2 = [...action.payload];
    },
    setTournamentId: (state, action) => {
      state.tournamentId = action.payload;
    },
    setSettingNextRound: (state, action) => {
      state.settingNextRound = action.payload;
    },
    resetTournamentMaking: (state) => {
      state.team1 = [];
      state.team2 = [];
      state.tournamentType = "";
      state.selectedRound = {
        _id: "",
        name: "",
      };
      state.cubes = "";
      state.disputes = [];
      state.winner = false;
      state.selectedInvitees = [];
      state.tournamentStarting = false;
      state.tournamentDate = new Date().toISOString();
      state.tournamentMatches = [];
      state.tournamentNow = false;
      state.settingNextRound = false;
      state.stagedPlayers = [];
      state.loading = false;
      state.isStreaming = false;
      state.currentTournament = {};
      state.privacy = "Private";
      state.selectedGame = {
        _id: "",
        platforms: { xbox: false, playstation: false, pc: false },
        name: "",
        image: "",
        consoles: [],
        rounds: [],
        gameTypes: [],
        providers: [],
        streamRequired: false,
      };
      state.console = {};
      state.selectedPlatform = "";
      state.selectedGameType = { _id: "", text: "", type: "", teams: [] };
      state.selectedTeam = "";
    },
    resetTournamentMatches: (state) => {
      state.tournamentMatches = [];
    },
    resetTournamentSlice: (state) => {
      return initialState;
    },
    setIsStreaming: (state, action) => {
      state.isStreaming = action.payload;
    },
    setCurrentTournament: (state, action) => {
      state.currentTournament = action.payload;
    },
    setCurrentTournamentState: (state, action) => {
      state.currentTournament.state = action.payload;
    },
    setTournamentType: (state, action) => {
      state.tournamentType = action.payload;
    },
    setPrivacy: (state, action) => {
      state.privacy = action.payload;
    },
    setSelectedTournamentGame: (state, action) => {
      state.selectedGame = action.payload;
    },
    setSelectedTournamentGameImage: (state, action) => {
      if (state.selectedGame) state.selectedGame.image = action.payload;
    },
    setTournamentConsole: (state, action) => {
      state.console = action.payload;
    },
    setTournamentPlatform: (state, action) => {
      state.selectedPlatform = action.payload;
    },
    setSelectedTournamentGameType: (state, action) => {
      state.selectedGameType = action.payload;
    },
    setSelectedTournamentGameTeam: (state, action) => {
      state.selectedTeam = action.payload;
    },
    setTournamentCubes: (state, action) => {
      state.cubes = action.payload;
    },
    setSelectedTournamentRound: (state, action) => {
      state.selectedRound = action.payload;
    },
    resetTournamentId: (state) => {
      state.tournamentId = "";
    },
    setEnableTournamentStaking: (state, action) => {
      state.enableStaking = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createMainTournament.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createMainTournament.fulfilled, (state, action) => {
      state.tournamentId = action.payload.tournamentId;
      state.loading = false;
    });
    builder.addCase(createMainTournament.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createTournamentMatch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTournamentMatch.fulfilled, (state, action) => {
      state.tournamentId = action.payload.tournamentId;
      state.loading = false;
    });
    builder.addCase(createTournamentMatch.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getStagedPlayers.fulfilled, (state, action) => {
      state.stagedPlayers = action.payload.stagedPlayers;
    });

    builder.addCase(inviteToTournament.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(inviteToTournament.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(inviteToTournament.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(goToTournamentMatch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(goToTournamentMatch.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(goToTournamentMatch.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
    builder.addCase(getEndedTournaments.fulfilled, (state, action) => {
      state.endedTournaments = action.payload?.tournaments;
    });
  },
});

export default tournamentSlice.reducer;
export const {
  setSelectedTournamentGame,
  setSelectedTournamentGameImage,
  setMatchDate,
  setTournamentStarting,
  setTournamentMatches,
  setTeamOne,
  setTeamTwo,
  setTournamentId,
  setSettingNextRound,
  resetTournamentMaking,
  resetTournamentMatches,
  setIsStreaming,
  setCurrentTournament,
  setCurrentTournamentState,
  setTournamentType,
  setPrivacy,
  setTournamentConsole,
  setTournamentPlatform,
  setSelectedTournamentGameType,
  setSelectedTournamentGameTeam,
  setTournamentCubes,
  setSelectedTournamentRound,
  resetTournamentId,
  resetTournamentSlice,
  setEnableTournamentStaking,
} = tournamentSlice.actions;
