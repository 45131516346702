import { toast } from "react-toastify";
import {
  checkIfBalanceIsEnoughApi,
  getCommunityDuelistsApi,
  getCommunityFriendsApi,
  getOtherUserApi,
  getUserApi,
  editUserApi,
  uploadImageApi,
  followUserApi,
  unfollowUserApi,
  getFollowersApi,
  getFollowingApi,
  removeFollowerApi,
  leaderBoardApi,
  checkPasswordApi,
  techSupportApi,
  deleteAccountApi,
  getReferralApi,
  deleteLinkedFacebookAccountApi,
  deleteLinkedGoogleAccountApi,
  deleteLinkedAppleAccountApi,
} from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateActivitionUserApi,
  updateBattleIdApi,
  updateEaUserApi,
  updateEpicUserApi,
  updateMlbUserApi,
  updateNintendoIdApi,
  updateOriginIdApi,
  updatePlayStationUserApi,
  updateSteamIdApi,
  updateStreetFighterUserApi,
  updateTwitchUserApi,
  updateXboxUserApi,
} from "api/settings.api";
interface WithCallback {
  payload: any;
  callback?: (data: any) => void;
  errorLog?: () => void;
}

export const getCommunityFriends = createAsyncThunk<any, any>(
  "/user/community-friends",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getCommunityFriendsApi(payload.page);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCommunityDuelists = createAsyncThunk<any, any>(
  "/user/community-duelists",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getCommunityDuelistsApi(payload.page);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getOtherUser = createAsyncThunk<any, any>(
  "user/getOtherUser",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getOtherUserApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getUser = createAsyncThunk<any, void>(
  "user/getUser",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await getUserApi();
      return data;
    } catch (error: any) {
      if (error?.status === 500) {
        toast.error("Session expired.Please login again");
      }
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const checkBalance = createAsyncThunk<any, any>(
  "user/checkBalance",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const data: any = await checkIfBalanceIsEnoughApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const editUser = createAsyncThunk<any, WithCallback>(
  "user/editUser",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await editUserApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const imageUpload = createAsyncThunk<any, WithCallback>(
  "user/imageUpload",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await uploadImageApi(payload.image);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const followUser = createAsyncThunk<any, WithCallback>(
  "user/followUser",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await followUserApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const unfollowUser = createAsyncThunk<any, WithCallback>(
  "user/unfollowUser",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await unfollowUserApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getFollowers = createAsyncThunk<any, void>(
  "user/getFollowers",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await getFollowersApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getFollowing = createAsyncThunk<any, void>(
  "user/getFollowing",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await getFollowingApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const removeFollowers = createAsyncThunk<any, WithCallback>(
  "user/removeFollowers",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await removeFollowerApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getLeaderboard = createAsyncThunk<any, void>(
  "user/getLeaderboard",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await leaderBoardApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateTwitchID = createAsyncThunk<any, WithCallback>(
  "user/updateTwitchID",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updateTwitchUserApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateEpicID = createAsyncThunk<any, WithCallback>(
  "user/updateEpicID",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updateEpicUserApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateActivitionID = createAsyncThunk<any, WithCallback>(
  "user/updateActivitionID",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updateActivitionUserApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateEAID = createAsyncThunk<any, WithCallback>(
  "user/updateEAID",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updateEaUserApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateXboxID = createAsyncThunk<any, WithCallback>(
  "user/updateXboxID",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updateXboxUserApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updatePsID = createAsyncThunk<any, WithCallback>(
  "user/updatePsID",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updatePlayStationUserApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateStreetFighterID = createAsyncThunk<any, WithCallback>(
  "user/updateStreetFighterID",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updateStreetFighterUserApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateMLBID = createAsyncThunk<any, WithCallback>(
  "user/updateMLBID",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updateMlbUserApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const checkPassword = createAsyncThunk<any, WithCallback>(
  "user/checkPassword",
  async ({ payload, callback, errorLog }, { rejectWithValue }) => {
    try {
      const data: any = await checkPasswordApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      errorLog?.();
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const techSupport = createAsyncThunk<any, WithCallback>(
  "user/techSupport",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await techSupportApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteAccount = createAsyncThunk<any, WithCallback>(
  "user/deleteAccount",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await deleteAccountApi();
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getReferrals = createAsyncThunk<any, WithCallback>(
  "user/getReferrals",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getReferralApi();
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteFacebookAccount = createAsyncThunk<any, WithCallback>(
  "user/deleteFacebookAccount",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await deleteLinkedFacebookAccountApi();
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteGoogleAccount = createAsyncThunk<any, WithCallback>(
  "user/deleteGoogleAccount",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await deleteLinkedGoogleAccountApi();
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteAppleAccount = createAsyncThunk<any, WithCallback>(
  "user/deleteAppleAccount",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await deleteLinkedAppleAccountApi();
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateBattleId = createAsyncThunk<any, WithCallback>(
  "user/updateBattleId",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updateBattleIdApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateNintendoId = createAsyncThunk<any, WithCallback>(
  "user/updateNintendoId",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updateNintendoIdApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateSteamId = createAsyncThunk<any, WithCallback>(
  "user/updateSteamId",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updateSteamIdApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateOriginId = createAsyncThunk<any, WithCallback>(
  "user/updateOriginId",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updateOriginIdApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
