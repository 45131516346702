import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as CloseIcon } from "assets/close-button.svg";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import ImageHolder from "./ImagePlaceholder";
import SearchBar from "./Search";
import { searchUsers } from "store/matchMaking/actions";

interface UserModalProps {
  data: any[];
  header?: string;
  placeholder?: string;
  selected?: any[];
  buttonText?: string;
  removeText?: string;
  onPress?: (data: any) => void;
  closeModal: () => void;
}

const UsersModal: React.FC<UserModalProps> = ({
  data,
  header,
  placeholder,
  buttonText,
  removeText,
  selected,
  onPress,
  closeModal,
}) => {
  const dispatch: AppDispatch = useAppDispatch();

  const [value, setValue] = useState<any>("");
  const [searchResults, setSearchResults] = useState<any[]>(data);
  const [selectedData, setSelectedData] = useState<any[]>(
    selected ? selected : []
  );
  const user = useAppSelector((state: RootState) => state.user).user?._id;
  const filteredItems = useMemo(() => {
    if (!value) return data;
    return searchResults.filter((item) =>
      item.username?.toLowerCase().includes(value?.toLowerCase())
    );
  }, [searchResults, value]);

  const onPressUser = (id: any) => {
    const updatedSelectedData = [...selectedData, id];
    setSelectedData(updatedSelectedData);
    onPress?.(updatedSelectedData);
  };

  const removeElementById = (idToRemove: string) => {
    const updatedData = selectedData.filter((item) => item !== idToRemove);
    setSelectedData(updatedData);
    onPress?.(updatedData);
  };

  const renderUsers = (searchResults: any, index: number) => {
    const isInclude = selectedData?.includes(searchResults._id);
    const isHost = searchResults._id === user;
    return (
      <>
        {!isHost && (
          <div
            key={index}
            className="flex items-center justify-between p-4 rounded mb-4"
          >
            <div className="flex items-center gap-4">
              <ImageHolder
                uri={searchResults?.profilePicture}
                className="w-10 h-10 rounded-full"
              />
              <span className="text-white text-lg">
                {searchResults?.username}
              </span>
            </div>
            {!isInclude ? (
              <button
                className=" bg-primary text-black px-4 py-2 rounded-xl hover:bg-primaryActive"
                onClick={() => onPressUser(searchResults._id)}
              >
                {buttonText}
              </button>
            ) : (
              <button
                className=" bg-brightRed text-black px-4 py-2 rounded-xl hover:bg-brightRed"
                onClick={() => removeElementById(searchResults._id)}
              >
                {removeText}
              </button>
            )}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!value) return;
    dispatch(
      searchUsers({
        payload: { query: value, page: "1" },
        callback: (results) => {
          setSearchResults(results.users);
        },
      })
    );
  }, [value]);

  return (
    <div
      className="relative z-10"
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 transition-all backdrop-blur-sm">
        <div className="fixed inset-0 z-10 w-full overflow-y-auto">
          <div className="flex min-h-full justify-center px-2 py-12 text-center ">
            <div className="relative w-[95%] sm:w-[80%] min-h-[60vh] rounded-2xl bg-componentBackground bg-opacity-95 text-slate-100 text-left shadow-xl transition-all">
              <div className="px-5 py-4">
                <button
                  type="button"
                  className="rounded-md p-1 inline-flex items-center justify-center focus:outline-none absolute top-3 right-2"
                  onClick={closeModal}
                >
                  <span className="sr-only">Close menu</span>
                  <CloseIcon />
                </button>
                <span className="text-2xl text-white items-center font-normal">
                  {header}
                </span>
              </div>
              <div className="mx-5 text-white">
                <SearchBar
                  placeholder={placeholder}
                  searchIcon
                  onPress={(data: any) => setValue(data)}
                  value={value}
                />
              </div>
              <div className="flex-grow overflow-y-auto p-4">
                {filteredItems.length > 0 ? (
                  filteredItems?.map(renderUsers)
                ) : (
                  <span className="text-2xl text-white items-center font-normal">
                    You have no any friends
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersModal;
