import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch } from "store/index";
import { getPaypalHistory, getTransactionHistory } from "store/wallet/action";
import { Pagination, ThemeProvider, createTheme } from "@mui/material";
import { setPaypalHistoryPage, setTransactionHistoryPage } from "store/wallet/walletSlice";
import miscsStyles from "styles/miscs/miscs.module.scss";
import LoadingAnim from "components/Loader";
import CloseIcon from "assets/close-button.svg";
import BackIcon from "assets/arrow-right.svg";
import { ReactComponent as WithdrawMoneyIcon } from "assets/transactionHistory/money_withdraw_icon.svg";
import { ReactComponent as MoneyReceivedIcon } from "assets/transactionHistory/money_received_icon.svg";
import { ReactComponent as GiftReceivedIcon } from "assets/transactionHistory/gift_yellow.svg";
import { ReactComponent as MoneySentIcon } from "assets/transactionHistory/money_sent_icon.svg";
import { ReactComponent as TournamentMoneySentIcon } from "assets/transactionHistory/tournament_money_sent.svg";

enum transferType {
  MONEY_SEND = "MONEY_SEND",
  MONEY_RECEIVE = "MONEY_RECEIVE",
  MONEY_WITHDRAW = "WITHDRAW",
  MONEY_WIN = "MONEY_WIN",
  MONEY_DRAW = "MONEY_DRAW",
  MATCH_PAYMENT = "MATCH_PAYMENT",
  STAKE_PAYMENT = "STAKE_PAYMENT",
  STAKE_REFUND = "STAKE_REFUND",
  TOURNAMENT_PAYMENT = "TOURNAMENT_PAYMENT",
  DONATION_SEND = "DONATION_SEND",
  DONATION_RECEIVE = "DONATION_RECEIVE",
  WALLET_TOPUP = "WALLET_TOPUP",
  ADMIN_PAYOUT = "ADMIN_PAYOUT",
  USER_REFUND = "USER_REFUND",
  CHALLENGE_PAYMENT = "CHALLENGE_PAYMENT",
}

export default function TransactionHistoryScreen({ }) {
  const navigate = useNavigate();
  const { from } = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const {
    totalTransactionPages,
    transactionHistoryPage,
    transactionHistory,
    paypalHistory,
    totalPaypalHistoryPages,
    paypalHistoryPage,
    loader,
  } = useAppSelector((state) => state.wallet);
  const paypal = from === 'paypal';

  useEffect(() => {
    dispatch(getTransactionHistory(transactionHistoryPage));
    dispatch(getPaypalHistory(paypalHistoryPage));
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const formatTransactionDate = (date: string) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    const hour = d.getHours();
    const minute = d.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hour}:${minute}`;
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(setTransactionHistoryPage(value));
    dispatch(getTransactionHistory(value));
    dispatch(setPaypalHistoryPage(value));
    dispatch(getPaypalHistory(value));
  };

  const onCancel = () => {
    navigate("/");
  };
  const onBack = () => {
    navigate("/wallet/main");
  };
  const renderAllTransactions = (item: any, index: number) => {
    const iconMap: Record<transferType, React.ReactNode> | any = {
      [transferType.MONEY_RECEIVE]: <MoneyReceivedIcon />,
      [transferType.MONEY_SEND]: <MoneySentIcon />,
      [transferType.MONEY_WITHDRAW]: <WithdrawMoneyIcon />,
      [transferType.MONEY_WIN]: <GiftReceivedIcon />,
      [transferType.MATCH_PAYMENT]: <MoneySentIcon />,
      [transferType.STAKE_PAYMENT]: <MoneySentIcon />,
      [transferType.STAKE_REFUND]: <MoneyReceivedIcon />,
      [transferType.TOURNAMENT_PAYMENT]: <TournamentMoneySentIcon />,
      [transferType.WALLET_TOPUP]: <MoneyReceivedIcon />,
      [transferType.MONEY_DRAW]: <GiftReceivedIcon />,
      [transferType.CHALLENGE_PAYMENT]: <MoneySentIcon />,
      [transferType.DONATION_SEND]: <MoneySentIcon />,
      [transferType.DONATION_RECEIVE]: <GiftReceivedIcon />,
      [transferType.ADMIN_PAYOUT]: <MoneySentIcon />,
      [transferType.USER_REFUND]: <GiftReceivedIcon />,

    };

    const getTransactionLabel = (type: transferType): string => {
      switch (type) {
        case transferType.MONEY_RECEIVE:
          return "Money Received";
        case transferType.MONEY_SEND:
          return "Money Sent";
        case transferType.MONEY_WITHDRAW:
          return "Withdraw Request";
        case transferType.MONEY_WIN:
          return "Win Received";
        case transferType.MATCH_PAYMENT:
          return "Match Payment sent";
        case transferType.STAKE_PAYMENT:
          return "Stake Payment sent";
        case transferType.STAKE_REFUND:
          return "Stake Payment received";
        case transferType.TOURNAMENT_PAYMENT:
          return "Tournament Payment sent";
        case transferType.WALLET_TOPUP:
          return "Wallet top up Received";
        case transferType.MONEY_DRAW:
          return "Challenge Draw Payment Received";
        case transferType.CHALLENGE_PAYMENT:
          return "Challenge Payment sent";

        case transferType.DONATION_SEND:
          return "Donation sent";
        case transferType.DONATION_RECEIVE:
          return "Donation Received";
        case transferType.ADMIN_PAYOUT:
          return "Payout Received";
        case transferType.USER_REFUND:
          return "Refund Received";
        default:
          return "";
      }
    };

    const transferTypeLabel = getTransactionLabel(item.transferType);

    return (
      <div className="bg-modalFade rounded-lg flex flex-row items-center md:gap-16 md:p-4 p-1 max-md:text-[12px] gap-2 max-md:w-full">
        {iconMap[item.transferType]}
        <div className="flex flex-col md:w-[75%] w-[90%]">
          <span className="text-white max-md:text-[12px]">
            {transferTypeLabel}
          </span>
          <span className="text-placeholder max-md:text-[12px]">
            Ref: {item.transactionId}
          </span>
          <span className="text-placeholder max-md:text-[12px]">
            {item.transferType === transferType.MONEY_WITHDRAW
              ? `${formatTransactionDate(item.date)} \n${item.status}`
              : `${formatTransactionDate(item.date)}`}
          </span>
        </div>
        <span className="text-primary md:w-24 w-16 text-[18px] max-md:text-[12px]">
          {item.transferType === transferType.MONEY_RECEIVE ||
            item.transferType === transferType.MONEY_WIN ||
            item.transferType === transferType.WALLET_TOPUP ||
            item.transferType === transferType.MONEY_DRAW ||
            item.transferType === transferType.DONATION_RECEIVE ||
            item.transferType === transferType.ADMIN_PAYOUT ||
            item.transferType === transferType.USER_REFUND
            ? `+ $${item.amount.toFixed(2)}`
            : `- $${item.amount.toFixed(2)}`}
        </span>
      </div>
    );
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="md:container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-full  max-md:h-[85vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <div className="flex flex-row items-center gap-5">
                  <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                    <img src={BackIcon} alt="Close" />
                  </button>
                  <span className="text-3xl text-white items-center font-normal max-md:text-xl">
                    {paypal ? "Paypal History" : 'Transaction History'}
                  </span>
                </div>

              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div
            className={`flex flex-col mt-4 h-[calc(80vh-120px)] max-md:h-full gap-y-4 overflow-hidden hover:[overflow:overlay] ${miscsStyles.beautifulScrollbar}`}
          >
            {paypal ? paypalHistory && paypalHistory.map(renderAllTransactions) : transactionHistory &&
              transactionHistory.map(renderAllTransactions)}
          </div>
        </div>
        <div className="flex justify-center md:absolute bottom-[30px] left-0 right-0">
          <ThemeProvider theme={darkTheme}>
            <Pagination
              count={paypal ? totalPaypalHistoryPages : totalTransactionPages}
              variant="outlined"
              shape="rounded"
              color="standard"
              onChange={handlePageChange}
            />
          </ThemeProvider>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
}
