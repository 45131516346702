import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import ImageHolder from "components/ImagePlaceholder";
import miscsStyles from "styles/components/notification.module.scss";

import { ReactComponent as Confirm } from "assets/payment_success.svg";
import { ReactComponent as Cancel } from "assets/payment_unsuccess.svg";
import { ReactComponent as Edit } from "assets/editPencil.svg";
import { ReactComponent as Google } from "assets/loginAndSecurity/googleAcc.svg";
import { ReactComponent as Apple } from "assets/loginAndSecurity/apple_acc.svg";
import { ReactComponent as Facebook } from "assets/loginAndSecurity/facebookAcc.svg";
import { ReactComponent as Link } from "assets/loginAndSecurity/link.svg";
import { ReactComponent as Unlink } from "assets/loginAndSecurity/unlink.svg";
import { deleteAppleAccount, deleteFacebookAccount, deleteGoogleAccount, editUser, getUser, imageUpload } from "store/user/action";
import {
  emailRegex,
  nameWithoutPureNumeric,
  phoneNoRegex,
} from "constant/validations";
import ProfilePicModal from "components/ProfilePicModal";
import LoadingAnim from "components/Loader";
import PasswordChecker from "components/PasswordCheckBox";

export default function EditProfileScreen({ }) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const userToken = useAppSelector((state: RootState) => state.auth).userToken;

  let duelHistory = user.user?.duelHistory;
  const { from } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [about, setAbout] = useState("");
  const [password, setPassword] = useState("");
  const [editName, setEditName] = React.useState(false);
  const [editPhone, setEditPhone] = React.useState(false);
  const [editEmail, setEditEmail] = React.useState(false);
  const [editAbout, setEditAbout] = React.useState(false);
  const [editPassword, setEditPassword] = React.useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const avatarUrl = useRef(user?.user?.profilePicture);
  const onCancel = () => {
    switch (from) {
      case "password":
        return navigate("/");
      default:
        return navigate("/profile");
    }
  };

  useEffect(() => {
    if (duelHistory) duelHistory = [...duelHistory].reverse();
    dispatch(getUser());
  }, []);

  useEffect(() => {
    if (user.user) {
      setName(user.user.username || "");
      setEmail(user.user.email || "");
      setPhone(user.user.phoneNumber || "");
      setAbout(user.user.about || "");
    }
  }, [user.user]);

  const updateAvatar = (imgSrc: any) => {
    avatarUrl.current = imgSrc;
    dispatch(
      imageUpload({
        payload: { image: imgSrc },
        callback: () => dispatch(getUser()),
      })
    );
  };

  const handleNameValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handlePhoneValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const handleEmailValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleAboutValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAbout(e.target.value);
  };

  const onPressConfirmName = () => {
    if (!nameWithoutPureNumeric.test(name)) {
      alert("Invalid user name!");
      return;
    }
    dispatch(
      editUser({
        payload: { username: name },
        callback: () => dispatch(getUser()),
      })
    );
    setEditName(false);
  };

  const onPressConfirmPhone = () => {
    if (!phoneNoRegex.test(phone)) {
      alert("Invalid phone number!");
      return;
    }
    dispatch(
      editUser({
        payload: { phoneNumber: phone },
        callback: () => dispatch(getUser()),
      })
    );
    setEditPhone(false);
  };

  const onPressConfirmEmail = () => {
    if (!emailRegex.test(email)) {
      alert("Invalid email!");
      return;
    }
    dispatch(
      editUser({
        payload: { email: email },
        callback: () => dispatch(getUser()),
      })
    );
    setEditEmail(false);
  };

  const onPressConfirmAbout = () => {
    dispatch(
      editUser({
        payload: { about: about },
        callback: () => dispatch(getUser()),
      })
    );
    setEditAbout(false);
  };



  const handleAppleLogin = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}/auth/apple/add?auth_token=${userToken}`, "_self");
  };
  const handleGoogleLogin = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}/auth/google/add?auth_token=${userToken}`, "_self");
  };
  const handleFaceBookLogin = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}/auth/facebook/add?auth_token=${userToken}`, "_self");
  };

  const handleDeleteApple = () => {
    dispatch(
      deleteAppleAccount({
        payload: {},
        callback: () => dispatch(getUser()),
      }))
  };
  const handleDeleteGoogle = () => {
    dispatch(
      deleteGoogleAccount({
        payload: {},
        callback: () => dispatch(getUser()),
      }))
  };
  const handleDeleteFacebook = () => {
    dispatch(
      deleteFacebookAccount({
        payload: {},
        callback: () => dispatch(getUser()),
      }))
  };
  return (
    <div
      className="mx-auto w-full flex-col bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] 
    bg-right bg-no-repeat flex items-center  h-screen "
    >
      <div className="container flex flex-col gap-y-[20px] md:my-16  max-md:p-3 items-center justify-between ">
        <div
          className={`  backdrop-blur-sm con rounded-[34px] w-[calc(100%-176px)] 
        h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh] ${miscsStyles.beautifulScrollbar}`}
        >
          <div className="w-full  flex items-center justify-between absolute top-10 ">
            {from === "password" && (
              <span className="md:text-3xl text-xl text-white items-center mx-10 font-normal">
                Login and Security
              </span>
            )}
            <button
              className=" w-[25px] h-[25px] absolute right-10"
              onClick={onCancel}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div
            className={` flex flex-col h-full max-md:h-full max-md:w-full ${from === "password" ? "mt-10" : ""
              }`}
          >
            <div className="flex md:flex-row max-md:flex-col md:justify-evenly  w-full h-full">
              <div className="flex flex-col items-center gap-y-4 mt-10 max-md:mt-6 p-5">
                {from !== "password" && (
                  <div className="relative">
                    <ImageHolder
                      uri={avatarUrl.current}
                      width={100}
                      className="flex w-[150px] h-[150px] rounded-full max-md:w-[15vh] max-md:h-[15vh] border-2 border-primaryActive"
                    />
                    <button
                      className="absolute -bottom-3 left-0 right-0 m-auto w-fit p-[.35rem] rounded-full bg-primary hover:bg-primaryActive border border-gray-600"
                      title="Change photo"
                      onClick={() => setModalOpen(true)}
                    >
                      <Edit />
                    </button>
                  </div>
                )}
                <div className="flex flex-col gap-3 md:gap-1 w-[463px] max-md:w-[263px] mt-3">
                  <span className="md:text-3xl text-xl text-white items-center my-5 font-normal">
                    Account Information
                  </span>
                  <div>
                    <span className="text-white text-[18px] mt-3">
                      User name
                    </span>
                    <div className="flex mt-5 h-16 w-full max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4">
                      <input
                        className="bg-borderColor align-center gap-5 h-16 rounded-lg p-2 w-full outline-none text-left text-white"
                        value={name}
                        onChange={handleNameValue}
                        disabled={!editName}
                      />
                      {!editName && (
                        <button
                          className=" bg-primary hover:bg-primaryActive rounded-xl p-1 w-36 flex flex-row justify-center items-center gap-2 h-10"
                          onClick={() => setEditName(true)}
                        >
                          <Edit />
                          <span>Edit</span>
                        </button>
                      )}
                      {editName && (
                        <div className="flex flex-row gap-3">
                          <button onClick={onPressConfirmName}>
                            <Confirm width={30} height={30} />
                          </button>
                          <button
                            onClick={() => {
                              setEditName(false);
                              setName(user.user?.username || "");
                            }}
                          >
                            <Cancel width={30} height={30} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-3">
                    <span className="text-white text-[18px] mt-3">
                      Phone Number
                    </span>
                    <div className="flex mt-5 h-16 w-full max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4">
                      <input
                        className=" bg-borderColor align-center gap-5 h-16 rounded-lg p-2 w-full outline-none text-left text-white"
                        placeholder={
                          editPhone
                            ? "New Phone Number"
                            : user?.user?.phoneNumber
                        }
                        disabled={!editPhone}
                        value={phone}
                        onChange={handlePhoneValue}
                      />
                      {!editPhone && (
                        <button
                          className=" bg-primary hover:bg-primaryActive rounded-xl p-1 w-36 flex flex-row justify-center items-center gap-2 h-10"
                          onClick={() => {
                            setEditPhone(true);
                          }}
                        >
                          <Edit />
                          <span>Edit</span>
                        </button>
                      )}
                      {editPhone && (
                        <div className="flex flex-row gap-3">
                          <button onClick={onPressConfirmPhone}>
                            <Confirm width={30} height={30} />
                          </button>
                          <button
                            onClick={() => {
                              setEditPhone(false);
                              setPhone(user.user?.phoneNumber || "");
                            }}
                          >
                            <Cancel width={30} height={30} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-3">
                    <span className="text-white text-[18px] mt-3">
                      Email Address
                    </span>
                    <div className="flex mt-5 h-16 w-full max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4">
                      <input
                        className=" bg-borderColor align-center gap-5 h-16 rounded-lg p-2 w-full outline-none text-left text-white"
                        placeholder={
                          editEmail ? "New Email Address" : user?.user?.email
                        }
                        disabled={!editEmail}
                        value={email}
                        onChange={handleEmailValue}
                      />
                      {!editEmail && (
                        <button
                          className="bg-primary hover:bg-primaryActive rounded-xl p-1 w-36 flex flex-row justify-center items-center gap-2 h-10"
                          onClick={() => {
                            setEditEmail(true);
                          }}
                        >
                          <Edit />
                          <span>Edit</span>
                        </button>
                      )}
                      {editEmail && (
                        <div className="flex flex-row gap-3">
                          <button onClick={onPressConfirmEmail}>
                            <Confirm width={30} height={30} />
                          </button>
                          <button
                            onClick={() => {
                              setEditEmail(false);
                              setEmail(user.user?.email || "");
                            }}
                          >
                            <Cancel width={30} height={30} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {from !== "password" && (
                    <div className="mt-3">
                      <span className="text-white text-[18px] mt-3">About</span>
                      <div className="flex mt-5 h-16 w-full max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4">
                        <input
                          className=" bg-borderColor align-center gap-5 h-16 rounded-lg p-2 w-full outline-none text-left text-white"
                          placeholder={user?.user?.about}
                          disabled={!editAbout}
                          value={about}
                          onChange={handleAboutValue}
                        />
                        {!editAbout && (
                          <button
                            className=" bg-primary hover:bg-primaryActive rounded-xl p-1 w-36 flex flex-row justify-center items-center gap-2 h-10"
                            onClick={() => {
                              setEditAbout(true);
                            }}
                          >
                            <Edit />
                            <span>Edit</span>
                          </button>
                        )}
                        {editAbout && (
                          <div className="flex flex-row gap-3">
                            <button onClick={onPressConfirmAbout}>
                              <Confirm width={30} height={30} />
                            </button>
                            <button
                              onClick={() => {
                                setEditAbout(false);
                                setAbout(user.user?.about || "");
                              }}
                            >
                              <Cancel width={30} height={30} />
                            </button>
                          </div>
                        )}
                        {modalOpen && (
                          <ProfilePicModal
                            updateAvatar={updateAvatar}
                            closeModal={() => setModalOpen(false)}
                            type="PROFILE"
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {from === "password" && (
                    <div className="mt-3">
                      <span className="text-white text-[18px] mt-3">
                        Password
                      </span>
                      <div className="flex mt-5 h-16 w-full max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4">
                        <input
                          className=" bg-borderColor align-center gap-5 h-16 rounded-lg p-2 w-full outline-none text-left text-white"
                          placeholder="********"
                          disabled
                          value={password}
                        />
                        {!editPassword && (
                          <button
                            className=" bg-primary hover:bg-primaryActive rounded-xl p-1 w-36 flex flex-row justify-center items-center gap-2 h-10"
                            onClick={() => {
                              setEditPassword(true);
                            }}
                          >
                            <Edit />
                            <span>Edit</span>
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  <span className="md:text-3xl text-xl text-white items-center mt-10 mb-5 font-normal">
                    Linked Accounts
                  </span>
                  <div className="flex  h-16 w-full max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4">
                    <div className="flex flex-row items-center gap-4">
                      <Apple />
                      <div className="flex flex-col justify-center items-start">
                        <span className="text-placeholder md:text-sm text-[10px]">
                          Apple
                        </span>
                        <span className="text-white md:text-lg text-[10px]">
                          {user.user?.appleId ? user.user?.appleMail || user.user?.appleId : "No linked account yet"}
                        </span>
                      </div>
                    </div>
                    <button
                      onClick={user.user?.appleId ? handleDeleteApple : handleAppleLogin}
                      className=" bg-primary hover:bg-primaryActive rounded-xl p-1 w-24 flex flex-row justify-center items-center gap-2 h-10"
                    >
                      {user.user?.appleId ? <Unlink /> : <Link />}
                      <span>{user.user?.appleId ? "Unlink" : 'link'}</span>
                    </button>
                  </div>
                  <div className="flex mt-5 h-16 w-full max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4">
                    <div className="flex flex-row items-center gap-4">
                      <Google />
                      <div className="flex flex-col justify-center items-start">
                        <span className="text-placeholder md:text-sm text-[10px]">
                          Google
                        </span>
                        <span className="text-white md:text-lg text-[10px]">
                          {user.user?.googleId ? user.user?.googleMail || user.user?.googleId : "No linked account yet"}
                        </span>
                      </div>
                    </div>
                    <button
                      onClick={user.user?.googleId ? handleDeleteGoogle : handleGoogleLogin}
                      className=" bg-primary hover:bg-primaryActive rounded-xl p-1 w-24 flex flex-row justify-center items-center gap-2 h-10"
                    >
                      {user.user?.googleId ? <Unlink /> : <Link />}
                      <span>{user.user?.googleId ? "Unlink" : 'link'}</span>
                    </button>
                  </div>
                  {/* <div className="flex mt-5 h-16 w-full max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4">
                    <div className="flex flex-row items-center gap-4">
                      <Facebook />
                      <div className="flex flex-col justify-center items-start">
                        <span className="text-placeholder md:text-sm text-[10px]">
                          Facebook
                        </span>
                        <span className="text-white md:text-lg text-[10px]">
                          {user.user?.facebookId ? user.user?.facebookUsername || user.user?.facebookId : "No linked account yet"}
                        </span>
                      </div>
                    </div>
                    <button
                      onClick={user.user?.facebookId ? handleDeleteFacebook : handleFaceBookLogin}
                      className=" bg-primary hover:bg-primaryActive rounded-xl p-1 w-24 flex flex-row justify-center items-center gap-2 h-10"
                    >
                      {user.user?.facebookId ? <Unlink /> : <Link />}
                      <span>{user.user?.facebookId ? "Unlink" : 'link'}</span>
                    </button>
                  </div> */}
                  {/* TODO */}
                </div>
              </div>
            </div>
            {editPassword && (
              <PasswordChecker
                visible={editPassword}
                onClose={() => setEditPassword(false)}
                type="EDIT_PASSWORD"
              />
            )}
          </div>
        </div>
      </div>
      <LoadingAnim loading={user.loader} />
    </div>
  );
}
