import { createSlice } from "@reduxjs/toolkit";

import { signOut } from "store/auth/actions";
import {
  createStream,
  endStream,
  getLiveStreams,
  streamForExternal,
} from "./actions";

interface StreamType {
  IN_APP: "IN_APP";
  EXTERNAL: "EXTERNAL";
  CASTING: "CASTING";
  NOT_STREAM: "NOT_STREAM";
  TWITCH: "TWITCH";
}
interface streamingPlayers {
  stream: {
    inApp: {
      streamToken: string;
      streamRoomId: string;
    };
    twitch: {
      streamTitle: string;
      streamerId: string;
      thumbnail: string;
    };
    external: {
      upstreamKey: string;
      upstreamUrl: string;
    };
    isStreaming: boolean;
    streamType: StreamType | string;
  };
  _id: string;
  username: string;
  profilePicture?: string;
  participants: number;
  tags?: string[];
  isHost: boolean;
  createdAt: Date;
}
interface StreamingSlice {
  streamMethod: string | null;
  loader: boolean;
  err: boolean;
  streamToken: string;
  streamRoomId: string;
  streamKey: string;
  upstreamUrl: string;
  liveStreams: any[];

  defaultStream: streamingPlayers | null;
  streamTitle: string;
  streamDescription: string;
  streamTags: [];
  streamCategory: string;
  thumbnail: string;

  otherStreamKey: any[];
  otherStreamUrl: any[];
}

const initialState: StreamingSlice = {
  streamMethod: null,
  loader: false,
  err: false,
  streamToken: "",
  streamRoomId: "",
  streamKey: "",
  upstreamUrl: "",
  liveStreams: [],
  defaultStream: {
    stream: {
      inApp: {
        streamToken: "",
        streamRoomId: "",
      },
      twitch: {
        streamTitle: "",
        streamerId: "",
        thumbnail: "",
      },
      external: {
        upstreamKey: "",
        upstreamUrl: "",
      },
      isStreaming: false,
      streamType: "",
    },
    _id: "",
    username: "",
    profilePicture: "",
    participants: 0,
    tags: [],
    isHost: false,
    createdAt: new Date(),
  },
  streamTitle: "",
  streamDescription: "",
  streamTags: [],
  streamCategory: "",
  thumbnail: "",
  otherStreamKey: [],
  otherStreamUrl: [],
};

export const streamingSlice = createSlice({
  name: "streaming",
  initialState,
  reducers: {
    setStreamingMethod: (state, action) => {
      state.streamMethod = action.payload;
    },
    resetStream: (state) => {
      state.streamToken = "";
      state.streamRoomId = "";
      state.streamKey = "";
      state.upstreamUrl = "";

      state.streamTitle = "";
      state.streamDescription = "";
      state.streamTags = [];
      state.streamCategory = "";
      state.thumbnail = "";
    },
    setDefaultStream: (state, action) => {
      if (state.defaultStream) {
        state.defaultStream.stream.inApp.streamRoomId =
          action.payload.streamRoomId;
        state.defaultStream.stream.inApp.streamToken =
          action.payload.streamToken;
        state.defaultStream.stream.twitch.streamTitle =
          action.payload.streamTitle;
        state.defaultStream.stream.twitch.streamerId =
          action.payload.streamerId;
        state.defaultStream.stream.twitch.thumbnail = action.payload.thumbnail;
        state.defaultStream.stream.external.upstreamKey =
          action.payload.upstreamKey;
        state.defaultStream.stream.external.upstreamUrl =
          action.payload.upstreamUrl;
        state.defaultStream.stream.isStreaming = action.payload.isStreaming;
        state.defaultStream.stream.streamType = action.payload.streamType;
        state.defaultStream._id = action.payload._id;
        state.defaultStream.username = action.payload.username;
        state.defaultStream.profilePicture = action.payload.profilePicture;
        state.defaultStream.tags = action.payload.tags;
        state.defaultStream.isHost = action.payload?.isHost;
        state.defaultStream.createdAt = action.payload?.createdAt;
        state.streamCategory = action?.payload?.category;
        state.streamTitle = action?.payload?.streamTitle;
      }
    },
    setParticipantsCount: (state, action) => {
      if (state.defaultStream) {
        state.defaultStream.participants = action.payload;
      }
    },
    setStreamTitle: (state, action) => {
      state.streamTitle = action?.payload;
    },
    setStreamDescription: (state, action) => {
      state.streamDescription = action?.payload;
    },
    setStreamTags: (state, action) => {
      state.streamTags = action?.payload;
    },
    setStreamCategory: (state, action) => {
      state.streamCategory = action?.payload;
    },
    setStreamThumbnail: (state, action) => {
      state.thumbnail = action?.payload;
    },
    setStreamToMorePlaces: (state, action) => {
      state.otherStreamKey = action?.payload.otherStreamKey;
      state.otherStreamUrl = action?.payload.otherStreamUrl;
    },
    resetStreamingMethod: (state) => {
      state.streamMethod = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
    builder.addCase(createStream.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createStream.fulfilled, (state, action) => {
      state.loader = false;
      state.streamRoomId = action.payload?.streamRoomId;
      state.streamToken = action.payload?.streamToken;
    });
    builder.addCase(createStream.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(streamForExternal.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(streamForExternal.fulfilled, (state, action) => {
      state.loader = false;
      state.streamKey = action.payload?.streamKey;
      state.upstreamUrl = action.payload?.upstreamUrl;
    });
    builder.addCase(streamForExternal.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(getLiveStreams.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getLiveStreams.fulfilled, (state, action) => {
      // state.liveStreams = action?.payload.lives;
      state.liveStreams = action?.payload.lives.sort(
        (a: { createdAt: number }, b: { createdAt: number }) =>
          b.createdAt - a.createdAt
      );
      state.loader = false;
    });
    builder.addCase(getLiveStreams.rejected, (state) => {
      state.err = true;
      state.loader = false;
    });

    builder.addCase(endStream.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(endStream.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(endStream.rejected, (state) => {
      state.err = true;
      state.loader = false;
    });
  },
});

export default streamingSlice.reducer;
export const {
  setStreamingMethod,
  resetStream,
  setStreamTitle,
  setStreamDescription,
  setStreamTags,
  setStreamCategory,
  setStreamThumbnail,
  setDefaultStream,
  setStreamToMorePlaces,
  setParticipantsCount,
  resetStreamingMethod,
} = streamingSlice.actions;
