import React from "react";
import thumbnailTemp from "assets/temp/temp.png";
import { useNavigate } from "react-router-dom";

const MatchCard = ({
  id,
  name,
  thumbnail,
  selected,
  item,
  onClick,
}: {
  id?: string;
  name?: string;
  selected?: boolean;
  thumbnail?: string;
  item?: any;
  onClick?: (matchItem: any) => void;
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!onClick) return;
    onClick?.(item);
  };

  return (
    <button
      className={`flex flex-col w-[20vh] h-[15vh] min-w-[182px] min-h-[136px] p-[5px] rounded-[12px] bg-borderColor items-center justify-between transform transition-transform hover:scale-105 hover:shadow-lg outline-none focus:ring focus:ring-[#FFD482] ${selected ? "border-2 border-[#FFD482]" : ""
        }`}
      onClick={handleClick}
    >
      <div
        style={{ backgroundImage: `url('${thumbnail}')` }}
        className="w-full h-full bg-cover rounded-[8px]"
      />
      <span className="text-white text-[12px] font-normal">{name}</span>
      {item?.streamRequired && <span className="text-primary text-[10px] font-normal">Stream Required</span>}
    </button>
  );
};

export default MatchCard;
