import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setStreamingMethod } from "store/streaming/streamingSlice";
import { createDcStream } from "store/streaming/actions";
import { streamTypes } from "types/constants";
import { RootState } from "store/index";
import { getStream } from "store/matchMaking/actions";

import ButtonDc from "components/Button";
import CloseIcon from "assets/close-button.svg";

export default function StreamingToolScreen({ }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { matchId } = useParams();
  const userId = useAppSelector((state: RootState) => state.user).user?._id;

  const handleClose = () => {
    navigate(`/streaming/${matchId}/platform`);
  };

  const handleStreamExt = () => {
    dispatch(
      createDcStream({
        payload: { streamType: "EXTERNAL" },
        callback: () => {
          dispatch(
            getStream({
              payload: userId,
              // callback: (data: any) => setSelectedStreamer(data),
              // rejectHandler: () => setSelectedStreamer(""),
            })
          );
          dispatch(setStreamingMethod(streamTypes.STREAM_WITH_EXTERNAL));
          navigate(`/duels/lobby/${matchId}/${"external"}`);
        },
      })
    );
  };

  const handleStreamDC = () => {
    navigate(`/streaming/${matchId}/more-places`);
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat gap-[7em] flex flex-col items-center  h-screen ">
      <div className="w-full h-screen bg-modalFade  fixed z-10 ">
        <div
          className="w-3/5 md:h-4/5 h-screen p-[30px] bg-cardBackground md:rounded-[34px] shadow-lg absolute top-1/2 
          left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-[50px] max-md:w-full overflow-y-auto"
        >
          <div className="flex flex-col gap-[1em] mt-5">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <span className="text-2xl text-white items-center font-normal">
                Start Streaming
              </span>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={handleClose}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-evenly items-center desktop:gap-20 desktop:mt-[5vh] gap-10">
            <span className=" text-placeholder text-xl font-normal">
              Choose Streaming Method
            </span>
            <div className="flex flex-col gap-8 items-center">
              <div className=" bg-borderColor rounded-xl p-4 w-full flex desktop:flex-row flex-col gap-8 max-md:w-4/5 justify-between ">
                <div className="flex flex-col desktop:w-3/4 gap-2">
                  <span className="text-white font-bold max-md:text-center">
                    Use Built-in Streaming Feature
                  </span>
                  <span className="text-white max-md:text-justify">
                    Stream directly from the app with our built-in tools and
                    features.
                  </span>
                </div>
                <ButtonDc
                  text={"Continue"}
                  action={handleStreamDC}
                  type="primary"
                  short
                />
              </div>
              <div className=" bg-borderColor rounded-xl p-4 flex desktop:flex-row flex-col gap-8 max-md:w-4/5 justify-between">
                <div className="flex flex-col desktop:w-3/4 gap-2">
                  <span className="text-white font-bold max-md:text-center">
                    Use External Streaming Software
                  </span>
                  <span className="text-white max-md:text-justify">
                    Stream using your preferred external software such as OBS or
                    Streamlabs.
                  </span>
                </div>
                <ButtonDc
                  text={"Continue"}
                  action={handleStreamExt}
                  type="primary"
                  short
                  disabled //TODO
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
