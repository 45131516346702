import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { playerTypes } from "store/currentMatch/currentMatchSlice";
import { matchStatuses, streamTypes } from "types/constants";
import {
  resetStream,
  setDefaultStream,
  setStreamingMethod,
} from "store/streaming/streamingSlice";
import { getStream } from "store/matchMaking/actions";
import {
  endMatch,
  getCurrentMatch,
} from "store/currentMatch/currentMatchActions";

import { ReactComponent as CommentsIcon } from "assets/comments_icon.svg";
import { ReactComponent as Clipboard } from "assets/clipboard.svg";
import { handleMatchCommentsNotificationCount } from "utils/noitificationHandler";
import defaultImage from "assets/default_profile_yellow.png";
import Refresh from "assets/refresh_icon.svg";

import MatchChatModal from "components/MatchChatModal";
import LoadingAnim from "components/Loader";
import ButtonDc from "components/Button";
import StreamerView from "components/StreamerView";

const DuelEngagedScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { matchId } = useParams();
  const { loading } = useAppSelector((state) => state.currentMatch);
  const { loader } = useAppSelector((state) => state.matchMaking);
  const { selectedDuelPath } = useAppSelector((state) => state.matchMaking);
  const { streamKey, upstreamUrl, streamMethod } =
    useAppSelector((state: RootState) => state.streaming);
  const currentMatch = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;
  const { matchCommentsNotificationCount } = useAppSelector((state: RootState) => state.notification);

  const fcmData = useAppSelector((state) => state.fcm);
  const userId = useAppSelector((state: RootState) => state.user).user?._id;
  const players = currentMatch?.players;
  const isUserHost = currentMatch.host?._id === userId;
  const rounds = currentMatch?.rounds;
  const cubeWager = currentMatch?.cubeWager;
  const isTournament =
    selectedDuelPath === "CREATE_TOURNAMENT" ||
    selectedDuelPath === "OPPONENT_JOIN_MATCH" ||
    selectedDuelPath === "OPPONENT_JOIN";
  const startMatchButton = isUserHost || isTournament;
  const [selectedPlayer, setSelectedPlayer] = useState<any>(null);
  const [selectedStreamer, setSelectedStreamer] = useState<any>();
  const [modalOpen, setModalOpen] = useState(false);

  const isStreamerHost = selectedPlayer?._id === userId;

  const { defaultStream } = useAppSelector(
    (state: RootState) => state.streaming
  );
  const teamOne = currentMatch.team1
    .map((playerId: string) =>
      players.find((player: playerTypes) => player._id === playerId)
    )
    .filter((player) => player !== undefined);

  const teamTwo = currentMatch.team2
    .map((playerId: string) =>
      players.find((player: playerTypes) => player._id === playerId)
    )
    .filter((player) => player !== undefined);

  const handleCommentsButton = (chat: any) => {
    handleMatchCommentsNotificationCount(-1);
    setModalOpen(true);
  };
  const formatThumbnail = (url: any) => {
    if (!url) return "";
    return url.replace("{width}", "400").replace("{height}", "300");
  };

  const handleEndMatch = () => {
    navigate(`/duels/vote/${matchId}/LOBBY`);
    const callback = () => {
      //TODO navigate to vote screen
    };
    dispatch(endMatch({ matchId, callback }));
    dispatch(resetStream());
  };

  const twitchStream = (player: any) => {
    if (streamMethod === streamTypes.TWITCH)
      dispatch(
        getStream({
          payload: player?._id,
          callback: (data: any) => setSelectedStreamer(data),
          rejectHandler: () => setSelectedStreamer(""),
        })
      );
  };
  const handleStreamMethod = (type: any) => {
    switch (type) {
      case "CASTING":
        dispatch(setStreamingMethod(streamTypes.STREAM_WITH_DUELCUBE));
        break;
      case "EXTERNAL":
        dispatch(setStreamingMethod(streamTypes.STREAM_WITH_EXTERNAL));
        break;
      case "IN_APP":
        dispatch(setStreamingMethod(streamTypes.STREAM_WITH_DUELCUBE));
        break;
      case "TWITCH":
        dispatch(setStreamingMethod(streamTypes.TWITCH));
        break;
      default:
        dispatch(setStreamingMethod(streamTypes.NOT_STREAM));
        break;
    }
  };
  const handlePlayerPress = (player: any) => {
    twitchStream(player);
    setSelectedPlayer(player);
    dispatch(
      getStream({
        payload: player?._id,
        callback: (res: any) => {
          handleStreamMethod(
            res.twitch ? "TWITCH" : res?.streamRoomId ? "IN_APP" : "EXTERNAL"
          );
          dispatch(
            setDefaultStream({
              streamRoomId: res?.streamRoomId,
              streamToken: res?.streamToken,
              streamTitle: res.streamTitle,
              streamerId: res.streamerId,
              thumbnail: res.thumbnail,
              upstreamKey: "", //TODO
              upstreamUrl: "", //TODO
              isStreaming: true,
              streamType: res?.streamType,
              _id: player?._id,
              username: player?.username,
              isHost: isStreamerHost,
            })
          );
        },
        rejectHandler: () =>
          dispatch(setStreamingMethod(streamTypes.NOT_STREAM)),
      })
    );
  };
  const renderTeam = (team: any[]) => {
    const columns = Math.ceil(team.length / 2); // Divide players into 2 columns
    const splitTeam = [];
    for (let i = 0; i < team.length; i += columns) {
      splitTeam.push(team.slice(i, i + columns));
    }

    return (
      <div className="flex justify-center gap-4">
        {splitTeam.map((column, columnIndex) => (
          <div key={columnIndex} className="flex flex-col items-center gap-2">
            {column.map((player: any) => (
              <div
                className={`bg-borderColor flex justify-center items-center w-[84px] h-[80px] rounded-[12px] cursor-pointer ${selectedPlayer?._id === player?._id
                  ? "border-2 border-primary"
                  : "hover:border-2 hover:border-primary"
                  }`}
                key={player?._id}
                onClick={() => handlePlayerPress(player)}
              >
                <div
                  className="w-[64px] h-[64px] rounded-full"
                  style={{
                    background: `url('${player.profilePicture ? player.profilePicture : defaultImage
                      }') no-repeat center center / cover`,
                  }}
                ></div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };


  useEffect(() => {
    if (players.length > 0) {
      const defaultPlayer = players.find(
        (player: playerTypes) => player._id === userId
      );
      if (defaultPlayer) {
        setSelectedPlayer(defaultPlayer);
        twitchStream(defaultPlayer);
      }
    }
  }, [players, userId]);

  useEffect(() => {
    dispatch(getCurrentMatch({ matchId }));
  }, [matchId]);

  useEffect(() => {
    if (currentMatch.matchStatus === matchStatuses.WAITING_YOU_TO_VOTE) {
      navigate(`/duels/vote/${matchId}/LOBBY`);
    }
  }, [currentMatch]);

  useEffect(() => {
    if (fcmData?.data?.type === "VOTE_POPUP")
      dispatch(getCurrentMatch({ matchId }));
  }, [fcmData]);

  const onRefresh = () => {
    dispatch(getCurrentMatch({ matchId }));
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      return "";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex min-h-screen h-full">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 flex flex-col justify-center
        w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] gap-3 max-md:h-[calc(100%)]"
        >
          <div className="flex flex-row max-md:justify-between  justify-center items-center gap-4">
            <span className="text-3xl text-white items-center font-normal text-center">
              Duel Engaged
            </span>
            <button
              className=" w-[25px] h-[25px] absolute right-12"
              onClick={onRefresh}
            >
              <img src={Refresh} alt="Close" />
            </button>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-6">
            {streamMethod === streamTypes.STREAM_WITH_EXTERNAL ? (
              <>
                <div
                  className="desktop:w-2/5 h-[300px] border-[6px] border-borderColor rounded-[46px] bg-cover bg-center bg-no-repeat max-w-[400px] w-full justify-center"
                  style={{
                    background: `url(${formatThumbnail(
                      currentMatch.coverImage
                    )}) no-repeat center center / cover`,
                  }}
                ></div>
                <div className="flex flex-col desktop:w-2/5 max-w-[500px] gap-2 w-full">
                  <div className="flex flex-row items-center justify-between bg-borderColor rounded-xl p-2 px-4">
                    <div className="flex flex-col truncate desktop:max-w-[25vw]">
                      <span className=" text-placeholder text-sm">
                        Stream Key
                      </span>
                      <span className="text-white text-lg">{streamKey}</span>
                    </div>
                    <button
                      className="p-2 bg-darkenGray rounded-full hover:bg-placeholder"
                      onClick={() => navigator.clipboard.writeText(streamKey)}
                    >
                      <Clipboard />
                    </button>
                  </div>
                  <div className="flex flex-row items-center justify-between bg-borderColor rounded-xl p-2 px-4 ">
                    <div className="flex flex-col truncate desktop:max-w-[25vw] ">
                      <span className="text-placeholder text-sm">
                        Upstream URL
                      </span>
                      <span className="text-white text-lg ">{upstreamUrl}</span>
                    </div>

                    <button
                      className="p-2 bg-darkenGray rounded-full  hover:bg-placeholder"
                      onClick={() => navigator.clipboard.writeText(upstreamUrl)}
                    >
                      <Clipboard />
                    </button>
                  </div>
                </div>
              </>
            ) : streamMethod === streamTypes.STREAM_WITH_DUELCUBE ? (
              <div className="bg-background  desktop:w-1/2 border-[6px] min-h-[300px] max-w-[800px] w-full border-borderColor rounded-[46px] ">
                <StreamerView
                  streamRoomId={
                    defaultStream?.stream.inApp.streamRoomId
                      ? defaultStream?.stream.inApp.streamRoomId
                      : ""
                  }
                  streamToken={
                    defaultStream?.stream.inApp.streamToken
                      ? defaultStream?.stream.inApp.streamToken
                      : ""
                  }
                  isHost={isStreamerHost}
                  userName={selectedPlayer?.username}
                />
              </div>
            ) : streamMethod === streamTypes.TWITCH ? (
              <button
                className="desktop:w-2/5 h-[300px] border-[6px] border-borderColor rounded-[46px] bg-cover bg-center bg-no-repeat max-w-[400px] w-full hover:border-primary"
                style={{
                  background: `url(${formatThumbnail(
                    defaultStream?.stream.twitch.thumbnail
                  )}) no-repeat center center / cover`,
                }}
                onClick={() =>
                  window.open(
                    `https://www.twitch.tv/${defaultStream?.stream.twitch.streamerId}`,
                    "_blank"
                  )
                }
              >
                <span className=" text-white">Click here to Watch</span>
              </button>
            ) : (
              <div
                className="desktop:w-2/5 h-[300px] border-[6px] border-borderColor rounded-[46px] bg-cover bg-center bg-no-repeat max-w-[400px] w-full justify-center"
                style={{
                  background: `url(${formatThumbnail(
                    currentMatch.coverImage
                  )}) no-repeat center center / cover`,
                }}
              ></div>
            )}
            <div className="flex desktop:gap-x-[20px] flex-wrap desktop:flex-row flex-col gap-5 justify-center items-center">
              <div className="flex flex-col gap-y-6  justify-center items-center">
                <div className="flex justify-center items-center bg-borderColor w-[287px] h-[73px] rounded-[12px]">
                  <span className="text-2xl text-white items-center font-normal capitalize">
                    {rounds}
                  </span>
                </div>
                <div className="flex justify-between items-center gap-4">
                  {renderTeam(teamOne)}
                  <span className="text-white">Vs </span>
                  {renderTeam(teamTwo)}
                </div>

              </div>
              <div className="flex flex-col gap-y-6">
                <div className="flex flex-col justify-center items-center bg-borderColor w-[287px] h-[73px] rounded-[12px]">
                  {cubeWager === 0 ? (<><span className="flex justify-center items-center text-primary text-[22px] font-bold">
                    Free Play
                  </span></>) : (<><span className="text-2xl text-white items-center font-normal">
                    Cube Wager
                  </span>
                    <span className="flex justify-center items-center text-primary text-[22px] font-bold">
                      ${cubeWager}
                    </span></>)}
                </div>
                <div className="w-[287px] h-[73px] flex items-center justify-center">
                  <button
                    className="bg-primary w-[230px] h-[52px] rounded-[12px] flex justify-center items-center gap-x-2 hover:bg-primaryActive"
                    onClick={handleCommentsButton}
                  >
                    <CommentsIcon />
                    <span className="text-background text-lg">Comments</span>
                  </button>
                  {!!matchCommentsNotificationCount && (
                    <div className="bg-red-600 rounded-full w-5 h-5 items-center justify-center flex absolute mb-5 ml-[200px] ">
                      <span className="text-white text-xs">{matchCommentsNotificationCount > 9 ? "9+" : matchCommentsNotificationCount}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {startMatchButton && (
              <ButtonDc
                text="End Match"
                action={handleEndMatch}
                type="primary"
              />
            )}
          </div>
        </div>
        {modalOpen && (
          <MatchChatModal
            isMatch={true}
            matchId={matchId}
            closeModal={() => {
              setModalOpen(false);
            }}
          />
        )}
      </div>
      <LoadingAnim loading={loading || loader} />
    </div>
  );
};

export default DuelEngagedScreen;
