import React, { useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { setDate } from "store/matchMaking/matchMakingSlice";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { setMatchDate } from "store/tournament/tournamentSlice";
import { startTournament, updateTournamentDate } from "store/tournament/action";
import { toast } from "react-toastify";

import LoadingAnim from "components/Loader";
import CloseIcon from "assets/close-button.svg";
import BackIcon from "assets/arrow-right.svg";
import ButtonDc from "components/Button";

export default function TimeSelectScreen({ }) {
  const isMobile = window.innerWidth <= 768;
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tournamentID } = useParams();
  const [currentDateValue, setCurrentDateValue] = React.useState<Dayjs | null>(
    dayjs()
  );
  const [dateValue, setDateValue] = React.useState<Dayjs | null>();
  const dateObject = dayjs(dateValue).toDate() || new Date();
  const duelpath = useAppSelector(
    (state) => state.matchMaking
  ).selectedDuelPath;
  const tournament = useAppSelector((state) => state.tournament);
  const isSettingNextRound = tournament.settingNextRound;

  const handleClose = () => {
    navigate("/wagering");
  };
  const onBack = () => {
    navigate("/duels/schedule-match");
  };
  const handleTournament = () => {
    if (tournamentID) {
      dispatch(
        updateTournamentDate({
          payload: {
            tournamentId: tournament.tournamentId,
            tournamentDate: dateObject,
          },
          callback: (data) => {
            if (tournament.tournamentState !== "STARTED") {
              dispatch(
                startTournament({
                  payload: tournament.tournamentId,
                  callback: () => toast.success("Tournament Scheduled..."),
                })
              );
            }

            dispatch(setMatchDate(dateObject));
            return navigate(
              `/tournament/live-bracket/${tournament.tournamentId}`
            );
          },
        })
      );
    } else {
      dispatch(setMatchDate(dateObject));
    }
    if (isSettingNextRound) {
      setTimeout(() => {
        return navigate("/tournament/live-bracket/nextRound");
      }, 300);
    }
  };

  const handleNext = () => {
    const now = dayjs();
    if (dateValue && dateValue.isBefore(now)) {
      alert(
        "Selected date and time cannot be earlier than current time or same."
      );
    } else {
      if (duelpath === "CREATE_TOURNAMENT") {
        handleTournament();
      } else {
        dispatch(setDate({ date: dateObject }));
        navigate("/duels/select-game");
      }
    }
  };
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  useEffect(() => {
    currentTime();
  }, []);

  const currentTime = () => {
    const intervalId = setInterval(() => {
      setCurrentDateValue(dayjs());
    }, 1000);

    return () => clearInterval(intervalId);
  };
  return (
    <div className="mx-auto w-full flex-col bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex items-center  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center justify-between">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 w-[calc(100%-176px)] 
        h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]   max-md:h-[95vh]  overflow-y-auto"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between mb-10">
            <div className="flex flex-row items-center gap-5">
              <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                <img src={BackIcon} alt="Close" />
              </button>
              <span className="text-3xl text-white items-center font-normal justify-center flex ">
                Select Date and Time
              </span>
            </div>
            <button className=" w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex items-center justify-center flex-col ">
            <ThemeProvider theme={darkTheme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateTimePicker
                  slotProps={{
                    actionBar: { actions: ["today"] },
                  }}
                  orientation={!isMobile ? "landscape" : "portrait"}
                  defaultValue={dayjs(Date())}
                  sx={{
                    ".MuiPickersCalendarHeader-label": {
                      color: "#FFD482",
                    },
                    ".MuiPickersYear-root": {
                      color: "#FFD482",
                    },
                    ".MuiPickersDay-today": {
                      color: "#FFD482",
                      borderColor: "#FFD482",
                    },
                  }}
                  disablePast
                  ampm={false}
                  value={dateValue ? dateValue : currentDateValue}
                  onChange={(newDateValue) => setDateValue(newDateValue)}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </div>
        </div>
        <div>
          <ButtonDc
            type="primary"
            className="absolute bottom-28 max-md:w-full "
            textClassName="max-md:text-sm"
            text={'Next'}
            action={handleNext}

          />
        </div>
      </div>
      <LoadingAnim loading={tournament.loading} />
    </div>
  );
}
