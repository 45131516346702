import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useNavigate } from "react-router-dom";
import { createStream, streamForExternal } from "store/streaming/actions";
import { setStreamToMorePlaces } from "store/streaming/streamingSlice";

import { ReactComponent as Plus } from "assets/plus.svg";
import { ReactComponent as Bin } from "assets/bin.svg";

import ButtonDc from "components/Button";
import LoadingAnim from "components/Loader";
import CloseIcon from "assets/close-button.svg";
import Sidebar from "components/SideBar";
import Menu from "components/Menu";
import NotificationModal from "components/Notifications";
interface InputField {
  key: string;
  url: string;
}

export default function MorePlacesScreen({ }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loader } = useAppSelector((state) => state.user);

  const [inputs, setInputs] = useState<InputField[]>([{ key: "", url: "" }]);
  const [isValid, setIsValid] = useState(false);
  const [visibleOptionsMenu, setVisibleOptionsMenu] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(false);

  const handleClose = () => {
    navigate(`/stream/streaming-method`);
  };

  const handleSkip = () => {
    navigate(`/stream/preview-stream/${"in-app"}`);
    //TODO
    // dispatch(
    //   createStream({
    //     payload: { streamType: "IN_APP" },
    //     callback: () => {
    //       dispatch(setStreamingMethod(streamTypes.STREAM_WITH_DUELCUBE));
    //       navigate(`/duels/lobby/${matchId}/${"streamDCOnly"}`);
    //     },
    //   })
    // );
  };
  const handleContinue = () => {
    const streamKeys = inputs.map((input) => input.key);
    const streamUrls = inputs.map((input) => input.url);

    const streamDataPayload = {
      otherStreamKey: streamKeys,
      otherStreamUrl: streamUrls,
    };
    dispatch(setStreamToMorePlaces(streamDataPayload));
    navigate(`/stream/preview-stream/${"in-app"}`);
    //       dispatch(setStreamingMethod(streamTypes.STREAM_WITH_DUELCUBE));
    //TODO
  };

  const addMoreInputs = () => {
    setInputs([...inputs, { key: "", url: "" }]);
  };

  const handleInputChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const values = [...inputs];
    values[index][event.target.name as keyof InputField] = event.target.value;
    setInputs(values);
    validateInputs(values);
  };

  const deleteInput = (index: number) => {
    if (inputs.length > 1) {
      const values = [...inputs];
      values.splice(index, 1);
      setInputs(values);
    }
  };

  const validateInputs = (values: InputField[]) => {
    const allValid = values.every(
      (input) =>
        input.key.length > 0 &&
        (input.url.startsWith("http://") || input.url.startsWith("rtmp://"))
    );
    setIsValid(allValid);
  };

  useEffect(() => {
    validateInputs(inputs);
  }, [inputs]);

  return (
    <div className="mx-auto w-full h-screen  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar
        onOptions={() => setVisibleOptionsMenu(true)}
        onNotifications={() => setVisibleNotifications(true)}
      />
      <div className=" w-full md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20 max-md:overflow-y-auto">
        <div className="md:mt-[20px] flex flex-row justify-between items-center w-[80vw] max-md:w-[95vw]">
          <span className="text-white text-3xl text-center max-md:text-2xl">
            Stream to More Places
          </span>
          <button
            className=" w-[25px] h-[25px] right-10 max-md:w-[15px]"
            onClick={handleClose}
          >
            <img src={CloseIcon} alt="Close" />
          </button>
        </div>
        <div className="flex flex-col md:p-4 gap-2 max-md:gap-8 items-center md:gap-10 rounded-2xl  md:bg-componentBackground bg-opacity-80 w-[80vw] h-[80vh] max-md:h-[70vh] max-md:w-[95vw]">
          <span className="text-placeholder text-center text-l font-normal mt-10 md:w-2/3">
            Want to share your stream on other platforms like YouTube or
            Facebook? <br />
            Enter the streaming URL and key for each platform you want to add.
          </span>
          <div className="h-[40vh] max-md:h-[40vh] max-md:w-full flex flex-col gap-4 overflow-y-auto">
            {inputs.map((input, index) => (
              <div key={index} className="flex max-md:w-full flex-row gap-4">
                <input
                  className="h-16 bg-borderColor rounded-xl md:w-[10vw] max-md:w-[20vw] max-md:text-xs p-2 text-center text-white focus:outline-none focus:ring-0 border-inputGray border-b-2 focus:border-primaryYellow peer"
                  placeholder="Enter stream key"
                  name="key"
                  value={input.key}
                  onChange={(event) => handleInputChange(index, event)}
                />
                <input
                  className="h-16 bg-borderColor rounded-xl md:w-[30vw] max-md:w-full p-2 max-md:text-sm text-left text-white focus:outline-none focus:ring-0 border-inputGray border-b-2 focus:border-primaryYellow peer"
                  placeholder="Enter stream URL"
                  name="url"
                  value={input.url}
                  onChange={(event) => handleInputChange(index, event)}
                />
                {inputs.length > 1 && (
                  <button
                    className="text-placeholder hover:text-primary"
                    onClick={() => deleteInput(index)}
                  >
                    <div className="hover:bg-borderColor p-2 rounded-full transition-colors">
                      <Bin className="w-6 h-6 " />
                    </div>
                  </button>
                )}
              </div>
            ))}
          </div>
          <button
            className="flex flex-row gap-2 text-placeholder items-center transition-colors hover:text-primary"
            onClick={addMoreInputs}
          >
            <Plus />
            Add More
          </button>
          <div className="flex flex-col gap-8 max-md:w-4/5">
            <ButtonDc text={"Skip"} action={handleSkip} type="background" />
            <ButtonDc
              text={"Continue"}
              action={handleContinue}
              type="primary"
              disabled={!isValid}
            />
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
      <Menu
        visible={visibleOptionsMenu}
        onClose={() => setVisibleOptionsMenu(false)}
      />
      <NotificationModal
        visible={visibleNotifications}
        onClose={() => setVisibleNotifications(false)}
      />
    </div>
  );
}
