import { toast } from "react-toastify";
import {
  getLinkedAccountsApi,
  sendPayPalAuthCodeApi,
} from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface WithCallback {
  payload?: any;
  callback?: (data?: any) => void;
}

export const getLinkedAccounts = createAsyncThunk<any, void>(
  "common/linkedAccounts",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await getLinkedAccountsApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addPaypalAccount = createAsyncThunk<any, WithCallback>(
  "common/addPaypalAccount",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await sendPayPalAuthCodeApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.data.message) {
        throw error;
      }
      return rejectWithValue(error.data.message);
    }
  }
);
